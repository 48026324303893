import React from "react";
import { useSelector } from "react-redux";
import { selectTranslations } from "../../../../../config/i18n/slice";
import { ArrowIcon } from "../../../../../../Assets/Icons/svg";
import { Divider, Image, Image as SemanticImage } from "semantic-ui-react";
import { LetteredAvatarImageUrl } from "../../../../../components/common/userProfilePlaceholder";
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers";
import moment from "moment";
import RenderFiles from "../../../../../components/common/RenderFiles";

function CommentCard({ allComments }) {
  const t = useSelector(selectTranslations);
  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return "en";
    }
  });

  return (
    <div className="message-container">
      {allComments?.map((comment) => (
        <>
          <div className="message position-relative">
            <Image
              avatar
              title={comment?.user?.firstName + " " + comment?.user?.lastName}
              src={
                comment?.user?.userProfile?.personalPicture?.url
                  ? comment?.user?.userProfile.personalPicture?.url
                  : LetteredAvatarImageUrl(
                      comment?.user?.firstName + " " + comment?.user?.lastName
                    )
              }
              style={{
                width: "32px",
                height: "32px",
              }}
            />
            <div className="message-content">
              <p className="message-header mb-1">
                <span className="name">
                  {comment?.user?.firstName + " " + comment?.user?.lastName}
                </span>
              </p>

              {comment?.previousStatus && comment?.status ? (
                <div className="status-body mb-2">
                  <div className="status-change">
                    <span className={`status ${comment?.previousStatus}`}>
                      {t.workOrders.work_order_status[comment?.previousStatus]}
                    </span>
                    <ArrowIcon />
                    <span className={`status ${comment?.status}`}>
                      {t.workOrders.work_order_status[comment?.status]}
                    </span>
                  </div>
                </div>
              ) : null}

              <p className="message-body mb-2">{comment?.comment}</p>

              <div className="message-body mb-1 image-attachment">
                {comment?.image?.length > 0 ? (
                  <>
                    {comment?.image?.filter(
                      (item) =>
                        item?.mime?.includes("image") ||
                        [".webm", ".mp4", ".hevc", ".mov"].includes(item?.ext)
                    )?.length > 0 ? (
                      <div className="media-container">
                        {comment?.image
                          ?.filter(
                            (item) =>
                              item?.mime?.includes("image") ||
                              [".webm", ".mp4", ".hevc", ".mov"].includes(
                                item?.ext
                              )
                          )
                          ?.map((item, index) => (
                            <div
                              onClick={() => {
                                window.open(item?.url, "_blank");
                              }}
                              key={index}
                              className="media-item"
                            >
                              {item?.url && (
                                <div
                                  className={`video-container d-flex justify-content-start align-items-center ${
                                    [".webm", ".mp4", ".hevc", ".mov"].includes(
                                      item?.ext
                                    )
                                      ? ""
                                      : "rounded-grey-bg"
                                  }`}
                                >
                                  {[".webm", ".mp4", ".hevc", ".mov"].includes(
                                    item?.ext
                                  ) ? (
                                    <video
                                      className="comments-video"
                                      width="300"
                                      height="170"
                                      controls
                                    >
                                      <source
                                        src={item?.url}
                                        type={"video/mp4"}
                                      />
                                      Your browser does not support the video
                                      tag.
                                    </video>
                                  ) : (
                                    <SemanticImage
                                      src={
                                        item?.url
                                          ? item?.url
                                          : toAbsoluteUrl(
                                              "/media/default-img.png"
                                            )
                                      }
                                      height="170px"
                                      width="300px"
                                      className={"data-header-image"}
                                    />
                                  )}
                                </div>
                              )}
                            </div>
                          ))}
                      </div>
                    ) : null}

                    {/* Container for Non-Image Files */}
                    {comment?.image?.filter(
                      (item) =>
                        !item?.mime?.includes("image") &&
                        ![".webm", ".mp4", ".hevc", ".mov"].includes(item?.ext)
                    )?.length > 0 ? (
                      <div className="non-media-container">
                        {comment?.image
                          ?.filter(
                            (item) =>
                              !item?.mime?.includes("image") &&
                              ![".webm", ".mp4", ".hevc", ".mov"].includes(
                                item?.ext
                              )
                          )
                          ?.map((item, index) => (
                            <div
                              onClick={() => {
                                window.open(item?.url, "_blank");
                              }}
                              key={index}
                              className={`image-dropzone c-pointer blue-border wo-dropzone p-1 file d-flex justify-content-between text-start mt-5`}
                            >
                              <div>
                                <label
                                  style={{ fontSize: "14px" }}
                                  className="d-flex align-items-center m-1"
                                >
                                  <div
                                    className={`${
                                      selectedLanguage === "en"
                                        ? "ml-4 mr-4"
                                        : "ml-4 mr-4"
                                    }`}
                                  >
                                    <RenderFiles file={item?.mime} />
                                  </div>
                                  {item?.name}
                                </label>
                              </div>
                            </div>
                          ))}
                      </div>
                    ) : null}
                  </>
                ) : null}
              </div>
              <p
                className={`${
                  selectedLanguage === "en" ? "text-right" : "text-left"
                }`}
              >
                <span className="time-data">
                  {moment(comment?.createdAt).format(
                    "MMMM DD, YYYY [at] hh:mm A"
                  )}
                </span>
              </p>
            </div>

            {/* <div className="hover-actions">
    <button className="action-btn">Edit</button>
    <button className="action-btn">Unread</button>
    <button className="action-btn">Copy</button>
    <button className="action-btn delete-btn">Delete</button>
  </div> */}
          </div>
          <Divider className="mb-0 mt-0" />
        </>
      ))}
    </div>
  );
}

export default CommentCard;
