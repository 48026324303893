export default {
  title: "أوامر العمل",
  title_small: "أوامر عمل",
  create_WO: "إضافة أمر عمل",

  tabs: {
    first: "عرض القائمة",
    second: "عرض التقويم",
  },
  add: "إضافة أمر",
  small_cancel: "إلغاء",
  kanbar: "عرض كانبان",
  upload_QR: "تحميل الرمز",
  drag_img: "تحميل الصورة أو الفيديو",
  upload_a_file: "قم بتحميل ملف",
  actual_times: "الوقت الفعلي",
  actual_cost: "التكلفة الفعلية",
  add_assignee: "إضافة المُكلف",
  add_assignees: "إضافة المُكلفيين",
  add_team: "إضافةالفريق",
  filter: {
    search: "بحث",
    assignees: "المكلّفون",
    assignee: "المكلفين",
    locations: "المواقع",
    dueDate: "تاريخ أمر العمل",
    date: "التاريخ",
    status: "الحالة",
    priority: "الأولوية",
    createdByUser: "انشأ من قبل",
    teams: "الفرق",
    people: "الأفراد",
    estimation_time_by: "الوقت المقدّر",
    select_assignees: "اختر المعينين",
    select_locations: "اختر المواقع",
    filter_by_status: "تصفية حسب الحالة",
    filter_by_priority: "تصفية حسب الأولوية",
    select_people: "اختر الأشخاص",
  },
  table: {
    title: "العنوان",
    ID: "الرمز",
    status: "الحالة",
    priority: "الأولوية",
    add_priority: "اختر الأولوية",
    assignees: "المكلّفون",
    category: "الفئة",
    customer: "العميل",
    location: "الموقع",
    dueDate: "تاريخ الاستحقاق",
    procedure: "اللإجراء",
    createdByUser: "أنشىء من قبل",
    createdAt: "تاريخ الإنشاء",
    updatedAt: "تاريخ التحديث",
    resultNotFound: "لم يتم العثور على نتيجة",
    startDateTime: "تاريخ ووقت البدء",
    dueDateTime: "تاريخ ووقت الاستحقاق",
  },
  form: {
    instances: "جدول الأوامر",
    enterNumber: "ادخل العدد",
    notes_updated: "تم تحديث ملاحظات الإغلاق بنجاح.",
    files_updated: "تم إضافة الملفات بنجاح.",
    files_deleted: "Files deleted successfully.",
    select_primary: "يرجى تحديد المُكلف الرئيسي",
    secondary_select: "اختر المكلّفين الثانويين",
    primary_select: "اختر المكلّف الرئيسي",
    add_people_tooltip:
      ".يجب تعيين المكلّف الرئيسي قبل إضافة المكلّفين الثانويين",
    additional_cost: "التكاليف الإضافية",
    part_qty_update: "تم تحديث كمية القطع بنجاح.",
    set_qty_update: "تم تحديث كمية المجموعة بنجاح.",
    start_date_new: "تاريخ / وقت البدء",
    due_date_new: "تاريخ / وقت الاستحقاق",
    close_out: "ملاحظات الإغلاق",
    primary: "المكلّف الرئيسي",
    secondary: "المكلّف الثانوي",
    add_close_out: "إضافة ملاحظات الإغلاق",
    added_parts_set: "القطع والمجموعات المُضافة",
    sub_total: "المجموع",
    labor_title: "العمالة",
    select_assignees: "اختيار المكلّف",
    start_time_date: "تاريخ/وقت البدء",
    part_set: "القطع و المجموعات",
    add_part_set: "إضافة القطع والمجموعات",
    search_parts: "البحث عن القطع والمجموعات",
    form_title: {
      add: "إضافة أمر عمل",
      edit: "تعديل أمر العمل",
      edit_small: "تعديل أمر العمل",
      delete_small: "حذف أمر العمل",
    },
    validation: {
      required_field: "الحقل مطلوب",
    },
    add_notes: "Add notes",
    select_part: "اختر القطع",
    select_set: "اختر المجموعات",
    description_text: "أضف الوصف",
    date_created: "تاريخ الإنشاء",
    progress: "الإنجاز",
    create_QR: "إنشاء رمز الاستجابة السريعة",
    create_QR_for_WO: "قم بإنشاء رمز الاستجابة السريعة لأمر العمل هذا",
    title_name: "أضف العنوان",
    title: "العنوان",
    description: "الوصف",
    customer: "الزبون",
    customer_placeholder: "اختر العميل",
    location: "الموقع",
    location_placeholder: "اختر الموقع",
    order_type: "نوع أمر العمل",
    add_order_type: "اختر نوع أمر العمل",
    order_type_1: "تصحيحي (غير مخطط)",
    order_type_2: "مجدول (مخطط)",
    due_date: "تاريخ الاستحقاق",
    add_due_date: "اختر تاريخ الاستحقاق",
    include_due_time: "تضمين وقت الاستحقاق؟",
    include_start_time: "تضمين وقت البدء؟",
    due_time: "وقت الاستحقاق",
    add_due_time: "أضف وقت الاستحقاق",
    priority: "ألاولوية",
    priority_options: ["بلا", "منخفضة", "متوسطة", "عالية"],
    people: "الأفراد",
    people_placeholder: "اختر الأفراد",
    time_placeholder: "أضف الوقت المتوقع",
    cost_placeholder: "إضافة التكلفة التقديرية",
    teams: "الفرق",
    teams_placeholder: "اختر الفرق",
    file: "ملف",
    attach_file: "قم بإرفاق ملف لأمر العمل هذا",
    assets: "الممتلكات",
    assets_placeholder: "اختر الممتلكات",
    category: "الفئة / الرمز",
    code: "الرمز",
    add_code: "اختر الرمز",
    category_options: [
      "ضرر",
      "اللإجراء التصحيحي لوقت التوقف",
      "وقت التوقف المخطط",
      "وقت التوقف غير المخطط",
      "فحص/تقييم",
      "كهرباء",
      "ميكانيك",
      "HVAC",
      "وقائي",
      "مشروع",
      "أمان",
      "إجراءات التشغيل القياسية",
    ],
    teams: "الفرق",
    parts: "القطع / المخزون",
    parts_placeholder: "حدد القطع / المخزون",
    labor_cost: "التكلفة المقدّرة",
    add_labor_cost: "إضافة التكلفة التقديرية",
    added_cost: "التكلفة المضافة",
    added_parts: "القطع المضافة",
    procedure: "اللإجراء",
    procedure_placeholder: "حدد اللإجراء",
    attachments: "المرفقات",
    attachments_placeholder: "إضافة ملفات",
    attachments_allowed: "الأنواع المسموح بها",
    attachments_remove: ["إزالة الملف؟", "إبقاء", "إزالة"],
    submit: {
      add: "تقديم",
      edit: "تحديث",
    },
    cancel_button: "إلغاء",
    success: {
      msg: "تمت اضافة أمر العمل بنجاح",
      ok: "موافق",
    },
    cancel: {
      msg: "هل أنت متأكد أنك تريد إلغاء؟",
      cancel: "نعم ، إلغاء",
      keep: "استمر",
    },
    updated: {
      msg: "تم تحديث أمر العمل بنجاح",
      ok: "موافق",
    },
    delete: {
      msg: "هل أنت متأكد أنك تريد حذف أمر العمل هذا؟",
      keep: "استمر",
      delete: "حذف",
    },
    deleted: {
      msg: "تم حذف أمر العمل بنجاح",
      ok: "موافق",
    },
  },

  activityTab: {
    activity: "Activity",
    noComment: "لا يوجد تعليقات",
    addComment: "أضافة تعليق",
    editingComment: "يتم حاليًا تعديل التعليق.",
    uploadFile: "تحميل ملف",
  },

  labor: {
    success: "تم إضافة الوقت بنجاح",
    edit: "تم تحديث الوقت بنجاح",
    delete: "تم حذف الوقت بنجاح",
    delete_labor: "هل أنت متأكد أنك تريد إلغاء هذا الوقت؟",
    add_time: "إضافة الوقت",
    edit_time: "تعديل الوقت",
    delete_time: "الوقت",
    total_time: "الوقت الإجمالي",
    total_cost: "التكلفة الإجمالية",
    statDate: "تاريخ/وقت البدء",
    duration: "المدة",
    started_at: "بدأ في",
    drive_time: "مدة القيادة",
    preparation_time: "مدة التحضير",
    inspection_time: "مدة الفحص",
    testing_time: "مدة الاختبار والتحقق",
    downtime_time: "مدة التعطل/الانتظار",
    wrench_time: "مدة العمل الفعلي",
    other_time: "أخرى",
  },

  cost: {
    cost: "التكلفة",
    partsCosts: "تكاليف القطع والمجموعات",
    laborCosts: "تكاليف العمل/العمالة",
    addCost: "التكاليف الإضافية",
    add_cost: "إضافة تكلفة",
    success: "تم إضافة التكلفة بنجاح",
    edit: "تم تحديث التكلفة بنجاح",
    delete: "تم حذف التكلفة بنجاح",
    delete_cost: "هل أنت متأكد أنك تريد إلغاء هذه التكلفة",
    assigned: "مكلّف لـ",
    edit_cost: "تعديل التكلفة",
    date_time: "التاريخ والوقت",
    labor_cost: "تكاليف العمالة",
    part_cost: "تكلفة القطعة",
    travel_cost: "تكلفة النقل",
    tax: "الضريبة",
    other_cost: "تكاليف أخرى",
  },

  details_view: {
    pastWO: "أوامر العمل السابقة",
    upcomingWO: "أوامر العمل القادمة",
    view_schedual: "عرض تفاصيل التكرار",
    view_instance: "إظهار جدول الأوامر",
    repeat_every: "تكرار",
    work_order_no: "أمر عمل",
    work_order: "أمر عمل",
    details: "التفاصيل",
    created_by: "انشا من قبل",
    priority: "الأولوية",
    status: "الحالة",
    due_date: "تاريخ الاستحقاق",
    estimation_time: "الوقت المقدّر",
    customer: "العميل",
    location: "الموقع الجغرافي",
    order_type: "نوع الطلب",
    repeat: "تكرار كل",
    never: "غير منتهي",
    category: "الفئة",
    procedure: "الدليل الإجرائي",
    parts: "القطع",
    assets: "الممتلكات",
    edit: "تعديل",
    delete: "حذف",
    assignees: "المعينين",
    attachments: "مرفقات",
    comments: "التعليقات",
    reports: "التقارير",
    labor_cost: "تكلفة العمالة",
    no_attachments_statement: "لا توجد مرفقات تنتمي إلى أوامر العمل",
    no_comments_statement: "لم يتم العثور على تعليقات",
    name: "الاسم",
    fileType: "نوع الملف",
    size: "حجم",
    view: "عرض",
    approve: "موافقة",
    decline: "رفض",
    schedule: "تفاصيل التكرار",
    daily: "يوم",
    weekly: "أسبوع",
    monthly: "شهر",
    yearly: "سنة",
    sun: "أحد",
    mon: "اثنين",
    tue: "ثلاثاء",
    wed: "أربعاء",
    thu: "خميس",
    fri: "جمعة",
    sat: "سبت",
    past_due_statement: "متأخر منذ",
    procedure_items: "عناصر اللإجراء",
    delete_not_allowed_message: "لا يمكن حذف أمر العمل هذا",
    expire_after: "ينتهي بعد",
    expire_on: "ينتهي في",
    orders: "أوامر",
    every: "كل",
    week: "أسبوع",
    month: "شهر",
    year: "سنة",
    weeks: "أسابيع",
    months: "أشهر",
    years: "سنوات",
    on: "في",
    after: "بعد",
    hour: "ساعة",
    log: "السجل",
    no_log_statement: "لا توجد سجلات",
    every_day: "كل",
    start_date: "تاريخ البدء",
    start_time: "وقت البدء",
    repeat_on: "تكرار في",
    ends: "إنهاء الجدولة",
    end_date: "تاريخ الانتهاء",
    month_on: "شهر, في",
    week_on: "الأسبوع على",
    select_no: "اختر الرقم",
    select_day: "اختر اليوم",
  },
  work_order_status: {
    open: "مُتاح",
    open_status: "مُتاح",
    open_status_action: "إتاحة",
    close: "إغلاق",
    reOpen: "إعادة الإتاحة",
    inProgress: "قيد التنفيذ ",
    onHold: "قيد الانتظار",
    inReview: "مكتمل",
    completed: "مغلق",
    hold: "تعليق",
    cancel: "ملغي",
    cancelled: "ملغي",
  },

  procedure_modal: {
    title: "تعديل الإجراء",
    sub_title:
      "أنت على وشك تغيير الإجراء المرتبط بأمر العمل، وبعض البنود قد تم العمل عليها من قبل المكلّفين.",
    important: "هام",
    sub_title1:
      "سيؤدي تغيير الإجراء إلى إلغاء البنود المنجزة والبيانات التي تم إدخالها والعمل عليها.",
    sub_title2: "لا يمكن التراجع عن التغيير بعد إحداثه.",
    proceed: "هل ترغب في المتابعة؟",
    confirm: "موافق",
  },

  modal_cancel: "إلغاء أمر العمل",
  modal_progress: "أمر العمل قيد التنفيذ",
  modal_hold: "تعليق أمر العمل",
  modal_approve: "الموافقة على أمر العمل",
  modal_decline: "رفض أمر العمل",
  priority_status: {
    none: "لا يوجد",
    low: "منخفضة",
    medium: "متوسطة",
    high: "عالية",
  },
  work_order_category: {
    Damage: "ضرر",
    HVAC: "تدفئة و تهوية و تكييف",
    Electrical: "كهرباء",
    Inspection: "فحص/تقييم",
    Mechanical: "ميكانيك",
    Preventive: "وقائي",
    Project: "مشروع",
    Refrigeration: "تبريد",
    Safety: "أمان",
    Sop: "إجراء عمل",
  },
  due_date_status: {
    any_date: "أي تاريخ",
    today: "اليوم",
    tomorrow: "غدا",
    next_7_day: "الأيام السبعة التالية",
    next_30_day: "ال ٣٠ يوما القادمة",
    this_month: "هذا الشهر",
    this_year: "هذا العام",
    yesterday: "بالأمس",
    last_week: "الأسبوع الماضي",
    last_month: "الأسبوع الماضي",
    last_year: "العام الماضي",
    past_due: "ما بعد الموعد المقرر",
  },

  cancel_WO: "هل أنت متأكد أنك تريد إلغاء أمر العمل هذا؟",
  hold_WO: "هل أنت متأكد أنك تريد تعليق أمر العمل هذا؟",
  progress_WO:
    "هل أنت متأكد من أنك تريد تعيين أمر العمل هذا كأمر عمل قيد التنفيذ؟",

  success_cancel: "تم إلغاء أمر العمل بنجاح",
  success_hold: "تم تعليق أمر العمل بنجاح",
  success_progress: "تم تعيين أمر العمل قيد التنفيذ بنجاح",
  reOpen_WO: "هل أنت متأكد من إعادة إتاحة أمر العمل؟",
  success_reOpen: "تم إعادة فتح أمر العمل بنجاح",
  success_approve: "تم إكمال أمر العمل بنجاح",
  success_decline: "تم إغلاق أمر العمل بنجاح",
  success_open: "تم فتح أمر العمل بنجاح",

  no_work_orders_available: "يبدو أنك ليس لديك أوامر عمل بعد",
  press_to_add_work_orders: "اضغط على زر '+' لإضافة أول أمر عمل لديك",
  no_work_orders_added: "لا تمت إضافة أوامر العمل",
  month: "الشهر",
  week: "الأسبوع",
  day: "اليوم",
  today: "اليوم",
  add_work_orders: "ابدء في إضافة أوامر العمل",
  search_again: "ابحث مرة أخرى",
  no_related_QR_msg: "لا يوجد أمر عمل ذي صلة برمز الاستجابة السريعة هذا",
  how_to_add_WO: "كيفية إضافة أوامر العمل",
  are_you_sure: "هل أنت متأكد أنك",
  put_in_progress: "ضعه قيد التنفيذ",
  put_on: "ضعه ",
  this_WO: "أمر العمل هذا؟",
  set_in: "ضعه قيد التنفيد",
  set_on: "ضعه قيد الانتظار",
  successfully: "بنجاح",
  submit_error: "يرجى إضافة سبب للتقديم",
  add_reason: "أَضف السبب",
  required: "أضف السبب (مطلوب)",
  complete_procedure: "% نسبة إتمام اللإجراء",
  assign: "تكليف",
  assign_people: "تكليف الأفراد/الفرق",
  what_delete: "ما الذي تريد حذفه؟",
  WO_assign_success: "تم تكليف أمر العمل بنجاح",
  WO_edit: "هذا واحد من سلسلة أوامر عمل مجدولة.",
  what_edit: "ما الذي تريد تعديله؟",
  this_wo: "أمر العمل هذا فقط",
  approve_WO: "هل أنت متأكد أنك أكملت أمر العمل؟",
  decline_WO: "هل أنت متأكد أنك تريد إغلاق أمر العمل هذا؟",
  open_WO: "هل أنت متأكد من إتاحة أمر العمل؟",
  this_series: "الجدول كاملاً",
  complete: "إكمال",
  part_error: "كمية القطع الموجودة في المخزون ليست كافية لجدول أمر العمل هذا.",
  set_error:
    "الكمية المتاحة غير كافية للمجموعات المضافة في أمر العمل المجدول هذا.",
};
