import React, { useState, useEffect } from "react";
import { Divider, Loader, Dimmer, Segment } from "semantic-ui-react";
import { IMAGES_URL } from "../../../config/functions";

import Moment from "react-moment";

import {  useSelector } from "react-redux";
import { selectTranslations } from "../../../config/i18n/slice";
import { useHistory } from "react-router-dom";
import {
  CheckboxIcon,
  TextIcon,
  NumberIcon,
  AmountIcon,
  MultipleChoiceIcon,
  ChecklistIcon,
  InspectionIcon,
  SignatureIcon,
  DateIcon,
  FileIcon,
  PhotoIcon,
  HeadingIcon,
  Attachment,
  RequiredIcon,
  EvidanceIcon,
  DownArrow,
  UpArrow,
  GreyFileIcon,
  GreyWOIcon,
  UpArrowAr,
  PhotoSvg,
  CSVSvg,
  ZipSvg,
  DocSvg,
  TextSvg,
  VideoGreyIcon,
  PdfGreyIcon,
} from "../../../../Assets/Icons/svg";

import moment from "moment";
import DownloadExcelFileWithData from "../../../../services/DownloadExcelFileWithData";
import DownloadPdfFileWithData from "../../../../services/DownloadPdfFileWithData";
import Tooltip from "react-power-tooltip";
import Asterisk from "../../../components/common/Asterisk";

const Details = ({ targetedData }) => {
  const t = useSelector(selectTranslations);
  const history = useHistory();

  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return "en";
    }
  });

  const currentUserLocations = useSelector((state) => {
    if (state?.auth?.user?.id) {
      return state.auth.user.locations;
    } else {
      return null;
    }
  });

  const roleData = useSelector((state) => {
    if (state?.auth?.user?.id) {
      return state.auth.user.role.type;
    } else {
      return null;
    }
  });
  const allIds = [];

  currentUserLocations?.length &&
    currentUserLocations.forEach((location) => {
      if (!allIds.some((inv) => inv == location?.id)) {
        allIds.push(location?.id);
      }
    });

  const [procedureFields, setProcedureFields] = useState([]);
  const [headerProcedureFields, setHeaderProcedureFields] = useState([]);
  const [showAttachmentTooltip, setAttachmentTooltip] = useState({});
  const [procedureItemsVisible, setProcedureItemsVisible] = useState({});
  const fieldTypes = [
    {
      key: "checkbox",
      value: "checkbox",
      text: t.procedures.form.type_options[0],
      icon: <CheckboxIcon width="24px" height="24px" />,
    },
    {
      key: "text",
      value: "text",
      text: t.procedures.form.type_options[1],
      icon: <TextIcon width="24px" height="24px" />,
    },
    {
      key: "number",
      value: "number",
      text: t.procedures.form.type_options[2],
      icon: <NumberIcon width="24px" height="24px" />,
    },
    {
      key: "amount",
      value: "amount",
      text: t.procedures.form.type_options[3],
      icon: <AmountIcon width="24px" height="24px" />,
    },
    {
      key: "multipleChoice",
      value: "multipleChoice",
      text: t.procedures.form.type_options[4],
      icon: <MultipleChoiceIcon width="24px" height="24px" />,
    },
    {
      key: "checklist",
      value: "checklist",
      text: t.procedures.form.type_options[5],
      icon: <ChecklistIcon width="24px" height="24px" />,
    },
    {
      key: "inspectionCheck",
      value: "inspectionCheck",
      text: t.procedures.form.type_options[6],
      icon: <InspectionIcon width="24px" height="24px" />,
    },
    {
      key: "signature",
      value: "signature",
      text: t.procedures.form.type_options[7],
      icon: <SignatureIcon width="24px" height="24px" />,
    },
    {
      key: "date",
      value: "date",
      text: t.procedures.form.type_options[8],
      icon: <DateIcon width="24px" height="24px" />,
    },
    {
      key: "file",
      value: "file",
      text: t.procedures.form.type_options[9],
      icon: <FileIcon width="24px" height="24px" />,
    },
    {
      key: "photo",
      value: "photo",
      text: t.procedures.form.type_options[10],
      icon: <PhotoIcon width="24px" height="24px" />,
    },
    {
      key: "heading",
      value: "heading",
      text: t.procedures.form.heading,
      icon: <HeadingIcon width="24px" height="24px" />,
    },
  ];

  const inspectionTypes = [
    {
      type: "yesNoNA",
      options: [
        { label: "Yes", value: "yes" },
        { label: "No", value: "no" },
        { label: "N/A", value: "na" },
      ],
    },
    {
      type: "goodFairPoorNA",
      options: [
        { label: "Good", value: "good" },
        { label: "Fair", value: "fair" },
        { label: "Poor", value: "poor" },
        { label: "N/A", value: "na" },
      ],
    },
    {
      type: "safeRiskNA",
      options: [
        { label: "Safe", value: "safe" },
        { label: "Risk", value: "risk" },
        { label: "N/A", value: "na" },
      ],
    },
    {
      type: "passFailNA",
      options: [
        { label: "Pass", value: "pass" },
        { label: "Fail", value: "fail" },
        { label: "N/A", value: "na" },
      ],
    },
    {
      type: "compliantNonCompliantNA",
      options: [
        { label: "Compliant", value: "compliant" },
        { label: "Non-Compliant", value: "non-compliant" },
        { label: "N/A", value: "na" },
      ],
    },
    {
      type: "scaleFive",
      options: [
        { label: "0", value: 0 },
        { label: "1", value: 1 },
        { label: "2", value: 2 },
        { label: "3", value: 3 },
        { label: "4", value: 4 },
        { label: "5", value: 5 },
      ],
    },
  ];
  const categoryOptions = [
    {
      value: "damage",
      label: t.workOrders.form.category_options[0],
    },
    {
      value: "corrective action Downtime",
      label: t.workOrders.form.category_options[1],
    },
    {
      value: "planned Downtime",
      label: t.workOrders.form.category_options[2],
    },
    {
      value: "unplanned Downtime",
      label: t.workOrders.form.category_options[3],
    },
    {
      value: "inspection",
      label: t.workOrders.form.category_options[4],
    },
    {
      value: "electrical",
      label: t.workOrders.form.category_options[5],
    },
    {
      value: "mechanical",
      label: t.workOrders.work_order_category.Mechanical,
    },
    {
      value: "HVAC",
      label: t.workOrders.work_order_category.HVAC,
    },
    {
      value: "preventive maintenance",
      label: t.workOrders.form.category_options[8],
    },
    {
      value: "project",
      label: t.workOrders.form.category_options[9],
    },
    {
      value: "safety",
      label: t.workOrders.work_order_category.Safety,
    },
    {
      value: "sop",
      label: t.workOrders.form.category_options[11],
    },
  ];

  const headers = [
    { label: t.procedures.table.ID, key: "id" },
    { label: t.procedures.table.title, key: "title" },
    { label: t.procedures.form.description, key: "description" },
    { label: t.procedures.details_view.category, key: "category" },
    { label: t.procedures.form.procedure_items, key: "fields" },
    { label: t.procedures.details_view.assets, key: "assets" },
    { label: t.procedures.details_view.locations, key: "locations" },
    { label: t.procedures.details_view.parts, key: "parts" },
    { label: t.procedures.table.createdByUser, key: "createdByUser" },
    { label: t.procedures.table.createdAt, key: "createdAt" },
    { label: t.procedures.table.updatedAt, key: "updatedAt" },
  ];

  const categoryStatus = categoryOptions.find(
    (status) => status.value == targetedData?.attributes?.category
  );

  const downloadXLS = () => {
    let csvData = [];

    let procedure_data = [];

    procedure_data.id = targetedData.id;
    procedure_data.title = targetedData.attributes.title || t.common.na;
    procedure_data.description =
      targetedData.attributes.description || t.common.na;

    procedure_data.category = categoryStatus?.label || t.common.na;
    procedure_data.fields = targetedData.attributes.fields.length || 0;

    let assetsData = targetedData.attributes?.assets?.data;
    let assetsString = "";

    if (assetsData?.length > 0) {
      assetsString = assetsData
        .map((people) => people.attributes?.name)
        .join(", ");
    } else {
      assetsString = t.common.na;
    }

    procedure_data.assets = assetsString;

    let locationData = targetedData.attributes?.locations?.data;
    let locationString = "";

    if (locationData?.length > 0) {
      locationString = locationData
        .map((each) => each.attributes?.name)
        .join(", ");
    } else {
      locationString = t.common.na;
    }

    procedure_data.locations = locationString;

    let partsData = targetedData.attributes?.parts?.data;
    let partsString = "";

    if (partsData?.length > 0) {
      partsString = partsData.map((each) => each.attributes?.name).join(", ");
    } else {
      partsString = t.common.na;
    }

    procedure_data.parts = partsString;

    procedure_data.createdByUser =
      targetedData?.attributes?.createdByUser?.data?.attributes?.firstName ||
      t.common.na;
    procedure_data.createdAt =
      moment(targetedData.attributes.createdAt).format("DD/MM/YYYY LT") ||
      t.common.na;
    procedure_data.updatedAt =
      moment(targetedData.attributes.updatedAt).format("DD/MM/YYYY LT") ||
      t.common.na;

    csvData.push([
      procedure_data.id,
      procedure_data.title,
      procedure_data.description,
      procedure_data.category,
      procedure_data.fields,
      procedure_data.assets,
      procedure_data.locations,
      procedure_data.parts,
      procedure_data.createdByUser,
      procedure_data.createdAt,
      procedure_data.updatedAt,
    ]);
    let csvColumnWidths = [
      { wch: 5 }, // ID column
      { wch: 20 }, // Title column
      { wch: 20 }, // Description column
      { wch: 25 }, // Category column
      { wch: 15 }, // Procedure Items column
      { wch: 25 }, // Procedure Items column
      { wch: 25 }, // Procedure Items column
      { wch: 25 }, // Procedure Items column
      { wch: 15 }, // Created By column
      { wch: 20 }, // Created At column
      { wch: 20 }, // Updated At column
    ];
    DownloadExcelFileWithData(
      headers,
      csvData,
      csvColumnWidths,
      targetedData?.attributes?.title || "Single-Procedure"
    );
  };

  const generatePDF = () => {
    let csvData = [];

    let procedure_data = [];

    procedure_data.id = targetedData.id;
    procedure_data.title = targetedData.attributes.title || t.common.na;
    procedure_data.description =
      targetedData.attributes.description || t.common.na;

    procedure_data.category = categoryStatus?.label || t.common.na;
    procedure_data.fields = targetedData.attributes.fields.length || 0;

    let assetsData = targetedData.attributes?.assets?.data;
    let assetsString = "";

    if (assetsData?.length > 0) {
      assetsString = assetsData
        .map((people) => people.attributes?.name)
        .join(", ");
    } else {
      assetsString = t.common.na;
    }

    procedure_data.assets = assetsString;

    let locationData = targetedData.attributes?.locations?.data;
    let locationString = "";

    if (locationData?.length > 0) {
      locationString = locationData
        .map((each) => each.attributes?.name)
        .join(", ");
    } else {
      locationString = t.common.na;
    }

    procedure_data.locations = locationString;

    let partsData = targetedData.attributes?.parts?.data;
    let partsString = "";

    if (partsData?.length > 0) {
      partsString = partsData.map((each) => each.attributes?.name).join(", ");
    } else {
      partsString = t.common.na;
    }

    procedure_data.parts = partsString;

    procedure_data.createdByUser =
      targetedData?.attributes?.createdByUser?.data?.attributes?.firstName ||
      t.common.na;
    procedure_data.createdAt =
      moment(targetedData.attributes.createdAt).format("DD/MM/YYYY LT") ||
      t.common.na;
    procedure_data.updatedAt =
      moment(targetedData.attributes.updatedAt).format("DD/MM/YYYY LT") ||
      t.common.na;

    csvData.push([
      procedure_data.id,
      procedure_data.title,
      procedure_data.description,
      procedure_data.category,
      procedure_data.fields,
      procedure_data.assets,
      procedure_data.locations,
      procedure_data.parts,
      procedure_data.createdByUser,
      procedure_data.createdAt,
      procedure_data.updatedAt,
    ]);

    const columnWidths = [, , , , , , , , , 22, 22];

    DownloadPdfFileWithData(
      headers,
      csvData,
      targetedData?.attributes?.title || t.procedures.procedure,
      columnWidths,
      "A3"
    );
  };

  useEffect(() => {
    if (targetedData?.attributes.fields.length > 0) {
      let newFields = targetedData.attributes.fields.map((field) => ({
        ...field,
        isExpanded: false,
      }));

      setProcedureFields(newFields);
    }
    if (targetedData?.attributes?.headers?.length > 0) {
      let newFields = targetedData.attributes?.headers.map((field) => ({
        ...field,
        isExpanded: false,
      }));

      setHeaderProcedureFields(newFields);
    }
  }, [
    targetedData?.attributes.fields,
    targetedData?.attributes?.headers?.fields,
  ]);

  const renderTriggers = (data) => {
    const actionTrigger = data.find((item) => item.trigger === "action");
    const evidenceTrigger = data.find((item) => item.trigger === "evidence");
    return (
      <>
        {actionTrigger && (
          <>
            <label
              style={
                selectedLanguage == "en"
                  ? { marginLeft: "-5px" }
                  : { marginRight: "-5px" }
              }
              className=""
            >
              <span className="">
                <RequiredIcon />{" "}
              </span>{" "}
              {t.procedures.form.then_options[0]}
            </label>

            <p className="detail-text-isc">
              <Segment secondary>
                <span className={selectedLanguage == "ar" ? "ml-2" : "mr-2"}>
                  <GreyWOIcon />
                </span>
                {t.procedures.form.wo_placeholder}
              </Segment>
            </p>
          </>
        )}
        {evidenceTrigger && (
          <>
            <label
              style={
                selectedLanguage == "en"
                  ? { marginLeft: "-5px" }
                  : { marginRight: "-5px" }
              }
              className=""
            >
              <EvidanceIcon /> {t.procedures.form.then_options[1]}
            </label>
            <p className="detail-text-isc">
              <Segment secondary>
                <span className={selectedLanguage == "ar" ? "ml-3" : "mr-3"}>
                  <GreyFileIcon />
                </span>
                {t.procedures.form.file_placeholder}
              </Segment>
            </p>
          </>
        )}
      </>
    );
  };

  const expandedArray = [
    "checkbox",
    "multipleChoise",
    "checklist",
    "inspectionCheck",
  ];
  const checkAsset = (id) => {
    if (!id) return false;
    if (roleData === "super_admin") return true; // Super admin always has access
    return allIds.includes(id); // Check for the presence of the id in allIds
  };

  const generateSubFields = (field) => {
    const generatedOptions = (options) => {
      return options?.map((option, index) => {
        return (
          <div className="procedure-item-option">
            <p>{option.item}</p>
            <input type="checkbox" checked={false} />
          </div>
        );
      });
    };

    const generatedMultiOptions = (options) => {
      return options?.map((option, index) => {
        return (
          <div className="procedure-item-option">
            <p>{option.item}</p>
            <input type="radio" checked={false} />
          </div>
        );
      });
    };

    const generatedInspectionOptions = (field) => {
      let filteredArray = inspectionTypes.filter(
        (insp) => insp.type == field.inspectionType
      );
      filteredArray = filteredArray[0];

      let filteredFailed = filteredArray?.options?.filter(
        (option) => option?.value == field?.failedValue
      );
      filteredFailed = filteredFailed ? filteredFailed[0] : null;

      let logicAnswer = filteredArray?.options?.filter(
        (option) => option.value == field?.logicAnswer
      );
      logicAnswer = logicAnswer ? logicAnswer[0] : null;

      return (
        <div className="wrapper-new-label-inspection-check">
          <label
            style={
              selectedLanguage == "en"
                ? { marginLeft: "-5px" }
                : { marginRight: "-5px" }
            }
            className="title-text-isc"
          >
            {t.procedures.form.type_options[6]}
          </label>
          <div className="mt-3 mb-5">
            {filteredArray?.options?.map((option) => (
              <span className="btn new-added-add-logic-txt option-inspection">
                {option.label
                  ? t.procedures.inspectionTypes[option.label.toLowerCase()] ||
                    option.label
                  : ""}
              </span>
            ))}
          </div>
          {filteredFailed ? (
            <>
              <label
                style={
                  selectedLanguage == "en"
                    ? { marginLeft: "-5px" }
                    : { marginRight: "-5px" }
                }
                className="title-text-isc"
              >
                {t.procedures.logic}
              </label>

              <div className="logic-box-for-isc mt-1">
                <label className="detail-text-isc mb-0">
                  {t.procedures.answer}
                </label>

                <div className="mt-3 mb-5 procedures-box">
                  {filteredArray?.options?.map((option) => (
                    <span
                      key={option.value}
                      className={`btn new-added-add-logic-txt option-inspection ${
                        filteredFailed?.value === option?.value
                          ? [
                              "no",
                              "poor",
                              "fail",
                              "risk",
                              "non-compliant",
                            ].includes(option?.value)
                            ? "red-box"
                            : [
                                "yes",
                                "good",
                                "compliant",
                                "pass",
                                "safe",
                              ].includes(option?.value)
                            ? "green-box"
                            : ["fair"].includes(option?.value)
                            ? "yellow-box"
                            : option?.value == "na"
                            ? "grey-box"
                            : [0, 1, 2, 3, 4, 5].includes(option?.value)
                            ? "blue-box"
                            : ""
                          : ""
                      }`}
                    >
                      {option.label
                        ? t.procedures.inspectionTypes[
                            option.label.toLowerCase()
                          ] || option.label
                        : ""}
                    </span>
                  ))}
                </div>

                {renderTriggers(field?.logics)}
              </div>
            </>
          ) : null}
        </div>
      );
    };

    let returnFields = null;
    switch (field.fieldType) {
      case "text":
        returnFields = (
          <Segment secondary>
            <span className={selectedLanguage == "ar" ? "ml-2" : "mr-2"}>
              <TextIcon width="24px" height="24px" fill="#788CA5" />
            </span>
            {t.procedures.form.text_placeholder}
          </Segment>
        );
        break;
      case "number":
        returnFields = (
          <Segment secondary>
            <span className={selectedLanguage == "ar" ? "ml-2" : "mr-2"}>
              {/* {fieldTypes[2]?.icon} */}
              <NumberIcon width="24px" height="24px" fill="#788CA5" />
            </span>
            {t.procedures.form.number_placeholder}
          </Segment>
        );

        break;

      case "checkbox":
        returnFields = generatedOptions(field?.options);

        break;
      case "amount":
        returnFields = (
          <Segment secondary>
            <span className={selectedLanguage == "ar" ? "ml-2" : "mr-2"}>
              <AmountIcon width="24px" height="24px" fill="#788CA5" />
            </span>
            {t.procedures.form.amount_placeholder}
          </Segment>
        );
        break;

      case "signature":
        returnFields = (
          <>
            <Segment secondary>
              <span className={selectedLanguage == "ar" ? "ml-2" : "mr-2"}>
                <SignatureIcon width="24px" height="24px" fill="#788CA5" />
              </span>
              {t.procedures.form.signature_placeholder}
            </Segment>{" "}
          </>
        );
        break;
      case "date":
        returnFields = (
          <Segment secondary>
            <span className={selectedLanguage == "ar" ? "ml-2" : "mr-2"}>
              <DateIcon width="24px" height="24px" fill="#788CA5" />
            </span>
            {t.procedures.form.date_placeholder}{" "}
          </Segment>
        );
        break;
      case "file":
        returnFields = (
          <Segment secondary>
            <span className={selectedLanguage == "ar" ? "ml-2" : "mr-2"}>
              <FileIcon width="24px" height="24px" fill="#788CA5" />
            </span>
            {t.procedures.form.file_placeholder}
          </Segment>
        );
        break;

      case "photo":
        returnFields = (
          <Segment secondary>
            <span className={selectedLanguage == "ar" ? "ml-2" : "mr-2"}>
              <PhotoIcon width="24px" height="24px" fill="#788CA5" />
            </span>
            {t.procedures.form.photo_placeholder}
          </Segment>
        );
        break;

      case "multipleChoice":
        returnFields = generatedMultiOptions(field.options);
        break;
      case "checklist":
        returnFields = generatedOptions(field.options);
        break;
      case "inspectionCheck":
        returnFields = generatedInspectionOptions(field);

        break;
      default:
        returnFields = null;
    }

    return (
      <>
        {returnFields}

        <div className=" mt-2">
          {field?.attachments?.data?.length > 0 && (
            <>
              {field?.attachments?.data?.map((prev, index) => {
                return (
                  <>
                    <div
                      onClick={() => {
                        window.open(IMAGES_URL + prev.attributes.url, "_blank");
                      }}
                      className="c-pointer file-name-show-procedure details"
                    >
                      <div
                        className="c-pointer"
                        style={{ position: "relative" }}
                        onMouseOver={() =>
                          setAttachmentTooltip({
                            ...showAttachmentTooltip,
                            [index]: true,
                          })
                        }
                        onMouseLeave={() =>
                          setAttachmentTooltip({
                            ...showAttachmentTooltip,
                            [index]: false,
                          })
                        }
                      >
                        <span className="ml-2 mr-2">
                          {[".jpg", ".jpeg", ".png", ".svg"].includes(
                            prev?.attributes?.ext
                          ) ? (
                            <PhotoSvg />
                          ) : [".pdf"].includes(prev?.attributes?.ext) ? (
                            <PdfGreyIcon />
                          ) : [".mp4", ".mov"].includes(
                              prev?.attributes?.ext
                            ) ? (
                            <VideoGreyIcon />
                          ) : [".csv", ".xlsx"].includes(
                              prev?.attributes?.ext
                            ) ? (
                            <CSVSvg />
                          ) : [".zip"].includes(prev?.attributes?.ext) ? (
                            <ZipSvg />
                          ) : [".doc", ".docx"].includes(
                              prev?.attributes?.ext
                            ) ? (
                            <DocSvg />
                          ) : [".txt"].includes(prev?.attributes?.ext) ? (
                            <TextSvg />
                          ) : (
                            <Attachment />
                          )}

                          <Tooltip
                            color="#fff"
                            backgroundColor="#172B4D"
                            position="bottom center"
                            show={showAttachmentTooltip[index]}
                            textBoxWidth="66px"
                            arrowAlign="center"
                            fontSize="9px"
                            fontWeight="400"
                            fontFamily="Roboto"
                            padding="5px 5px 5px 7px"
                            borderRadius="5px"
                            moveUp="5px"
                            static
                          >
                            <span className="work-order-preview-tooltip">
                              {t.people.personal_info_form.attachments}
                            </span>
                          </Tooltip>
                        </span>
                      </div>
                      <p
                        style={{ wordBreak: "break-word" }}
                        className="mt-1 c-pointer"
                      >
                        {" "}
                        {prev?.attributes?.name}
                      </p>
                    </div>
                  </>
                );
              })}
            </>
          )}
        </div>
      </>
    );
  };

  const generateFields = (fields, isHeader = false) => {
    const toggleProcedureItems = (index) => {
      if (procedureItemsVisible[index]) {
        setProcedureItemsVisible({
          ...procedureItemsVisible,
          [index]: false,
        });
      } else {
        setProcedureItemsVisible({
          ...procedureItemsVisible,
          [index]: true,
        });
      }
    };

    if (isHeader) {
      return headerProcedureFields.map((field, index) => (
        <React.Fragment key={field.id}>
          <div
            className={
              field?.fields?.length == 0
                ? "hide-border mb-5"
                : !procedureItemsVisible[index]
                ? "show-border mb-5"
                : "mb-5 hide-border"
            }
            style={
              field?.fields?.length == 0
                ? {}
                : !procedureItemsVisible[index]
                ? { borderRadius: "8px", border: "1px solid #0C66E4" }
                : {}
            }
          >
            <ProcedureItemContainerCard
              toggleProcedureItems={toggleProcedureItems}
              procedureItemsVisible={procedureItemsVisible}
              fieldCount={field?.fields?.length}
              field={field}
              fieldTypes={fieldTypes}
              index={index}
            >
              {field?.label}
            </ProcedureItemContainerCard>

            {!procedureItemsVisible[index] &&
              field?.fields &&
              field?.fields.length > 0 &&
              field.fields.map((subField) => (
                <ProcedureItemContainerCard
                  isHeader={isHeader}
                  key={subField.id}
                  field={subField}
                  fieldTypes={fieldTypes}
                >
                  <div className="item-value">
                    {generateSubFields(subField)}
                  </div>
                </ProcedureItemContainerCard>
              ))}
          </div>
        </React.Fragment>
      ));
    } else {
      return procedureFields.map((field, index) =>
        field?.fieldType == "heading" ? (
          <ProcedureItemContainerCard field={field} fieldTypes={fieldTypes}>
            {field?.label}
          </ProcedureItemContainerCard>
        ) : (
          <ProcedureItemContainerCard field={field} fieldTypes={fieldTypes}>
            <div className="item-value">{generateSubFields(field)}</div>
          </ProcedureItemContainerCard>
        )
      );
    }
  };

  return (
    <>
      {targetedData ? (
        <div className="preview-details-wrapper">
          <div className="procedure-data-header">
            <div className="header-title d-flex justify-content-between align-items-center">
              <div className="header-pill-container">
                <p className="procedure-title">
                  {targetedData?.attributes?.title}
                </p>
              </div>
            </div>
          </div>
          <div className="procedure-intro">
            <div className="d-flex align-items-start mt-5">
              <label className="procedure-intro-label procedure-label">
                {t.procedures.form.description}
                {":"}
              </label>
              <p className="procedure-intro-value">
                {targetedData?.attributes?.description
                  ? targetedData?.attributes?.description
                  : t.common.na}
              </p>
            </div>

            <div className="d-flex align-items-center mt-5">
              <label className="procedure-intro-label procedure-label">
                {t.procedures.details_view.category}
                {":"}
              </label>
              <p className="procedure-intro-value">
                {categoryStatus?.label || t.common.na}
              </p>
            </div>
            <div className="d-flex align-items-start mt-5">
              <label className="procedure-intro-label procedure-label">
                {t.procedures.details_view.assets}
                {":"}
              </label>
              <p className="workorder-intro-value">
                {targetedData?.attributes?.assets?.data?.length
                  ? targetedData.attributes.assets.data.map((asset, index) => (
                      <React.Fragment key={index}>
                        {checkAsset(asset?.attributes?.location?.data?.id) ? (
                          <p
                            className={`detail-intro-link mb-1 ${
                              selectedLanguage == "en"
                                ? "text-left"
                                : "text-right"
                            }`}
                            basic
                            onClick={() =>
                              history.push(
                                `/assets?id=${asset?.attributes?.countId}`
                              )
                            }
                          >
                            {asset.attributes.name}
                          </p>
                        ) : (
                          <p className={`mb-0`} basic>
                            {asset.attributes.name}
                          </p>
                        )}
                      </React.Fragment>
                    ))
                  : t.common.na}
              </p>
            </div>
          </div>
          <ul className="details-list">
            <li className="list-item" style={{ marginBottom: "10px" }}>
              <label className="item-label procedure-label procedure-item-header">
                {t.procedures.details_view.procedure_items} &nbsp; &nbsp;
                {targetedData?.attributes?.procedureItems
                  ? targetedData?.attributes?.procedureItems
                  : 0}
              </label>
              <div className="item-value ">
                {targetedData?.attributes?.headers?.length > 0
                  ? generateFields(targetedData?.attributes?.headers, true)
                  : null}
              </div>
              <div className="item-value ">
                {targetedData?.attributes.fields.length > 0
                  ? generateFields(targetedData?.attributes.fields)
                  : null}
              </div>
            </li>
          </ul>

          <Divider />

          <div className="d-flex align-items-center mt-5">
            <label className="workorder-intro-label work-request-label grey">
              {t.workOrders.table.createdByUser}
              {":"}
            </label>
            <p className="workorder-intro-value grey">
              {targetedData?.attributes?.createdByUser?.data?.attributes
                ?.firstName &&
              targetedData?.attributes?.createdByUser?.data?.attributes
                ?.lastName
                ? targetedData?.attributes?.createdByUser?.data?.attributes
                    ?.firstName +
                  " " +
                  targetedData?.attributes?.createdByUser?.data?.attributes
                    ?.lastName
                : t.common.na}
              {", "}
              <Moment
                format="DD/MM/YYYY LT"
                date={targetedData?.attributes?.createdAt}
              />
            </p>
          </div>

          <div className="d-flex align-items-center mt-3">
            <label className="workorder-intro-label work-request-label grey">
              {t.common.update_by}
              {":"}
            </label>
            <p className="workorder-intro-value grey">
              {targetedData?.attributes?.updatedByUser?.data
                ? targetedData?.attributes?.updatedByUser?.data?.attributes
                    ?.firstName +
                  " " +
                  targetedData?.attributes?.updatedByUser?.data?.attributes
                    ?.lastName +
                  ", " +
                  moment(targetedData?.attributes?.updatedAt).format(
                    "DD/MM/YYYY LT"
                  )
                : t.common.na}
            </p>
          </div>
        </div>
      ) : (
        <Dimmer active inverted>
          <Loader inverted>{t.common.loading}</Loader>
        </Dimmer>
      )}
    </>
  );
};

const ProcedureItemContainerCard = ({
  fieldTypes,
  field,
  children,
  isHeader,
  fieldCount = null,
  index = null,
  toggleProcedureItems = () => {},
  procedureItemsVisible,
}) => {
  const generatedFieldType = (type) => {
    let filteredFields = fieldTypes.filter((field) => field.value == type);
    filteredFields = filteredFields[0];
    return (
      <>
        {type !== "heading" ? (
          <>
            {filteredFields.icon}
            <div className="photo">{filteredFields.text}</div>
          </>
        ) : null}
      </>
    );
  };

  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return "en";
    }
  });

  return (
    <div className={isHeader ? "m-3" : ""}>
      <div
        className={`procedure-item-parent
   ${field?.fieldType === "heading" ? "header-div" : ""}
   ${
     field?.fieldType === "heading" &&
     (!field?.fields ||
       field?.fields?.length === 0 ||
       procedureItemsVisible?.[index])
       ? "show-header"
       : ""
   }`}
      >
        <div className={field?.fieldType == "heading" ? "head-title" : "title"}>
          {field?.fieldType == "heading" && field?.fields?.length > 0 ? (
            !procedureItemsVisible[index] ? (
              <span
                className="mt-2 c-pointer"
                onClick={() => toggleProcedureItems(index)}
              >
                {<DownArrow />}
              </span>
            ) : (
              <span
                className="mt-2 c-pointer"
                onClick={() => toggleProcedureItems(index)}
              >
                {selectedLanguage == "en" ? <UpArrow /> : <UpArrowAr />}
              </span>
            )
          ) : null}

          {field.label}
          {field?.isRequired ? <Asterisk color="#D14040" /> : null}
        </div>

        <div className="type-parent">
          {generatedFieldType(field?.fieldType)}
          {field?.fieldType == "heading" && fieldCount !== 0 ? (
            <span
              className={
                selectedLanguage == "ar" ? "mr-2 pl-1 pr-1" : "ml-3 pl-1 pr-1"
              }
              style={{
                background: "#0C66E4",
                color: "white",
                borderRadius: "3px",
              }}
            >
              {fieldCount}
            </span>
          ) : null}
        </div>
      </div>
      {field?.fieldType && !["heading"].includes(field?.fieldType) ? (
        <div className="procedure-sub-item-parent">{children}</div>
      ) : (
        <div className={!isHeader ? "mb-5" : ""}></div>
      )}
    </div>
  );
};

export default Details;
