import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import React from "react";
import ReactDOM from "react-dom";
import { useDropzone } from "react-dropzone";
import { selectTranslations } from "../../config/i18n/slice";
import { CommentUploadIcon } from "../../../Assets/Icons/svg";
import ReactTooltip from "react-tooltip";
const FileUpload = ({ onFileChange, name = "", attachments = {} }) => {
  const fileTypes = {
    images: [
      "image/jpeg",
      "image/jpg",
      "image/png",
      "image/gif",
      "image/svg+xml",
      "image/webp",
      "image/heic",
    ],
    documents: [
      "text/plain",
      "application/pdf",
      "text/csv",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ],
    video: ["video/mp4", "video/quicktime", "video/webm", "video/hevc"],
  };
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      const allowedFileTypes = [
        ...fileTypes.images,
        ...fileTypes.documents,
        ...fileTypes.video,
      ];

      // Validate file types
      const invalidFiles = acceptedFiles.filter(
        (file) => !allowedFileTypes.includes(file.type)
      );

      // Show toast if there are invalid files
      if (invalidFiles?.length > 0) {
        toast.error(t.common.invalid_msg);
        return;
      }
      let totalFile = attachments?.image?.length + acceptedFiles?.length;
    
      let totalAttachment = organisationImage?.numberOfAttachments
        ? organisationImage?.numberOfAttachments
        : 10;

      let totalSize = organisationImage?.attachmentsSize
        ? organisationImage?.attachmentsSize
        : 200;
      if (totalFile > totalAttachment) {
        toast.error(
          `${t.common.number_error_1} ${totalAttachment} ${t.common.number_error_2}`
        );
        return;
      }
      const validFiles = acceptedFiles.filter((file) => {
        const isImage = fileTypes.images.includes(file.type);
        const isDocument = fileTypes.documents.includes(file.type);
        const isVideo = fileTypes.video.includes(file.type);

        const validSize =
          (isVideo || isImage || isDocument) &&
          file.size < totalSize * 1024 * 1024;

        if (!validSize) {
          toast.error(
            `${t.common.file_upload_valid} ${totalSize} ${t.common.file_upload_second}`
          );
          return false;
        }
        return true;
      });

      onFileChange(validFiles, name);
    },
  });

  const t = useSelector(selectTranslations);
  const organisationImage = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation;
    } else {
      return null;
    }
  });

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      {name === "image" ? (
        <div
          data-tip={t.workOrders.activityTab.uploadFile}
          data-for="upload-tooltip"
        >
          <CommentUploadIcon />
          {ReactDOM.createPortal(
            <ReactTooltip
              id="upload-tooltip"
              className="custom-primary-tooltip"
              place="top"
              effect="solid"
            />,
            document.body
          )}
        </div>
      ) : null}
    </div>
  );
};

export default FileUpload;
