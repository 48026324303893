import React, { useEffect } from "react";
import { Loader } from "semantic-ui-react";
import {
  ArrowLeft,
  ArrowRight,
  CommentSendIcon,
  NoCommentsIcon,
} from "../../../../../../Assets/Icons/svg";
import { useSelector } from "react-redux";
import { selectTranslations } from "../../../../../config/i18n/slice";
import { Input } from "semantic-ui-react";
import CommentCard from "./commentCard";
import Pagination from "../../../../../components/common/Pagination";
import AttachmentSection from "../../../../../components/common/attachmentSection";
import FileUpload from "../../../../../components/common/UploadCommentsFile";

const CommentTab = ({
  allComments,
  setPage,
  page,
  total,
  fetchAllComments,
  addComment,
  setLoading,
  loading,
  onFileChange,
  setComment,
  comment,
  attachments,
  setAttachments,
  handleRemoveAttachment,
}) => {
  const t = useSelector(selectTranslations);

  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return "en";
    }
  });

  useEffect(() => {
    fetchAllComments();
  }, [page]);

  return (
    <>
      {loading ? (
        <Loader active inline="centered">
          {t.common.loading}
        </Loader>
      ) : (
        <div className="comments-container">
          <div
            className={`comments-data ${
              allComments?.length == 0 ? "increase-height" : ""
            } scroll-data`}
          >
            {allComments?.length == 0 ? (
              <div className="text-center">
                <NoCommentsIcon />
                <div className="font-weight-bold font16">
                  {t.workOrders.activityTab.noComment}
                </div>
              </div>
            ) : (
              <>
                <CommentCard allComments={allComments} />
              </>
            )}
          </div>
          {allComments?.length > 0 ? (
            <div className="d-flex justify-content-end">
              <Pagination
                ofString={t.common.of}
                total={total}
                parts={true}
                prevItem={
                  selectedLanguage === "en" ? <ArrowLeft /> : <ArrowRight />
                }
                nextItem={
                  selectedLanguage === "en" ? <ArrowRight /> : <ArrowLeft />
                }
                page={page}
                limit={10}
                handleNext={async () => {
                  setLoading(true);
                  setPage(page + 1);
                  setLoading(false);
                }}
                handlePrevious={async () => {
                  setLoading(true);
                  setPage(page - 1);
                  setLoading(false);
                }}
              />
            </div>
          ) : null}
          <div className="upload-container">
            <AttachmentSection
              onRemoveAttachment={handleRemoveAttachment}
              attachments={attachments?.image}
            />

            <div className="upload-section">
              <div className="upload-options d-flex">
                <button className="upload-button">
                  <FileUpload
                    onFileChange={onFileChange}
                    name="image"
                    attachments={attachments}
                    setAttachments={setAttachments}
                  />
                </button>
                <Input
                  type="text"
                  className="upload-input"
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  placeholder={t.workOrders.activityTab.addComment}
                />
              </div>

              <button
                disabled={!comment && attachments?.image?.length === 0}
                onClick={() => addComment()}
                className="send-button"
              >
                <CommentSendIcon />
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CommentTab;
