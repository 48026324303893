import React from "react";
import AllDetailCard from "./allDetailCard";
import FileUpload from "../../../../../components/common/UploadCommentsFile";
import { Input } from "semantic-ui-react";
import {
  ArrowLeft,
  ArrowRight,
  CommentSendIcon,
} from "../../../../../../Assets/Icons/svg";
import AttachmentSection from "../../../../../components/common/attachmentSection";
import { selectTranslations } from "../../../../../config/i18n/slice";
import { useSelector } from "react-redux";
import Pagination from "../../../../../components/common/Pagination";

const AllDetails = ({
  comment,
  addComment,
  setComment,
  attachments,
  setAttachments,
  handleRemoveAttachment,
  onFileChange,
}) => {
  const t = useSelector(selectTranslations);
  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return "en";
    }
  });
  return (
    <div className="comments-container">
      <div className="comments-data scroll-data">
        <AllDetailCard />
      </div>
      <div className="d-flex justify-content-end">
        <Pagination
          ofString={t.common.of}
          total={1}
          parts={true}
          prevItem={selectedLanguage === "en" ? <ArrowLeft /> : <ArrowRight />}
          nextItem={selectedLanguage === "en" ? <ArrowRight /> : <ArrowLeft />}
          page={1}
          limit={10}
          // handleNext={async () => {
          //   setLoading(true);
          //   setPage(page + 1);
          //   setLoading(false);
          // }}
          // handlePrevious={async () => {
          //   setLoading(true);
          //   setPage(page - 1);
          //   setLoading(false);
          // }}
        />
      </div>
      <div className="upload-container">
        <AttachmentSection
          onRemoveAttachment={handleRemoveAttachment}
          attachments={attachments?.image}
        />

        <div className="upload-section">
          <div className="upload-options d-flex">
            <button className="upload-button">
              <FileUpload
                onFileChange={onFileChange}
                name="image"
                attachments={attachments}
                setAttachments={setAttachments}
              />
            </button>
            <Input
              type="text"
              className="upload-input"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              placeholder={t.workOrders.activityTab.addComment}
            />
          </div>

          <button onClick={() => addComment()} className="send-button">
            <CommentSendIcon />
          </button>
        </div>
      </div>
    </div>
  );
};

export default AllDetails;
