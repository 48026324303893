import { Image } from "semantic-ui-react";
import { CloseIconWO } from "../../../Assets/Icons/svg";
import useDisplayFormattedText from "../../../hooks/useDisplayFormattedText";
import RenderFiles from "./RenderFiles";
import React from "react";
import LoadingSpinner from "./LoadingSpinner";
const AttachmentSection = ({ attachments, onRemoveAttachment }) => {

  const images = attachments?.filter((attachment) =>
    attachment.type.includes("image")
  );
  const otherFiles = attachments?.filter(
    (attachment) => !attachment.type.includes("image")
  );

  const { DisplayFormattedText } = useDisplayFormattedText();

  return (
    <div className="attachment-section">
      <div className="attachment-row">
        {/* Images Section */}
        {images?.length > 0 && (
          <div className="attachments-container images-section">
            {images.map((attachment, index) => (
              <div key={index} className="attachment-item-image">
                <Image
                  alt={attachment.name}
                  width={"56px"}
                  height={"56px"}
                  onLoad={() => URL.revokeObjectURL(attachment.previewUrl)} // Cleanup memory
                  src={attachment?.url ? attachment.url : attachment.previewUrl}
                />
                <div
                  className="remove-attachment"
                  onClick={() => onRemoveAttachment(attachment?.id)}
                >
                  {attachment?.isUploading ? (
                    <LoadingSpinner /> 
                  ) : (
                    <CloseIconWO />
                  )}
                </div>
              </div>
            ))}
          </div>
        )}

       
        {images?.length > 0 && otherFiles?.length > 0 && (
          <div className="vertical-divider"></div>
        )}

       
        {otherFiles?.length > 0 && (
          <div className="attachments-container images-section">
            {otherFiles.map((attachment, index) => (
              <div class="attachment-item-image attachment-item">
                <div class="attachment-icon">
                  <RenderFiles file={attachment?.type} />
                </div>
                <div class="attachment-details">
                  <span class="attachment-name">
                    {DisplayFormattedText(attachment?.name, 11)}
                  </span>
                </div>
                <div
                  className="remove-attachment docs"
                  onClick={() => onRemoveAttachment(attachment?.id)}
                >
                  {attachment?.isUploading ? (
                    <LoadingSpinner /> 
                  ) : (
                    <CloseIconWO />
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default AttachmentSection;
