import React, { useEffect, useState } from "react";
import { Table, Header } from "semantic-ui-react";
import Tooltip from "react-power-tooltip";
import * as auth from "../../modules/Auth/_redux/authRedux";

import { useLocation, useHistory } from "react-router";

import {
  getLocationByID,
  getParentBySubLoc,
} from "../../config/functions";
import { useDebounce } from "use-debounce";

import Add from "./add";
import Edit from "./edit";

import Preview from "./preview/index";

import Filters from "./filters";

import { Country, State } from "country-state-city";

import { connect, useDispatch, useSelector } from "react-redux";
import { selectTranslations } from "../../config/i18n/slice";
import { cloneDeep } from "lodash";
import { usePermissionsSimplified } from "../../../hooks/usePermissionsSimplified";
import {
  AddIconWithCircle,
  CloseIcon,
  LinkBlueIcon,
  LocationBlueIcon,
  LocationIcon,
  PlusIcon,
  SortIcon,
} from "../../../Assets/Icons/svg";
import useDisplayFormattedText from "../../../hooks/useDisplayFormattedText";
import DataTable from "react-data-table-component";
import { ArrowLeft, ArrowRight } from "../../../Assets/Icons/svg";
import Pagination from "../../components/common/CommonPagination";
import {
  fetchAllLoctionData,
  fetchLocationsInitData,
  fetchLocationsWithFilter,
} from "../../../redux/reducers/locationReducer";
import Animation from "../../components/common/CustomLoader";

const customStyles = {
  headCells: {
    style: {
      paddingTop: "11px", // override the cell padding for head cells
    },
  },
  cells: {
    style: {
      paddingLeft: "16px", // override the cell padding for data cells
      paddingRight: "16px",
      paddingTop: "19px",
      paddingBottom: "19px",
    },
  },
};

const customSubStyles = {
  rows: {
    style: {
      background: "#E9F2FF", // override the row height
    },
  },
  headCells: {
    style: {
      paddingTop: "4px", // override the cell padding for head cells
      paddingBottom: "4px",
    },
  },
  cells: {
    style: {
      paddingLeft: "16px", // override the cell padding for data cells
      paddingRight: "16px",
      paddingTop: "19px",
      paddingBottom: "19px",
    },
  },
};
const Locations = (props) => {
  const dispatch = useDispatch();

  const t = useSelector(selectTranslations);
  const { userPermissions } = usePermissionsSimplified();
  const curentUrl = useLocation();
  const queryParams = new URLSearchParams(curentUrl.search);
  const locationId = queryParams.get("id");
  const history = useHistory();
  const { DisplayFormattedText } = useDisplayFormattedText();
  const [filterOptions, setFilterOptions] = useState({
    customers: [],
    countries: [],
    cities: [],
    createdByUser: [],
    createdAt: {
      value: 0,
      text: t.locations.filter.createdAt,
    },
    priority: [],
    status: [],
  });

  const user = useSelector((state) => {
    if (state?.auth?.user) {
      return state.auth.user;
    } else {
      return null;
    }
  });

  const [searchTitle, setSearchTitle] = useState("");
  const [loader, setloader] = useState(false);
  const [debounceValue] = useDebounce(searchTitle, 500);
  const [loadUserData, setLoadUserData] = useState(false);
  const [modalWidth, setModalWidth] = useState(
    Math.max(window.innerWidth / 2, 514)
  );
  const [assetUploadModal, setAssetUploadModal] = useState(false);

  const currentUserLocations = useSelector((state) => {
    if (state?.auth?.user?.id) {
      return state.auth.user.locations;
    } else {
      return null;
    }
  });
  const currentUserId = useSelector((state) => {
    if (state?.auth?.user?.id) {
      return state.auth.user.id;
    } else {
      return null;
    }
  });

  const [parentIds, setParentIds] = useState([]);

  const role = useSelector((state) => {
    if (state?.auth?.user?.id) {
      return state.auth.user.role.type;
    } else {
      return null;
    }
  });

  const allIds = [];
  currentUserLocations?.length &&
    currentUserLocations.forEach((location) => {
      if (!allIds.some((inv) => inv == location?.id)) {
        allIds.push(location?.id);
      }

      location.subLocations.forEach((subLocation) => {
        if (!allIds.some((inv) => inv == subLocation?.id)) {
          allIds.push(subLocation?.id);
        }
      });
    });
  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });
  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return "en";
    }
  });

  const {
    data,
    isLoading: loading,
    error,
    initData,
    page,
    pageSize,
    total,
    initLoading,
  } = useSelector((state) => ({
    data: state.location.locationData,
    isLoading: state.location.isLoading,
    error: state.location.error,
    initData: state.location.locationInitData,
    page: state.location.page,
    pageSize: state.location.pageSize,
    total: state.location.total,
    initLoading: state.location.initLoading,
  }));
  const [toggleAsideModal, setToggleAsideModal] = useState(false);
  const [fetching, setFetchLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortData, setSortData] = useState([]);
  const [expandedRow, setExpandedRow] = useState();
  const [targetedData, setTargetedData] = useState();
  const [togglePreview, setTogglePreviw] = useState(false);
  const [toggleEditModal, setToggleEditModal] = useState(false);

  const [refresh, setRefresh] = useState(false);
  const [selectedParentLocation, setSelectedParentLocation] = useState(null);
  const [showTooltip, setShowTooltip] = useState(false);
  const [showSubTooltip, setShowSubTooltip] = useState({});

  const ExpandedComponent = ({ data }) => {
    const columns = [
      {
        selector: (row) => row.attributes?.subLocations?.data,
        sortable: true,
        width: selectedLanguage === "en" ? "30px" : "60px",
      },
      {
        selector: (row) => row.attributes?.subLocations?.data,
        sortable: true,
        width: "50px",
      },
      {
        selector: (row) => row?.id,
        width: selectedLanguage === "en" ? "150px" : "151px",
        sortable: true,
        cell: (row) => (
          <span
            onClick={() => {
              setTargetedData(row);
              setTogglePreviw(true);
            }}
            className="id-text-style"
          >
            {row?.attributes?.countId}
          </span>
        ),
      },
      {
        width: selectedLanguage === "en" ? "163px" : "167px",
        selector: (row) => row?.attributes?.name,
        sortable: true,
        cell: (row) => (
          <span
            onClick={() => {
              setTargetedData(row);
              setTogglePreviw(true);
            }}
            className="id-text-style"
          >
            {row?.attributes?.name
              ? DisplayFormattedText(row?.attributes?.name, 15)
              : t.common.na}
          </span>
        ),
      },
      {
        width: "196px",
        selector: (row) => row?.attributes?.address,
        sortable: true,
        cell: (row) => (
          <span
            onClick={() => {
              setTargetedData(row);
              setTogglePreviw(true);
            }}
            className=""
          >
            {row?.attributes?.address
              ? DisplayFormattedText(row?.attributes?.address, 19)
              : t.common.na}
          </span>
        ),
      },
      {
        selector: (row) => row?.attributes?.country,
        minWidth: "156px",
        sortable: true,
        cell: (row) =>
          row.attributes.country ? (
            <span
              onClick={() => {
                setTargetedData(row);
                setTogglePreviw(true);
              }}
            >
              {Country.getCountryByCode(row.attributes.country)?.name}
            </span>
          ) : (
            t.common.na
          ),
      },
      {
        selector: (row) => row.attributes?.city,
        sortable: true,
        minWidth: "156px",
        cell: (row) => {
          const citiesOfCountry = State.getStatesOfCountry(
            row.attributes.country
          );
          const the_city = row.attributes.city
            ? citiesOfCountry.filter((city) => {
                return city.isoCode == row.attributes.city;
              })[0]?.name
              ? citiesOfCountry.filter((city) => {
                  return city.isoCode == row.attributes.city;
                })[0]?.name
              : row.attributes.cityName
              ? row.attributes.cityName
              : t.common.na
            : t.common.na;

          const formatted_address = DisplayFormattedText(the_city, 10);

          return (
            <span
              onClick={() => {
                setTargetedData(row);
                setTogglePreviw(true);
              }}
            >
              {formatted_address}
            </span>
          );
        },
      },
      {
        selector: (row) => row?.attributes?.customers,
        sortable: true,
        minWidth: "160px",
        cell: (row) => {
          const locationData = row?.attributes.customers?.data;
          if (locationData?.length > 0) {
            const firstLocation = locationData[0]?.attributes?.name.substring(
              0,
              12
            );
            const remainingCount = locationData.length - 1;
            const remainingLocations = locationData
              .slice(1)
              .map((location) => location.attributes.name)
              .join(", ");
            const displayText =
              remainingCount > 0
                ? `${firstLocation} ... +${remainingCount}`
                : firstLocation;
            return (
              <span
                onClick={() => {
                  setTargetedData(row);
                  setTogglePreviw(true);
                }}
                title={locationData
                  .map((location) => location.attributes.name)
                  .join(", ")}
              >
                {displayText}
              </span>
            );
          } else {
            return t.common.na;
          }
        },
      },

      {
        minWidth: "200px",
        name: t.locations.form.propertyType,
        selector: (row) => row.attributes?.propertyType,
        sortable: true,
        cell: (row) => {
          const locationData = row.attributes?.propertyType;
          if (locationData && Array.isArray(locationData)) {
            const displayText = locationData
              .map((location) => {
                const nameParts = location?.property;

                return nameParts;
              })
              .join(", "); // Adjust the join character as needed
            return (
              <span
                onClick={() => {
                  setTargetedData(row);
                  setTogglePreviw(true);
                }}
              >
                {displayText
                  ? DisplayFormattedText(displayText, 20)
                  : t.common.na}
              </span>
            );
          } else {
            return (
              <span
                onClick={() => {
                  setTargetedData(row);
                  setTogglePreviw(true);
                }}
              >
                {t.common.na}{" "}
              </span>
            );
          }
        },
      },

      {
        selector: (row) => row.attributes?.subLocations?.data,
        sortable: true,
      },
    ];
    const filteredDataSub =
    data?.attributes?.subLocations?.data?.filter((item) =>
      allIds.includes(item.id)
  ) || [];
    return (
      <>
        <DataTable
          columns={columns}
          className="datatable-div"
          data={
            !(role === "super_admin")
              ? filteredDataSub
              : data?.attributes?.subLocations?.data
          }
          direction={selectedLanguage === "en" ? "ltr" : "rtl"}
          onRowClicked={async (row) => {
            const reponse = await getLocationByID(row.id, organisationId);
            if (reponse.status == 200) {
              setTargetedData(reponse.data.data[0]);
              setTogglePreviw(true);
            }
          }}
          customStyles={customSubStyles}
          persistTableHead={true}
          pointerOnHover={true}
          highlightOnHover={false}
          noTableHead
        />
      </>
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoadUserData(true);
      let parentdata = [];
      if (!(role === "super_admin")) {
     

        const Ids = await getParentBySubLoc();
        setParentIds(Ids);
        parentdata = Ids;
      }

      const fetchData = await dispatch(
        fetchLocationsInitData({
          organisationId,
          role,
          allIds: parentdata,
          currentUserId,
        })
      );

      setLoadUserData(false);
      if (fetchData) {
        setFetchLoading(false);
        if (locationId) {
          let fetchallData = await dispatch(
            fetchAllLoctionData({
              organisationId,
              role,
              allIds: parentdata,
              currentUserId,
            })
          );

          let selectedLocation = null;
          for (let i = 0; i < fetchallData.payload.response.length; i++) {
            let each = cloneDeep(fetchallData.payload.response[i]);

            if (each?.attributes?.countId == locationId) {
              selectedLocation = each;
              break;
            } else {
              if (each?.attributes?.subLocations?.data) {
                for (
                  let j = 0;
                  j < each.attributes.subLocations.data.length;
                  j++
                ) {
                  let sub = cloneDeep(each.attributes.subLocations.data[j]);
                  if (sub.attributes?.countId == locationId) {
                    const reponse = await getLocationByID(
                      sub.id,
                      organisationId
                    );

                    if (reponse.status == 200) {
                      selectedLocation = reponse.data.data[0];
                    }
                    break;
                  }
                }
              }
              if (selectedLocation) {
                break;
              }
            }
          }

          if (selectedLocation) {
            setTargetedData(selectedLocation);
            setTogglePreviw(true);
          }
        }
      }
    };

    fetchData();
  }, [refresh,locationId]);

  const handleSort = async (column, sortDirection) => {
    setloader(true);

    // Assuming the selector represents the field to sort
    const sort = [`${column.sortField}:${sortDirection}`];
    setSortData(sort);
    const sortedData = await dispatch(
      fetchLocationsWithFilter({
        organisationId,
        searchName: debounceValue,
        page: page,
        pageSize: pageSize,
        location: filterOptions.locations,
        country: filterOptions.countries,
        city: filterOptions.cities,
        rate: filterOptions.rate,
        sort,
        role,
        allIds: parentIds,
        currentUserId,
      })
    );

    setloader(false);
  };

  const handleRemoveSorting = async () => {
    setloader(true);

    // Assuming the select or represents the field to sort
    const sort = undefined;
    setSortData(sort);
    const sortedData = await dispatch(
      fetchLocationsWithFilter({
        organisationId,
        searchName: debounceValue,
        page: page,
        pageSize: pageSize,
        location: filterOptions.locations,
        country: filterOptions.countries,
        city: filterOptions.cities,
        rate: filterOptions.rate,
        sort,
        role,
        allIds: parentIds,
        currentUserId,
      })
    );

    setloader(false);
  };

  const columns = [
    {
      selector: (row) => row.attributes?.subLocations?.data,

      width: selectedLanguage === "en" ? "30px" : "60px",
      cell: (row) =>
        row.attributes.subLocations.data.length > 0 ? (
          <div
            className="sub-header-count align-center"
            onClick={(e) => {
              e.stopPropagation();
              if (expandedRow !== row.id) {
                setExpandedRow(row.id);
              } else {
                setExpandedRow(null);
              }
            }}
          ></div>
        ) : (
          <Table.Cell className="aside-counter flexbox align-center"></Table.Cell>
        ),
    },
    {
      name: t.locations.table.ID,

      sortField: "countNumId",
      width: selectedLanguage === "en" ? "135px" : "141px",
      sortable: true,
      cell: (row) => {
        const filteredDataSub =
          row?.attributes?.subLocations?.data?.filter((item) =>
            allIds?.includes(item.id)
          ) || [];

        const subLocationCount = !(role === "super_admin")
          ? filteredDataSub?.length
          : row?.attributes?.subLocations?.data?.length;
        return selectedLanguage === "en" ? (
          <div
            onClick={() => {
              if (!(role === "super_admin")) {
                if (allIds?.includes(row.id)) {
                  setTargetedData(row);
                  setTogglePreviw(true);
                }
              } else {
                setTargetedData(row);
                setTogglePreviw(true);
              }
            }}
            className={`id-text-style`}
          >
            {row?.attributes?.countId}
            {subLocationCount > 0 ? (
              <span
                style={{ color: "#0C66E4" }}
                className="ml-2 mr-2 link-span"
              >
                <LinkBlueIcon />
                <span className="ml-1">{subLocationCount}</span>
              </span>
            ) : null}
          </div>
        ) : (
          <div
            onClick={() => {
              if (!(role === "super_admin")) {
                if (allIds?.includes(row.id)) {
                  setTargetedData(row);
                  setTogglePreviw(true);
                }
              } else {
                setTargetedData(row);
                setTogglePreviw(true);
              }
            }}
            className={`id-text-style d-flex`}
          >
            {row?.attributes?.countId}
            {subLocationCount ? (
              <span
                style={{ color: "#0C66E4" }}
                className="ml-2 mr-2 link-span"
              >
                <span className="ml-1">{subLocationCount}</span>
                <LinkBlueIcon />
              </span>
            ) : null}
          </div>
        );
      },
    },
    {
      name: t.locations.form.location_name,
      width: "180px",

      sortField: "name",
      sortable: true,
      cell: (row) => (
        <span
          onClick={() => {
            if (!(role === "super_admin")) {
              if (allIds?.includes(row.id)) {
                setTargetedData(row);
                setTogglePreviw(true);
              }
            } else {
              setTargetedData(row);
              setTogglePreviw(true);
            }
          }}
          className="id-text-style"
        >
          {row?.attributes?.name
            ? DisplayFormattedText(row?.attributes?.name, 19)
            : t.common.na}
        </span>
      ),
    },
    {
      name: t.locations.table.address,
      width: "196px",
      
      sortField: "address",
      sortable: true,
      cell: (row) => (
        <span
          onClick={() => {
            if (!(role === "super_admin")) {
              if (allIds?.includes(row.id)) {
                setTargetedData(row);
                setTogglePreviw(true);
              }
            } else {
              setTargetedData(row);
              setTogglePreviw(true);
            }
          }}
          className=""
        >
          {row?.attributes?.address
            ? DisplayFormattedText(row?.attributes?.address, 19)
            : t.common.na}
        </span>
      ),
    },
    {
      name: t.locations.table.country,

      sortField: "countryName",
      sortable: true,
      minWidth: "156px",
      cell: (row) =>
        row.attributes.country ? (
          <span
            onClick={() => {
              if (!(role === "super_admin")) {
                if (allIds?.includes(row.id)) {
                  setTargetedData(row);
                  setTogglePreviw(true);
                }
              } else {
                setTargetedData(row);
                setTogglePreviw(true);
              }
            }}
          >
            {DisplayFormattedText(
              Country.getCountryByCode(row.attributes.country)?.name,
              15
            ) || "N/A"}
          </span>
        ) : (
          t.common.na
        ),
    },
    {
      name: t.locations.table.city,
      minWidth: "156px",

      sortField: "cityName",
      sortable: true,
      cell: (row) => {
        const citiesOfCountry = State.getStatesOfCountry(
          row.attributes.country
        );
        const the_city = row?.attributes?.city
          ? citiesOfCountry.filter((city) => {
              return city.isoCode == row?.attributes?.city;
            })[0]?.name
            ? citiesOfCountry.filter((city) => {
                return city.isoCode == row?.attributes?.city;
              })[0]?.name
            : row?.attributes?.cityName
            ? row?.attributes?.cityName
            : t.common.na
          : t.common.na;
        const formatted_address = DisplayFormattedText(the_city, 10);

        return (
          <span
            onClick={() => {
              if (!(role === "super_admin")) {
                if (allIds?.includes(row.id)) {
                  setTargetedData(row);
                  setTogglePreviw(true);
                }
              } else {
                setTargetedData(row);
                setTogglePreviw(true);
              }
            }}
          >
            {formatted_address}
          </span>
        );
      },
    },
    {
      name: t.locations.table.customer,

      sortField: "customers.name",
      sortable: true,
      minWidth: "160px",
      cell: (row) => {
        const locationData = row?.attributes.customers?.data;
        if (locationData?.length > 0) {
          const firstLocation = locationData[0]?.attributes?.name.substring(
            0,
            12
          );
          const remainingCount = locationData.length - 1;
          const remainingLocations = locationData
            .slice(1)
            .map((location) => location.attributes.name)
            .join(", ");
          const displayText =
            remainingCount > 0
              ? `${firstLocation} ... +${remainingCount}`
              : firstLocation;
          return (
            <span
              onClick={() => {
                if (!(role === "super_admin")) {
                  if (allIds?.includes(row.id)) {
                    setTargetedData(row);
                    setTogglePreviw(true);
                  }
                } else {
                  setTargetedData(row);
                  setTogglePreviw(true);
                }
              }}
              title={locationData
                .map((location) => location.attributes.name)
                .join(", ")}
            >
              {displayText}
            </span>
          );
        } else {
          return t.common.na;
        }
      },
    },

    {
      width: "200px",
      name: t.locations.form.propertyType,

      sortField: "propertyType.property",
      sortable: true,
      cell: (row) => {
        const locationData = row.attributes?.propertyType;
        if (locationData && Array.isArray(locationData)) {
          const displayText = locationData
            .map((location) => {
              const nameParts = location?.property;

              return nameParts;
            })
            .join(", "); // Adjust the join character as needed
          return (
            <span
              onClick={() => {
                if (!(role === "super_admin")) {
                  if (allIds?.includes(row.id)) {
                    setTargetedData(row);
                    setTogglePreviw(true);
                  }
                } else {
                  setTargetedData(row);
                  setTogglePreviw(true);
                }
              }}
            >
              {displayText
                ? DisplayFormattedText(displayText, 20)
                : t.common.na}
            </span>
          );
        }
      },
    },

    {
      name: t.locations.add_sub,
      selector: (row) => row,

      cell: (row) => (
        <>
          {userPermissions?.location?.create ? (
            <span
              title="Add Sub-Location"
              onClick={(e) => {
                e.stopPropagation();
                setSelectedParentLocation(row);
                setToggleAsideModal(true);
              }}
            >
              <span className="item-button-icon-container">
                <AddIconWithCircle width="20px" height="20px" />
              </span>
            </span>
          ) : (
            <span style={{ height: "32px" }} className=""></span>
          )}
        </>
      ),
    },
  ];

  const handleSetDataByFilter = (newData) => {};

  const getPaginatedData = (dataToPaginate) => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return dataToPaginate.slice(startIndex, endIndex);
  };

  const totalFilteredPages = Math.ceil(data.length / pageSize);

  return (
    <>
      <div className="new-listing-container table-header-dashboard stick-header pb-5">
        <div className="header-actions">
          <Header className="procedure-title mt-5" as="h2">
            {" "}
            <LocationIcon />
            {t.locations.title}
          </Header>
          <div></div>

          {userPermissions?.location?.create && initData.length > 0 ? (
            <button
              onClick={() => {
                setToggleAsideModal(true);
              }}
              type="button"
              className="add-button location"
            >
              <span className="add-button-text"> {t.locations.add}</span>
              <span className="add-button-img">
                <PlusIcon />
              </span>
            </button>
          ) : null}
        </div>
        {!(initLoading || loadUserData) ? (
          <div
            className={
              initData?.length > 0
                ? "new-listing-filter wo-filter sticky-filter mt-8"
                : ""
            }
          >
            <Filters
              data={initData}
              setAssetUploadModal={setAssetUploadModal}
              assetUploadModal={assetUploadModal}
              allIds={allIds}
              parentIds={parentIds}
              currentUserId={currentUserId}
              role={role}
              setDataByFilter={handleSetDataByFilter}
              hasStatus={false}
              fileData={data}
              setFilterOptions={setFilterOptions}
              filterOptions={filterOptions}
              setloader={setloader}
              loader={loader}
              setSortData={setSortData}
              sortData={sortData}
              setSearchTitle={setSearchTitle}
              searchTitle={searchTitle}
              debounceValue={debounceValue}
            />
          </div>
        ) : null}
      </div>
      <div className="new-listing-container">
        {initLoading || loadUserData ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ minHeight: "500px" }}
          >
            <Animation />
          </div>
        ) : (
          <div>
            <div className="new-listing-filter">
              {initData?.length > 0 ? (
                <>
                  {error ? (
                    <div className="mt-8 mb-8 ">
                      <Animation />
                    </div>
                  ) : (
                    <DataTable
                      className="datatable-div"
                      columns={columns}
                      onSort={handleSort}
                      fixedHeader
                      data={data}
                      expandableRows
                      expandableRowsComponent={ExpandedComponent}
                      expandableRowDisabled={(row) =>
                        row.attributes.subLocations?.data.length > 0
                          ? false
                          : true
                      }
                      noDataComponent={
                        initData.length > 0 ? (
                          <div className="no-data-container">
                            <div className="">
                              <p className="no-data-text">
                                {t.common.no_result_found}
                              </p>
                            </div>
                          </div>
                        ) : null
                      }
                      direction={selectedLanguage === "en" ? "ltr" : "rtl"}
                      onRowClicked={(row) => {
                        if (!(role === "super_admin")) {
                          if (allIds?.includes(row.id)) {
                            setTargetedData(row);
                            setTogglePreviw(true);
                          }
                        } else {
                          setTargetedData(row);
                          setTogglePreviw(true);
                        }
                      }}
                      sortIcon={
                        sortData ? (
                          <div>
                            <span>
                              <SortIcon />
                            </span>
                            <span
                              onClick={(e) => {
                                e.stopPropagation();
                                handleRemoveSorting();
                              }}
                            >
                              <CloseIcon />
                            </span>
                          </div>
                        ) : (
                          <span></span>
                        )
                      }
                      customStyles={customStyles}
                      persistTableHead={true}
                      pointerOnHover={true}
                      highlightOnHover={true}
                      progressPending={loader}
                      progressComponent={
                        <div className="mt-8 mb-8 ">{t.common.loading}</div>
                      }
                    />
                  )}

                  <div className="d-flex justify-content-end">
                    <Pagination
                      ofString={t.common.of}
                      total={total}
                      prevItem={
                        selectedLanguage === "en" ? (
                          <ArrowLeft />
                        ) : (
                          <ArrowRight />
                        )
                      }
                      nextItem={
                        selectedLanguage === "en" ? (
                          <ArrowRight />
                        ) : (
                          <ArrowLeft />
                        )
                      }
                      page={page}
                      limit={pageSize}
                      handleNext={async () => {
                        setloader(true);
                        await dispatch(
                          fetchLocationsWithFilter({
                            organisationId,
                            searchName: "",
                            page: page + 1,
                            pageSize: pageSize,
                            customer: filterOptions.customers,
                            country: filterOptions.countries,
                            city: filterOptions.cities,
                            sort: sortData,
                            role,
                            allIds: parentIds,
                            currentUserId,
                          })
                        );
                        setloader(false);
                      }}
                      handlePrevious={async () => {
                        setloader(true);
                        await dispatch(
                          fetchLocationsWithFilter({
                            organisationId,
                            searchName: "",
                            page: page - 1,
                            pageSize: pageSize,
                            customer: filterOptions.customers,
                            country: filterOptions.countries,
                            city: filterOptions.cities,
                            sort: sortData,
                            role,
                            allIds: parentIds,
                            currentUserId,
                          })
                        );
                        setloader(false);
                      }}
                    />
                  </div>
                </>
              ) : (
                <div className="no-data-container">
                  <div className="no-work-orders pb-15">
                    <LocationBlueIcon />
                    <p className="no-data-text">
                      {t.common.there_no}{" "}
                      <span
                        className="link-text"
                        style={{ position: "relative", cursor: "pointer" }}
                        onMouseOver={() => setShowTooltip(true)}
                        onMouseLeave={() => setShowTooltip(false)}
                      >
                        <Tooltip
                          color="#fff"
                          backgroundColor="#172B4D"
                          position="top left"
                          show={showTooltip}
                          textBoxWidth={
                            selectedLanguage == "ar" ? "90px" : "65px"
                          }
                          arrowAlign="start"
                          fontSize="14px"
                          fontWeight="400"
                          fontFamily={
                            selectedLanguage == "ar"
                              ? "Noto Kufi Arabic"
                              : "Roboto"
                          }
                          padding="0px 0px 0px 8px"
                          borderRadius="5px"
                          static
                          moveRight={selectedLanguage == "ar" ? "55px" : "76px"}
                          moveUp="-1px"
                        >
                          <span className="work-order-tooltip">
                            {t.locations.how_to_add_locations}
                          </span>
                        </Tooltip>
                        {" " + t.locations.title + " "}
                      </span>{" "}
                      {t.common.added}
                    </p>
                    {userPermissions?.location?.create ? (
                      <div
                        style={{ gap: "15px" }}
                        className="d-flex justify-content-center"
                      >
                        <button
                          className="work-order-button-transparent"
                          onClick={() => {
                            setToggleAsideModal(true);
                          }}
                        >
                          {t.assets.start_add_asset}
                        </button>

                        <button
                          className="work-order-button-transparent"
                          onClick={() => setAssetUploadModal(true)}
                        >
                          {t.common.bulk_upload}
                        </button>
                      </div>
                    ) : null}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        <Add
          toggled={toggleAsideModal}
          allIds={allIds}
     
          parentIds={parentIds}
          setParentIds={setParentIds}
          role={role}
          currentUserId={currentUserId}
          untoggle={() => {
            setToggleAsideModal(false);
            setModalWidth(Math.max(window.innerWidth / 2, 514));
            setSelectedParentLocation(null);
          }}
          setRefresh={setRefresh}
          refresh={refresh}
          parentLocation={selectedParentLocation}
        />

        <Edit
          targetedData={targetedData}
          allIds={allIds}
          parentIds={parentIds}
          role={role}
          currentUserId={currentUserId}
          toggled={toggleEditModal}
          refresh={refresh}
          setRefresh={setRefresh}
          untoggle={() => {
            setModalWidth(Math.max(window.innerWidth / 2, 514));
            setTargetedData();
            setToggleEditModal(false);
          }}
        />

        <Preview
          targetedData={targetedData}
          toggled={togglePreview}
          setModalWidth={setModalWidth}
          modalWidth={modalWidth}
          edit={() => {
            setTogglePreviw(false);
            setToggleEditModal(true);
          }}
          untoggle={() => {
            setTargetedData();
            setModalWidth(Math.max(window.innerWidth / 2, 514));
            setTogglePreviw(false);
            history.push("/locations");
          }}
          refresh={refresh}
          setRefresh={setRefresh}
        />
      </div>
    </>
  );
};

export default connect(null, auth.actions)(Locations);
