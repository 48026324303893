import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Tab } from "semantic-ui-react";
import {
  addNewComment,
  getAllComments,
  uploadFile,
} from "../../../config/functions";
import { selectTranslations } from "../../../config/i18n/slice";
import UserProfilePlaceholder from "../../../components/common/userProfilePlaceholder";
import {
  CommentIcon,
  LogTabIcon,
} from "../../../../Assets/Icons/svg";
import CommentTab from "./actibityTab/comments/commentTab";
import AllDetails from "./actibityTab/allDetails.js/allDetails";
import { toast } from "react-toastify";

const Comments = ({ data }) => {
  const t = useSelector(selectTranslations);
  const [comment, setComment] = useState("");

  const [attachments, setAttachments] = useState({
    image: [],
  });
  

  const [commentLoading, setCommentLoading] = useState(false);
  const [allComments, setAllComments] = useState("");
  const [commentPage, setCommentPage] = useState(1);
  const [commentTotal, setCommentTotal] = useState();

  const onFileChange = async (files, name) => {
    let newFiles = [];
    Array.from(files).forEach((file) => {
      const randomnumber = Math.floor(Math.random() * 100); // Generate random ID
      newFiles.push({
        file: file,
        id: randomnumber,
        name: file.name,
        type: file.type,
        cancelPopup: false,
        isChanged: true,
        previewUrl: URL.createObjectURL(file),
        isUploading: true, // Mark as uploading initially
        url: null, // Initially, set URL as null until upload is complete
      });
    });

    setAttachments((prevAttachments) => ({
      ...prevAttachments,
      [name]: [...(prevAttachments[name] || []), ...newFiles], // Keep previous files
    }));
    // Upload each file and update the attachments with the uploaded URLs
    const uploadedFiles = await Promise.all(
      newFiles.map(async (fileObj) => {
        try {
          const formData = new FormData();
          formData.append("file", fileObj.file); // Assuming API expects a "file" field

          const uploadResponse = await uploadFile(formData); // Replace `uploadFile` with your actual API function

          if (uploadResponse?.status === 200) {
            return {
              ...fileObj,
              url: uploadResponse.data[0]?.url || null, // Add the uploaded URL
              fileID: uploadResponse.data[0]?.id || null, // Add the uploaded URL
              isUploading: false, // Upload completed
            };
          } else {
            toast.error(t.common.something_wrong);
            return { ...fileObj, isUploading: false };
          }
        } catch (error) {
          toast.error(t.common.something_wrong);
          return { ...fileObj, isUploading: false };
        }
      })
    );

    // Update the attachments state with the uploaded URLs
    setAttachments((prevAttachments) => ({
      ...prevAttachments,
      [name]: prevAttachments[name].map((file) =>
        file.isUploading
          ? uploadedFiles.find((f) => f.id === file.id) || file
          : file
      ),
    }));
  };

  const addComment = async () => {
    const fileIDs = attachments?.image
      ?.filter((attachment) => attachment?.fileID) // Filter objects that have fileID
      ?.map((attachment) => attachment.fileID);

    const response = await addNewComment(data?.id, comment, fileIDs);

    if (response && response.status === 200) {
      toast.success(t.workOrders.activityTab.commentSuccess);
      setComment("");
      setCommentPage(1);
      if (commentPage === 1) {
        fetchAllComments();
      }
      setAttachments({
        image: [],
      });
    } else {
      toast.error(t.common.something_wrong);
    }
  };

  const handleRemoveAttachment = (id) => {
    const updatedAttachments = attachments?.image?.filter(
      (attachment) => attachment?.id !== id
    );

    setAttachments((prev) => {
      return {
        ...prev,
        image: updatedAttachments,
      };
    });
  };

  const fetchAllComments = async () => {
    setCommentLoading(true);
    const response = await getAllComments(data?.id, commentPage);

    if (response && response?.status === 200) {
      setAllComments(response?.response);
      setCommentTotal(response?.pagination?.total);
      setCommentPage(response?.pagination?.page);
    } else if (response.status == 400) {
      if (response?.error && response?.error?.message) {
        toast.error(response.error.message);
      }
    } else {
      toast.error(t.common.something_wrong);
    }
    setCommentLoading(false);
  };

  const panes = [
    {
      menuItem: t.common.all,
      pane: {
        key: "all",
        content: (
          <AllDetails
            data={data}
            fetchAllComment={fetchAllComments}
            addComment={addComment}
            comment={comment}
            setComment={setComment}
            attachments={attachments}
            onFileChange={onFileChange}
            setAttachments={setAttachments}
            handleRemoveAttachment={handleRemoveAttachment}
          />
        ),
      },
    },
    {
      menuItem: {
        key: "logs",
        icon: <LogTabIcon />,
        content: t.workOrders.details_view.log,
      },

      pane: {
        key: "logs",
        content: <div className="pane-body"></div>,
      },
    },
    {
      menuItem: {
        key: "comment",
        icon: <CommentIcon />,
        content: t.workOrders.details_view.comments,
      },
      pane: {
        key: "comment",
        content: (
          <CommentTab
            page={commentPage}
            allComments={allComments}
            setAllComments={setAllComments}
            setPage={setCommentPage}
            total={commentTotal}
            setTotal={setCommentTotal}
            setLoading={setCommentLoading}
            loading={commentLoading}
            fetchAllComments={fetchAllComments}
            addComment={addComment}
            comment={comment}
            setComment={setComment}
            attachments={attachments}
            setAttachments={setAttachments}
            onFileChange={onFileChange}
            handleRemoveAttachment={handleRemoveAttachment}
            data={data}
          />
        ),
      },
    },
  ];

  return (
    <>
      <Tab className="comments-tab" panes={panes} renderActiveOnly={false} />
    </>
  );
};
export default Comments;
