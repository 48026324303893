import React, { useState, useEffect } from "react";
import {
  Modal,
  Dropdown,
  Grid,
  Input,
  Form,
  Button,
  Divider,
  Checkbox,
  TextArea,
  Radio,
  Dimmer,
  Loader,
  GridColumn,
} from "semantic-ui-react";
import ReactDOMServer from "react-dom/server";
import { format, setDate } from "date-fns";
import calendar_icon from "../../../Assets/Icons/calender-icon.svg";
import clock_icon from "../../../Assets/Icons/clock.svg";
import location_icon from "../../../Assets/Icons/location_on.jpg";
import parent_icon from "../../../Assets/Icons/parentAsset.png";
import child_icon from "../../../Assets/Icons/childAsset.png";
import gchild_icon from "../../../Assets/Icons/grandChild.png";
import sub_location_icon from "../../../Assets/Icons/pin_drop.png";
import {
  getActiveUsersByRole,
  getAllTeams,
  uploadFile,
  IMAGES_URL,
  createNewWorkOrder,
  createNewSchedule,
  updateWorkRequestStatus,
  getAllCustomers,
  getAllProcedures,
  getAllPartsByAssets,
  handleKeyDown,
  getAllAreaByLocationId,
  getAllParts,
  getAllSets,
  getAllParentLocations,
  getAllAssetsOption,
  currencyDecimalPlaces,
  getAllWOPartsData,
  fetchInvLocationsOptions,
} from "../../config/functions";

import { useDispatch, useSelector } from "react-redux";
import { selectTranslations } from "../../config/i18n/slice";

import AddCustomerModal from "./popups/add-customer";
import AddLocationModal from "./popups/add-location";
import AddPeopleModal from "./popups/add-people";
import AddProcedureModal from "../Procedures/add";
import AddAssetModal from "./popups/add-asset";
import AddTeamModal from "./popups/add-team";
import { LetteredAvatarImageUrl } from "../../components/common/userProfilePlaceholder";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import { useDropzone } from "react-dropzone";
import { useSuccessModal } from "../../../hooks/Messages/useSuccessModal";
import {
  AddIconWithCircle,
  DeleteIcon,
  CategoryInspection,
  CategoryMechanical,
  CloseIcon,
  Damage,
  Electrical,
  OperationProcedure,
  Project,
  Refrigeration,
  Safety,
  CatgoryPreventive,
  CorrectiveIcon,
  PlannedIcon,
  UnPlannedIcon,
  CustomerPlusIcon,
  SearchIcon,
  ArrowLeft,
  ArrowRight,
  FilesIcon,
  DocxImg,
  ImgPhotos,
  PngPhotos,
  Mp4File,
  TxtFile,
  CsvImg,
  DocImg,
  XlsxFile,
  PdfBlueIcon,
  InfoIconBlue,
  InfoIcon,
  FlagWhiteIcon,
  RepeatIcon,
  RepeatOnIcon,
  EndSchedualIcon,
  FlagwithFilled,
} from "../../../Assets/Icons/svg";
import Asterisk from "../../components/common/Asterisk";
import DatePicker from "react-multi-date-picker";
import { scrollToError } from "../../../utilFunctions/scrollToError";
import ToolTip from "react-power-tooltip";
import { fetchWOInitData } from "../../../redux/reducers/workOrderReducer";
import { Resizable } from "re-resizable";
import useNewCancelModal from "../../../hooks/Messages/useNewCncelModal";
import _ from "lodash";
import { usePermissionsSimplified } from "../../../hooks/usePermissionsSimplified";
import DataTable from "react-data-table-component";
import Pagination from "../../components/common/CommonPagination";
import useDisplayFormattedText from "../../../hooks/useDisplayFormattedText";

/*
 * The FileUpload component in JavaScript allows users to upload files with customizable drag and drop functionality.
 */

const FileUpload = ({
  onFileChange,
  name = "",
  onCertificateFileChange,
  attachments = {},
  setAttachments = () => {},
}) => {
  const fileTypes = {
    images: [
      "image/jpeg",
      "image/jpg",
      "image/png",
      "image/gif",
      "image/svg+xml",
      "image/webp",
      "image/heic",
    ],
    documents: [
      "text/plain",
      "application/pdf",
      "text/csv",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ],
    video: ["video/mp4", "video/quicktime", "video/webm", "video/hevc"],
  };

  const fileTypesCompare = {
    images: [
      "image/jpeg",
      "image/jpg",
      "image/png",
      "image/gif",
      "image/svg+xml",
      "image/webp",
      "image/heic",
    ],
    txt: ["text/plain"],
    csv: ["text/csv"],
    docx: [
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ],
    xlsx: [
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ],
    documents: ["application/msword"],
    pdf: ["application/pdf"],
    video: [
      "video/mp4",
      "video/quicktime",
      "application/octet-stream",
      "video/webm",
      "video/hevc",
    ],
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      const allowedFileTypes =
        name === "image"
          ? [...fileTypes.images, ...fileTypes.video]
          : [...fileTypes.images, ...fileTypes.documents, ...fileTypes.video];

      // Validate file types
      const invalidFiles = acceptedFiles.filter(
        (file) => !allowedFileTypes.includes(file.type)
      );

      // Show toast if there are invalid files
      if (invalidFiles?.length > 0) {
        toast.error(t.common.invalid_msg);
        return;
      }
      let totalFile = attachments?.warrantyFile?.length + acceptedFiles?.length;
      let totalAttachment = organisationImage?.numberOfAttachments
        ? organisationImage?.numberOfAttachments
        : 10;
      let totalSize = organisationImage?.attachmentsSize
        ? organisationImage?.attachmentsSize
        : 200;
      if (name !== "image" && totalFile > totalAttachment) {
        toast.error(
          `${t.common.number_error_1} ${totalAttachment} ${t.common.number_error_2}`
        );
        return;
      }
      const validFiles = acceptedFiles.filter((file) => {
        const isImage = fileTypes.images.includes(file.type);
        const isDocument = fileTypes.documents.includes(file.type);
        const isVideo = fileTypes.video.includes(file.type);

        const validSize =
          (isVideo || isImage || isDocument) &&
          file.size < totalSize * 1024 * 1024;

        if (!validSize) {
          toast.error(
            `${t.common.file_upload_valid} ${totalSize} ${t.common.file_upload_second}`
          );
          return false;
        }
        return true;
      });

      name === "image"
        ? onFileChange(validFiles, name)
        : onCertificateFileChange(validFiles, name);
    },
  });

  const t = useSelector(selectTranslations);
  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return "en";
    }
  });

  const organisationImage = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation;
    } else {
      return null;
    }
  });

  const renderFile = (file, index) => (
    <div
      className={`image-dropzone blue-border p-1 file d-flex justify-content-between text-start ${
        index == 0 ? "" : "mt-5"
      }`}
    >
      <div>
        <label
          style={{ fontSize: "14px" }}
          className="d-flex align-items-center m-1"
        >
          <div
            className={`${
              selectedLanguage == "en" ? "ml-4 mr-4" : "ml-4 mr-4"
            }`}
          >
            {fileTypesCompare?.images?.includes(file?.type) ? (
              <PngPhotos />
            ) : fileTypesCompare?.txt?.includes(file?.type) ? (
              <TxtFile />
            ) : fileTypesCompare?.csv?.includes(file?.type) ? (
              <CsvImg />
            ) : fileTypesCompare?.documents?.includes(file?.type) ? (
              <DocImg />
            ) : fileTypesCompare?.video?.includes(file?.type) ? (
              <Mp4File />
            ) : fileTypesCompare?.pdf?.includes(file?.type) ? (
              <PdfBlueIcon />
            ) : fileTypesCompare?.docx?.includes(file?.type) ? (
              <DocxImg />
            ) : fileTypesCompare?.xlsx?.includes(file?.type) ? (
              <XlsxFile />
            ) : null}
          </div>

          {file?.name}
        </label>
      </div>

      <div
        className="mr-5 ml-5 mt-1 c-pointer"
        onClick={(e) => {
          e.stopPropagation();
          const newCovidCopy = attachments.warrantyFile.filter(
            (covidFile, i) => i !== index
          );
          setAttachments({
            ...attachments,
            warrantyFile: newCovidCopy,
          });
        }}
      >
        <CloseIcon width={"18px"} height={"18px"} />
      </div>
    </div>
  );

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      {name === "image" ? (
        attachments?.image?.name ? (
          <div
            className={`image-dropzone blue-border p-1 file d-flex justify-content-between text-start
            `}
          >
            <div>
              <label
                style={{ fontSize: "14px" }}
                className="d-flex align-items-center m-1"
              >
                <div
                  className={`${
                    selectedLanguage == "en" ? "ml-4 mr-4" : "ml-4 mr-4"
                  }`}
                >
                  {fileTypes?.images?.includes(attachments?.image.type) ? (
                    <PngPhotos />
                  ) : (
                    <Mp4File />
                  )}
                </div>

                {attachments?.image?.name}
              </label>
            </div>

            <div
              className="mr-5 ml-5 mt-1 c-pointer"
              onClick={(e) => {
                e.stopPropagation();
                setAttachments({
                  ...attachments,
                  image: null,
                });
              }}
            >
              <CloseIcon width={"18px"} height={"18px"} />
            </div>
          </div>
        ) : (
          <div className="image-dropzone  text-center mb-5">
            <div>
              <p className="p1">
                <div className="d-flex align-items-start justify-content-center ">
                  <div>
                    {" "}
                    <ImgPhotos />
                  </div>
                  <div
                    className={`mr-2 ml-2 ${
                      selectedLanguage == "en" ? "text-left" : "text-right"
                    }`}
                  >
                    {" "}
                    <div className="" style={{ color: "#0C66E4" }}>
                      {" "}
                      {t.workOrders.drag_img}
                    </div>{" "}
                    <p
                      className="mb-0"
                      style={{ color: "#788CA5", fontSize: "12px" }}
                    >
                      {" "}
                      {t.common.valid_files_video}
                    </p>{" "}
                  </div>
                </div>
              </p>
            </div>
          </div>
        )
      ) : attachments?.warrantyFile?.length > 0 ? (
        <>
          {attachments.warrantyFile.map((file, index) =>
            renderFile(file, index)
          )}

          <div className="warrenty-file-dropzone mt-5 d-flex align-items-center justify-content-between">
            <div className="d-flex">
              <span className="mt-1">
                <FilesIcon />
              </span>
              <div className="pl-4 pr-4 d-flex align-items-center">
                <p className="p1 m-0">{t.common.files}</p>
                <span style={{ color: "#788CA5" }} className="ml-3">
                  {t.common.files_attachments}
                </span>
              </div>
            </div>
            <div>
              <AddIconWithCircle />
            </div>
          </div>
        </>
      ) : (
        <div className="warrenty-file-dropzone d-flex align-items-center justify-content-between">
          <div className="d-flex">
            <span className="mt-1">
              <FilesIcon />
            </span>
            <div className="pl-4 pr-4 d-flex align-items-center">
              <p className="p1 m-0">{t.common.files}</p>
              <span style={{ color: "#788CA5" }} className="ml-3">
                {t.common.files_attachments}
              </span>
            </div>
          </div>
          <div>
            <AddIconWithCircle />
          </div>
        </div>
      )}
    </div>
  );
};

function Add({
  toggled,
  untoggle,
  fromWR = false,
  dueDateParam,
  request,
  setRefresh,
  refresh,
  setFilterOptions,
  setSelectedDates,
  setSearchTitle,
  WRtitle,
  WRdescription,
  setWRdata,
  WRordertype,
  WRID,
  WRpriority,
  WRasset,
  WRlocation,
  WRstatus,
  WRFile,
  modalPrevWidth,
  setmodalPrevWidth,
  allIds,
  roleData,
  currentUserId,

  handleSuccess = () => {},
}) {
  const t = useSelector(selectTranslations);
  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });

  const organisationTimezone = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.timezone;
    } else {
      return null;
    }
  });
  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return "en";
    }
  });
  const getLocalTime = (offset) => {
    // `offset` is the numeric timezone offset (e.g., 2 for UTC+2, 3 for UTC+3)
    const now = new Date(); // Get the current time in local timezone
    const utcMilliseconds = now.getTime() + now.getTimezoneOffset() * 60000; // Convert local time to UTC
    const localMilliseconds = utcMilliseconds + offset * 3600000; // Add timezone offset in milliseconds

    const localDate = new Date(localMilliseconds);

    return localDate.toTimeString().slice(0, 5); // Format as "HH:mm"
  };

  const [partsData, setPartsData] = useState([]);
  const [partSearchTitle, setPartSearchTitle] = useState("");
  const {
    userPermissions,
    checkForLinkPermission,
  } = usePermissionsSimplified();
  const [areAllSelected, setAreAllSelected] = useState(false);
  const [attachments, setAttachments] = useState({
    image: null,
    warrantyFile: [],
  });

  const [showPeopleTooltip, setPeopleTooltip] = useState(false);

  useEffect(() => {
    const fetchFile = async () => {
      if (WRFile && WRFile.file instanceof Promise) {
        const resolvedFile = await WRFile.file;
        setAttachments((prevAttachments) => ({
          ...prevAttachments,
          image: {
            ...WRFile,
            file: resolvedFile,
          },
        }));
      } else if (WRFile) {
        setAttachments((prevAttachments) => ({
          ...prevAttachments,
          image: WRFile,
        }));
      }
    };

    fetchFile();
  }, [WRFile]);
  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "#E9F2FF",
        paddingTop: "8px",
      },
    },
    cells: {
      style: {
        paddingTop: "20px",
        paddingBottom: "8px",
      },
    },
  };
  const dispatch = useDispatch();

  const onCertificateFileChange = (files, name) => {
    setInitChange(true);
    let newFiles = [];
    Array.from(files).forEach((file) => {
      var randomnumber = Math.floor(Math.random() * (0 - 100 + 1)) + 100;
      newFiles.push({
        file: file,
        id: randomnumber,
        name: file.name,
        type: file.type,
        cancelPopup: false,
        isChanged: true,
      });
    });

    setAttachments({
      ...attachments,
      [name]: [...attachments[name], ...newFiles],
    });
  };

  const organisationCurrency = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.currency;
    } else {
      return "JOD";
    }
  });

  const conditionalRowStyles = [
    {
      when: (row) => row?.disable == true,
      style: {
        backgroundColor: "#e4e4e4", // Apply red color to the row
      },
    },
  ];

  const handleRowSelected = (row, checked, selectedPartsData = []) => {
    let allSelectedRows = [...selectedPartsData];
    let selectedRows = [...prevPartsData];

    if (checked) {
      allSelectedRows.push({ ...row, selectedPart: true, selectedPart: true });
      selectedRows.push({ ...row, selectedPart: true });
    } else {
      allSelectedRows = allSelectedRows.filter((el) => el?.id !== row?.id);
      selectedRows = selectedRows.filter((el) => el?.id !== row?.id);
    }

    const updatedData = [...partsData].map((item) => ({
      ...item,
      selectedPart: allSelectedRows.some((el) => el.id === item.id),
    }));

    const updatedInitData = [...initPartsData].map((item) => ({
      ...item,
      selectedPart: allSelectedRows.some((el) => el.id === item.id),
    }));

    // Check if all rows are selected to update "Select All" checkbox
    const allRowsSelected = updatedData.every((item) => item.selectedPart);

    setPartsData(updatedData);
    setInitPartsData(updatedInitData);
    setSelectedTablePartData(allSelectedRows);
    setPrevPartsData(selectedRows);
    setAreAllSelected(allRowsSelected);
  };

  const handleSelectAll = (checked) => {
    let updatedData = [...partsData];
    let selectedRows = [...prevPartsData];
    let allSelectedRowsData = [...selectedTablePartData]; // Clone the existing selected data

    if (checked) {
      updatedData = updatedData.map((item) =>
        item.disable
          ? item // Skip if disabled
          : { ...item, selectedPart: true }
      );

      // Add the selected parts to allSelectedRowsData, avoiding duplicates
      updatedData.forEach((item) => {
        if (!item.disable) {
          const alreadySelected = allSelectedRowsData.some(
            (selectedItem) => selectedItem.id === item.id
          );

          if (!alreadySelected) {
            allSelectedRowsData.push({ ...item, selectedPart: true });
            selectedRows.push({ ...item, selectedPart: true });
          }
        }
      });
    } else {
      // If unchecked, mark all rows as deselected, but skip if disabled
      updatedData = updatedData.map((item) =>
        item.disable
          ? item // Skip if disabled
          : { ...item, selectedPart: false }
      );

      // Remove the deselected parts from allSelectedRowsData
      allSelectedRowsData = allSelectedRowsData.filter(
        (selectedItem) =>
          !updatedData.some(
            (updatedItem) =>
              updatedItem.id === selectedItem.id && !updatedItem.disable
          )
      );

      selectedRows = allSelectedRowsData.filter(
        (selectedItem) =>
          !updatedData.some(
            (updatedItem) =>
              updatedItem.id === selectedItem.id && !updatedItem.disable
          )
      );
    }

    const updatedInitData = [...initPartsData].map((item) => ({
      ...item,
      selectedPart:
        !item.disable && allSelectedRowsData.some((el) => el.id === item.id),
    }));

    // Update state
    setPartsData(updatedData);
    setInitPartsData(updatedInitData);
    setSelectedTablePartData(allSelectedRowsData); // Update selected rows
    setPrevPartsData(selectedRows); // Update previous state
    setAreAllSelected(checked); // Update the "Select All" state
  };

  const handleAddPartData = async (setFieldValue) => {
    setAddPartModal(false);
    setPrevPartsData([]);
    setPartSearchTitle("");
    setSelectedInvLoc("");
    setCurrentPage(1);
    setSelectedFinalPartData(selectedTablePartData);
  };
  const { DisplayFormattedText } = useDisplayFormattedText();
  const [initChange, setInitChange] = useState(false);
  const [cancelModal, toggleCancelModal] = useState(false);
  const [buttonStatus, setButtonStatus] = useState(0);
  const [successModal, toggleSuccessModal] = useState(false);
  const { setNewCancelModal, NewCancelModal } = useNewCancelModal();
  const { setSuccessModal, SuccessModal } = useSuccessModal();

  const [addCustomerModal, toggleAddCustomerModal] = useState(false);
  const [addLocationModal, toggleAddLocationModal] = useState(false);
  const [addPeopleModal, toggleAddPeopleModal] = useState(false);
  const [addTeamModal, toggleAddTeamModal] = useState(false);
  const [dayFlag, setDayFlag] = useState(false);
  const [addProcedureModal, toggleAddProcedureModal] = useState(false);
  const [addAssetModal, toggleAddAssetModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const [selectedTablePartData, setSelectedTablePartData] = useState([]);
  const [selectedFinalPartData, setSelectedFinalPartData] = useState([]);
  const [allPartsData, setAllPartsData] = useState([]);
  const [prevPartsData, setPrevPartsData] = useState([]);
  const [initPartsData, setInitPartsData] = useState([]);
  const [selectedInvLoc, setSelectedInvLoc] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [addPartModal, setAddPartModal] = useState(false);
  const [customerText, setCustomerText] = useState("");
  const [locationText, setLocationText] = useState("");
  const [peopleText, setPeopleText] = useState("");
  const [teamText, setTeamText] = useState("");
  const [procedureText, setProcedureText] = useState("");
  const [assetText, setAssetText] = useState("");

  const [options, setOptions] = useState({
    customers: [],
    inv_location: [],
    locations: [],
    area: [],
    partsData: [],
    sets: [],
    priority: [
      {
        key: 0,
        value: "low",
        color: "#4FAB68",
        text: t.workOrders.form.priority_options[1],
        content: (
          <div
            style={
              selectedLanguage == "en"
                ? { fontSize: "12px" }
                : { fontSize: "11px" }
            }
            className={`priority-label low d-inline mt-0`}
          >
            <FlagWhiteIcon />
            <span className="ml-2 mr-2">
              {t.workOrders.form.priority_options[1]}
            </span>
          </div>
        ),
      },

      {
        key: 1,
        value: "medium",
        color: "#E2B203",
        text: t.workOrders.form.priority_options[2],
        content: (
          <div
            style={
              selectedLanguage == "en"
                ? { fontSize: "12px" }
                : { fontSize: "11px" }
            }
            className={`priority-label medium d-inline mt-0`}
          >
            <FlagWhiteIcon />
            <span className="ml-2 mr-2">
              {t.workOrders.form.priority_options[2]}
            </span>
          </div>
        ),
      },
      {
        key: 2,
        value: "high",
        color: "#D14040",
        text: t.workOrders.form.priority_options[3],
        content: (
          <div
            style={
              selectedLanguage == "en"
                ? { fontSize: "12px" }
                : { fontSize: "11px" }
            }
            className={`priority-label high d-inline mt-0`}
          >
            <FlagWhiteIcon />
            <span className="ml-2 mr-2">
              {t.workOrders.form.priority_options[3]}
            </span>
          </div>
        ),
      },
    ],

    repeatEvery: [
      {
        key: 0,
        value: "daily",
        text: t.workOrders.details_view.daily,
      },

      {
        key: 1,
        value: "weekly",
        text: t.workOrders.details_view.weekly,
      },
      {
        key: 2,
        value: "monthly",
        text: t.workOrders.details_view.monthly,
      },
      {
        key: 1,
        value: "yearly",
        text: t.workOrders.details_view.yearly,
      },
    ],
    people: [],
    primary_assignee: [],
    teams: [],
    assets: [],
    parts: [],
    procedures: [],
    weeks: [],
    months: [],
    monthsDays: [],
    years: [],
    orderTypes: [
      {
        key: 0,
        value: "reactive",
        text: t.workOrders.form.order_type_1,
      },
      {
        key: 1,
        value: "scheduled",
        text: t.workOrders.form.order_type_2,
      },
    ],
    categories: [
      {
        key: 0,
        value: "damage",
        text: (
          <span>
            <span className="ml-2 mr-2">
              <Damage />
            </span>
            {t.workOrders.form.category_options[0]}
          </span>
        ),
      },
      {
        key: 1,
        value: "corrective action Downtime",
        text: (
          <span>
            <span className="ml-2 mr-2">
              <CorrectiveIcon />
            </span>
            {t.workOrders.form.category_options[1]}
          </span>
        ),
      },
      {
        key: 2,
        value: "planned Downtime",
        text: (
          <span>
            <span className="ml-2 mr-2">
              <PlannedIcon />
            </span>
            {t.workOrders.form.category_options[2]}
          </span>
        ),
      },
      {
        key: 3,
        value: "unplanned Downtime",
        text: (
          <span>
            <span className="ml-2 mr-2">
              <UnPlannedIcon />
            </span>
            {t.workOrders.form.category_options[3]}
          </span>
        ),
      },
      {
        key: 4,
        value: "inspection",
        text: (
          <span>
            <span className="ml-2 mr-2">
              <CategoryInspection />
            </span>
            {t.workOrders.form.category_options[4]}
          </span>
        ),
      },
      {
        key: 5,
        value: "electrical",
        text: (
          <span>
            <span className="ml-2 mr-2">
              <Electrical />
            </span>
            {t.workOrders.form.category_options[5]}
          </span>
        ),
      },

      {
        key: 6,
        value: "mechanical",
        text: (
          <span>
            <span className="ml-2 mr-2">
              <CategoryMechanical />
            </span>
            {t.workOrders.form.category_options[6]}
          </span>
        ),
      },

      {
        key: 7,
        value: "HVAC",
        text: (
          <span>
            <span className="ml-2 mr-2">
              <Refrigeration />
            </span>
            {t.workOrders.work_order_category.HVAC}
          </span>
        ),
      },
      {
        key: 8,
        value: "preventive maintenance",
        text: (
          <span>
            <span className="ml-2 mr-2">
              <CatgoryPreventive />
            </span>
            {t.workOrders.form.category_options[8]}
          </span>
        ),
      },
      {
        key: 9,
        value: "project",
        text: (
          <span>
            <span className="ml-2 mr-2">
              <Project />
            </span>
            {t.workOrders.form.category_options[9]}
          </span>
        ),
      },
      {
        key: 10,
        value: "safety",
        text: (
          <span>
            <span className="ml-2 mr-2">
              <Safety />
            </span>
            {t.workOrders.form.category_options[10]}
          </span>
        ),
      },

      {
        key: 11,
        value: "sop",
        text: (
          <span>
            <span className="ml-2 mr-2">
              <OperationProcedure />
            </span>
            {t.workOrders.form.category_options[11]}
          </span>
        ),
      },
    ],
  });
  const getPaginatedData = (dataToPaginate) => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return dataToPaginate.slice(startIndex, endIndex);
  };

  const handleDecimalPoints = (value) => {
    const decimalPlaces = currencyDecimalPlaces[organisationCurrency] ?? 2; // Default to 2 if currency not found
    const factor = Math.pow(10, decimalPlaces);
    const roundedValue = Math.round(value * factor) / factor;
    const formattedValue = roundedValue.toFixed(decimalPlaces);

    // Remove trailing zeros after decimal point
    if (formattedValue.indexOf(".") !== -1) {
      return formattedValue.replace(/\.?0+$/, "");
    }

    return Number(formattedValue);
  };

  const addParts = async (selectedPartsData = [], fetchdata = false) => {
    setAreAllSelected(false);
    setInitChange(true);
    setAddPartModal(true);
    if (partsData?.length <= 0 || fetchdata) {
      setLoading(true);
      const fetchData = await getAllWOPartsData(organisationId);

      if (fetchData) {
        if (fetchData?.length == 0) {
          setLoading(false);
        } else {
          let allPartData = fetchData?.map((part) => {
            return {
              id: `${part.countId}-${part?.inventoryLocation?.id}`,
              part: part?.id,
              type: part.type,
              countId: part?.countId,
              name: part?.name,
              totalCost: part?.inventoryLocation?.unitCost
                ? handleDecimalPoints(part?.inventoryLocation?.unitCost)
                : 0,
              quantity: 1,
              unitCost: part?.inventoryLocation?.unitCost
                ? handleDecimalPoints(part?.inventoryLocation?.unitCost)
                : 0,
              unitType: part?.measuringUnit,
              selectedPart: false,
              location: part?.inventoryLocation?.id,
              location_name: part?.inventoryLocation?.name,
              asset: part?.relatedAsset,
              status: part?.inventoryLocation?.status,
              disable:
                part?.inventoryLocation?.status == "outOfStock" ? true : false,
              availableQty: part?.inventoryLocation?.availableQuantity
                ? part?.inventoryLocation?.availableQuantity
                : 0,
            };
          });
          const filteredParts = fetchData
            ?.map((part) => {
              const isSelected = selectedPartsData.some(
                (selectedPart) => selectedPart.id === part.id
              );

              if (isSelected) {
                return null; // Skip this part
              } else {
                return {
                  id: `${part.countId}-${part?.inventoryLocation?.id}`,
                  type: part.type,
                  part: part?.id,
                  countId: part?.countId,
                  name: part?.name,

                  totalCost: part?.inventoryLocation?.unitCost
                    ? handleDecimalPoints(part?.inventoryLocation?.unitCost)
                    : 0,
                  quantity: 1,
                  unitCost: part?.inventoryLocation?.unitCost
                    ? handleDecimalPoints(part?.inventoryLocation?.unitCost)
                    : 0,

                  unitType: part?.measuringUnit,
                  selectedPart: false,
                  location: part?.inventoryLocation?.id,
                  location_name: part?.inventoryLocation?.name,
                  asset: part?.relatedAsset,
                  status: part?.inventoryLocation?.status,
                  disable:
                    part?.inventoryLocation?.status == "outOfStock"
                      ? true
                      : false,
                  availableQty: part?.inventoryLocation?.availableQuantity
                    ? part?.inventoryLocation?.availableQuantity
                    : 0,
                };
              }
            })
            .filter((part) => part !== null);

          setPartsData(filteredParts);
          setInitPartsData(filteredParts);
          setAllPartsData(allPartData);
          setLoading(false);
          if (!fetchdata) {
            setAddPartModal(true);
          }
        }
      } else {
        toast.error(t.common.something_wrong);
        setLoading(false);
        if (!fetchdata) {
          setAddPartModal(true);
        }
      }
      setLoading(false);
    } else {
      setLoading(true);

      const filteredParts = allPartsData
        ?.map((part) => {
          const isSelected = selectedPartsData.some(
            (selectedPart) => selectedPart.id === part.id
          );
          if (isSelected) {
            return null;
          } else {
            return {
              id: part?.id,
              type: part?.type,
              countId: part?.countId,
              part: part?.part,
              name: part?.name,
              totalCost: part.totalCost,
              quantity: part?.quantity ? part?.quantity : null,
              unitCost: part?.unitCost,
              unitType: part?.unitType,
              selectedPart: false,
              location: part?.location,
              location_name: part?.location_name,
              asset: part?.asset,
              status: part?.status,
              disable: part?.disable,
              availableQty: part?.availableQty ? part.availableQty : 0,
            };
          }
        })
        .filter((part) => part !== null);
      setPartsData(filteredParts);
      setInitPartsData(filteredParts);
      setLoading(false);
    }
  };

  const removeFromSelectedParts = (index, selectedPartsData = []) => {
    const updatedSelectedPartsData = [...selectedPartsData];
    updatedSelectedPartsData[index].selectedPart = false;
    updatedSelectedPartsData.splice(index, 1);

    setSelectedFinalPartData(updatedSelectedPartsData);
    handleRowSelected(null, false, updatedSelectedPartsData);
  };

  const { initData } = useSelector((state) => ({
    initData: state.asset.assetInitData,
  }));

  const [data, setData] = useState({
    title: WRtitle ? WRtitle : "",
    description: WRdescription ? WRdescription : "",
    customer: "",
    primaryAssignee: "",
    location: WRlocation ? WRlocation : "",
    area: "",
    partsData: [],
    sets: [],
    orderType: WRordertype ? WRordertype : "reactive",
    startDate: format(new Date(), "yyyy-MM-dd"),
    startTime: getLocalTime(organisationTimezone.split("+")[1]),
    dueDate: dueDateParam
      ? format(new Date(dueDateParam), "yyyy-MM-dd")
      : format(new Date(), "yyyy-MM-dd"),
    dueTime: "",
    repeatEvery: "daily",
    daysRepeat: {
      sat: false,
      sun: false,
      mon: false,
      tue: false,
      wed: false,
      thu: false,
      fri: false,
    },
    repeatEveryWeek: 1,
    repeatEveryMonth: 1,
    repeatMonthDay: "d1",
    repeatEveryYear: 1,
    endRepeat: 1,
    estimationTime: 0,
    laborCost: 0,
    priority: WRpriority ? WRpriority : "low",

    category: "",
    people: [],
    teams: [],
    assets: WRasset ? WRasset : [],
    procedure: "",
    endDate: format(new Date(), "yyyy-MM-dd"),
    endType: "on",
    qrcode: "",
  });

  let allQtyOptions = [
    {
      key: 0,
      value: "pieces",
      text: t.parts.qty_unit.piece,
    },
    {
      key: 1,
      value: "items",
      text: t.parts.qty_unit.items,
    },
    {
      key: 2,
      value: "units",
      text: t.parts.qty_unit.unit,
    },
    {
      key: 3,
      value: "liters (L)",
      text: selectedLanguage == "en" ? "l" : "لتر",
    },
    {
      key: 4,
      value: "gallons (gal)",
      text: selectedLanguage == "en" ? "gal" : "جالون",
    },
    {
      key: 5,
      value: "cubic meters (m³)",
      text: selectedLanguage == "en" ? "m³" : "م³",
    },
    {
      key: 6,
      value: "cubic feet (ft³)",
      text: selectedLanguage == "en" ? "ft³" : "ق³",
    },
    {
      key: 7,
      value: "barrels",
      text: t.parts.vol_unit.barrels,
    },
    {
      key: 8,
      value: "kilograms (kg)",
      text: selectedLanguage == "en" ? "kg" : "كجم",
    },
    {
      key: 9,
      value: "pounds (lb)",
      text: selectedLanguage == "en" ? "lb" : "رطل",
    },

    {
      key: 10,
      value: "tons",
      text: t.parts.mass_unit.tons,
    },
    {
      key: 11,
      value: "metric tonnes",
      text: t.parts.mass_unit.m_tons,
    },
  ];

  let _newData = initPartsData;

  useEffect(() => {
    // Clone the data to apply filters without mutating the original data
    let filteredData = [..._newData];
    setCurrentPage(1);
    // Apply partSearchTitle filter
    if (partSearchTitle.length > 0) {
      const re = new RegExp(_.escapeRegExp(partSearchTitle), "i");
      const isMatch = (result) => re.test(result?.name);
      filteredData = _.filter(filteredData, isMatch);
    }

    // Apply selectedInvLoc filter
    if (selectedInvLoc) {
      filteredData = filteredData.filter(
        (part) => part?.location === selectedInvLoc
      );
    }

    // Check if all parts are selected and set state
    if (filteredData.length > 0) {
      const allSelectedDisable = filteredData.every(
        (item) => item.disable === true
      );

      if (!allSelectedDisable) {
        const allSelected = filteredData
          .filter((item) => item.disable === false) // Only include non-disabled items
          .every((item) => item.selectedPart === true);
        setPartsData(filteredData);

        setAreAllSelected(allSelected);
      } else {
        setPartsData(filteredData);
        setAreAllSelected(false);
      }
    } else {
      setPartsData([]);
    }
  }, [partSearchTitle, selectedInvLoc]);

  const HandleUnitType = (data) => {
    let unitType;
    if (data) {
      unitType = allQtyOptions.find((el) => el.value == data);
    }
    return data ? unitType?.text : null;
  };

  const areAnyDaysSelected = Object?.values(data?.daysRepeat).some(
    (day) => day
  );

  const [validation, setValidation] = useState({
    title: {
      error: true,
      pattern: (title) => title != "",
      skipped: false,
      message: t.common.required_field,
    },

    category: {
      error: true,
      pattern: (category) => category != "",
      skipped: false,
      message: t.common.required_field,
    },

    location: {
      error: true,
      pattern: (location) => location != "",
      skipped: false,
      message: t.common.required_field,
    },
    endRepeat: {
      error:
        data?.orderType === "scheduled" &&
        data?.endType === "after" &&
        data?.endRepeat == "",
      pattern: (endRepeat) => endRepeat !== "",
      skipped: false,
      message: t.common.required_field,
    },
    endDate: {
      error:
        data?.orderType === "scheduled" &&
        data?.endType === "on" &&
        data?.endDate == null,
      pattern: (endRepeat) => endRepeat !== "",
      skipped: false,
      message: t.common.required_field,
    },
    orderType: {
      error: true,
      pattern: (orderType) => orderType != "",
      skipped: false,
      message: t.common.required_field,
    },
    daysRepeat: {
      skipped: false,
      message: t.common.required_field,
      error:
        data?.orderType === "scheduled" &&
        ["daily", "weekly"].includes(data?.repeatEvery)
          ? !areAnyDaysSelected
          : false,
    },

    procedure: {
      error: false,
      pattern: (procedure) => true,
      skipped: false,
      message: t.common.required_field,
    },
  });

  const [showCancleTooltip, setCancleTooltip] = useState(false);

  const resetForm = () => {
    setData({
      title: "",
      description: "",
      customer: "",
      location: "",
      orderType: "reactive",
      area: "",
      partsData: [],
      sets: [],
      primaryAssignee: "",
      startDate: format(new Date(), "yyyy-MM-dd"),
      startTime: getLocalTime(organisationTimezone.split("+")[1]),
      dueDate: dueDateParam
        ? format(new Date(dueDateParam), "yyyy-MM-dd")
        : format(new Date(), "yyyy-MM-dd"),
      dueTime: "",
      repeatEvery: "daily",
      daysRepeat: {
        sat: false,
        sun: false,
        mon: false,
        tue: false,
        wed: false,
        thu: false,
        fri: false,
      },
      repeatEveryWeek: 1,
      repeatEveryMonth: 1,
      repeatMonthDay: "d1",
      repeatEveryYear: 1,
      endRepeat: 1,
      estimationTime: 0,
      laborCost: 0,
      priority: "low",

      category: "",
      people: [],
      teams: [],
      assets: [],
      procedure: "",
      endDate: format(new Date(), "yyyy-MM-dd"),
      endType: "on",
      qrcode: "",
    });
    setPartSearchTitle("");
    setSelectedTablePartData([]);
    setSelectedFinalPartData([]);
    setAllPartsData([]);
    setPrevPartsData([]);
    setAttachments([]);
    setInitPartsData([]);
    setSelectedInvLoc("");
    setPartsData([]);
    setCurrentPage(1);

    setValidation({
      title: {
        error: false,
        pattern: (title) => title != "",
        skipped: false,
        message: t.common.required_field,
      },
      category: {
        error: false,
        pattern: (category) => category != "",
        skipped: false,
        message: t.common.required_field,
      },

      daysRepeat: {
        skipped: false,
        message: t.common.required_field,
        error:
          data?.orderType === "scheduled" &&
          ["daily", "weekly"].includes(data?.repeatEvery)
            ? !areAnyDaysSelected
            : false,
      },
      orderType: {
        error: true,
        pattern: (orderType) => orderType != "",
        skipped: false,
        message: t.common.required_field,
      },
      location: {
        error: false,
        pattern: (location) => location != "",
        skipped: false,
        message: t.common.required_field,
      },

      procedure: {
        error: false,
        pattern: (procedure) => true,
        skipped: false,
        message: t.common.required_field,
      },

      endDate: {
        error:
          data?.orderType === "scheduled" &&
          data?.endType === "on" &&
          data?.endDate == null,
        pattern: (endRepeat) => endRepeat !== "",
        skipped: false,
        message: t.common.required_field,
      },

      endRepeat: {
        error:
          data?.orderType === "scheduled" &&
          data?.endType === "after" &&
          data?.endRepeat === "",
        pattern: (endRepeat) => endRepeat !== "",
        skipped: false,
        message: t.common.required_field,
      },
    });

    setAttachments({
      image: null,
      warrantyFile: [],
    });

    setOptions({
      ...options,
      area: [],
      partsData: [],
      sets: [],
      inv_location: [],
    });
    if (fromWR) {
      setModalWidth(Math.max(window.innerWidth / 2, 514));
      setmodalPrevWidth(Math.max(window.innerWidth / 2, 514));
    } else {
      setModalWidth(Math.max(window.innerWidth / 2, 514));
    }

    setInitChange(false);
  };

  const fetchArea = async (id) => {
    const response = await getAllAreaByLocationId(organisationId, id);

    let the_options_area = [];
    if (response.length > 0) {
      response.forEach((el) => {
        the_options_area.push({
          key: `${el.id}`,
          value: el.id,
          text: `${el.attributes.name}`,
        });
      });
    }

    setOptions((prev) => {
      return {
        ...prev,
        partsData: [],
        sets: [],
        area: the_options_area,
      };
    });
  };

  const handleClosePartModal = () => {
    let newAddedData = [...prevPartsData];

    let parts = [...selectedTablePartData];

    parts = parts?.filter(
      (part) => !newAddedData.some((addedPart) => addedPart.id == part.id)
    );

    setAddPartModal(false);
    setPartSearchTitle("");
    setSelectedInvLoc("");
    setCurrentPage(1);
    setSelectedTablePartData(parts);
    setSelectedFinalPartData(parts);
  };

  //New Inventory data structure
  const getInvLocations = async () => {
    const fetchData = await fetchInvLocationsOptions(organisationId);

    let parentOptions = [];

    if (fetchData?.length > 0) {
      fetchData.forEach((el) => {
        parentOptions.push({
          key: el.id,
          value: el.id,
          text: el.name,
          disabled: el?.disable,
          image: { src: location_icon },
          className: "main-location-dropdown-option",
        });

        if (el?.subLocations?.length > 0) {
          el.subLocations.forEach((subEl) => {
            parentOptions.push({
              key: subEl.id,
              value: subEl.id,
              text: subEl.name,
              image: { src: sub_location_icon },
              className: "sub-location-dropdown-option",
              parentId: el.id,
            });
          });
        }
      });

      setOptions((prev) => {
        return {
          ...prev,
          inv_location: parentOptions,
        };
      });

      return parentOptions;
    }
  };

  const filterInventoryData = (response, locationId, areaId) => {
    let filteredInventory = [];

    response.forEach((item) => {
      let matchCount = 0;
      let setMatchCount = 0;

      // Iterate through each part's inventory
      item?.attributes?.parts?.length > 0 &&
        item.attributes.parts.forEach((part) => {
          // Check each inventory item for the selected location match
          part?.part?.data?.attributes?.inventory &&
            part.part.data.attributes.inventory.forEach((inv) => {
              if (
                inv.location.data.id === locationId &&
                inv.area.data.id === areaId &&
                inv.quantity !== 0
              ) {
                matchCount++;
              }
            });
        });

      // If both parts' inventories match the selected location, push to filteredInventory
      if (item?.attributes?.parts?.length !== 0) {
        if (
          matchCount >= 2 &&
          !filteredInventory.some((inv) => inv.key == item.id)
        ) {
          filteredInventory.push({
            key: `${item.id}`,
            value: `${item.id}`,
            text: item?.attributes?.name,
            description: "",
            disabled: false,
          });
        } else if (!filteredInventory.some((inv) => inv.key == item.id)) {
          filteredInventory.push({
            key: `${item.id}`,
            value: `${item.id}`,
            text: item?.attributes?.name,
            description: t?.parts?.status.out_of_stock,
            disabled: true,
          });
        }
      }

      item?.attributes?.sets?.length > 0 &&
        item.attributes.sets.forEach((set) => {
          set?.set?.data?.attributes?.parts &&
            set.set.data.attributes.parts.forEach((part_single) => {
              // Check each inventory item for the selected location match
              part_single?.part?.data?.attributes?.inventory &&
                part_single.part.data.attributes.inventory.forEach((inv) => {
                  if (
                    inv.location.data.id === locationId &&
                    inv.area.data.id === areaId &&
                    inv.quantity !== 0
                  ) {
                    setMatchCount++;
                  }
                });
            });
          if (setMatchCount >= 2) {
            if (
              item?.attributes?.parts?.length == 0 &&
              !filteredInventory.some((inv) => inv.key == item.id)
            ) {
              filteredInventory.push({
                key: `${item.id}`,
                value: `${item.id}`,
                text: item?.attributes?.name,
                description: "",
                disabled: false,
              });
            }

            if (
              !filteredInventory.some((inv) => inv.key == set?.set?.data?.id)
            ) {
              filteredInventory.push({
                key: `${set?.set?.data?.id}`,
                value: `${set?.set?.data?.id}`,
                text: set?.set?.data?.attributes?.name,
                description: "",
                disabled: false,
              });
            }
          } else {
            if (
              item?.attributes?.parts?.length === 0 &&
              !filteredInventory.some((inv) => inv.key == item.id)
            ) {
              filteredInventory.push({
                key: `${item.id}`,
                value: `${item.id}`,
                text: item?.attributes?.name,
                description: t.parts.status.out_of_stock,
                disabled: true,
              });
            }

            if (
              !filteredInventory.some((inv) => inv.key == set?.set?.data?.id)
            ) {
              filteredInventory.push({
                key: `${set?.set?.data?.id}`,
                value: `${set?.set?.data?.id}`,
                text: set?.set?.data?.attributes?.name,
                description: t.parts.status.out_of_stock,
                disabled: true,
              });
            }
          }
        });
    });

    return filteredInventory;
  };
  const fetchParts = async (id, areaId) => {
    let the_options_parts = [];
    const response = await getAllParts(
      organisationId,
      "",
      1,
      99999,
      [],
      [],
      [],
      [],
      [id],
      [areaId]
    );

    if (response?.response?.length > 0) {
      response.response.forEach((el) => {
        // Find the inventory entry that matches the id and areaId
        const matchedInventory = el.attributes.inventory.find(
          (inv) => inv.location.data.id == id && inv.area.data.id == areaId
        );

        // Use the matched inventory's quantity or default to 0 if no match is found
        const quantity = matchedInventory ? matchedInventory.quantity : 0;

        the_options_parts.push({
          key: `${el.id}`,
          value: `${el.id}`,
          text: `${el.attributes.name}`,
          description: `${
            quantity !== 0
              ? `${t.parts.table.qty_in_stock}: ${quantity} ${HandleUnitType(
                  el?.attributes?.measuringUnit
                )}`
              : t.parts.status.out_of_stock
          }`,
          disabled: quantity == 0 ? true : false,
        });
      });
    }

    const setResponse = await getAllSets(
      organisationId,
      "",
      1,
      99999,
      [],
      id,
      areaId
    );

    const inventoryData = filterInventoryData(
      setResponse?.response,
      id,
      areaId
    );
    setOptions((prev) => {
      return {
        ...prev,

        partsData: the_options_parts,
        sets: inventoryData,
      };
    });
  };

  const fetchSets = async () => {
    const setResponse = await getAllSets(organisationId);

    const inventoryData = filterInventoryData(setResponse?.response);
    setOptions((prev) => {
      return {
        ...prev,
        sets: inventoryData,
      };
    });
  };

  useEffect(() => {
    const areAnyDaysSelected = Object?.values(data?.daysRepeat).some(
      (day) => day
    );

    if (
      !areAnyDaysSelected &&
      data?.orderType === "scheduled" &&
      ["daily", "weekly"].includes(data?.repeatEvery)
    ) {
      setDayFlag(true);
    } else {
      setDayFlag(false);
    }
  }, [data.daysRepeat]);

  const fetchMonthOptions = () => {
    let months = [];

    for (let i = 1; i < 25; i++) {
      const obj = {
        key: i,
        value: i,
        text: i,
      };

      months.push(obj);
    }

    setOptions((prev) => {
      return {
        ...prev,
        months: months,
      };
    });
  };

  /*
   * The function `fetchMonthDaysOptions` generates an array of objects representing options for selecting days in a month, with special handling for the suffixes 'st', 'nd', 'rd', and 'th'.
   */
  const fetchMonthDaysOptions = () => {
    let monthsDays = [];

    for (let i = 1; i < 32; i++) {
      let day_th = "th";
      if (i == 1) day_th = "st";
      if (i == 2) day_th = "nd";
      if (i == 3) day_th = "rd";

      const obj = {
        key: i,
        value: "d" + i,
        text: i > 28 ? i + day_th + " (or last)" : i + day_th,
      };

      monthsDays.push(obj);
    }

    setOptions((prev) => {
      return {
        ...prev,
        monthsDays: monthsDays,
      };
    });
  };

  /*
   * The function `fetchYearsOptions` generates an array of objects representing years
   */
  const fetchYearsOptions = () => {
    let years = [];

    for (let i = 1; i < 11; i++) {
      const obj = {
        key: i,
        value: i,
        text: i,
      };

      years.push(obj);
    }
    setOptions((prev) => {
      return {
        ...prev,
        years: years,
      };
    });
  };

  const fetchWeekOptions = () => {
    let weeks = [];

    for (let i = 1; i < 21; i++) {
      const obj = {
        key: i,
        value: i,
        text: i,
      };

      weeks.push(obj);
    }

    setOptions((prev) => {
      return {
        ...prev,
        weeks: weeks,
      };
    });
  };

  const fetchLocationsOptions = async (customerID) => {
    const fetchData = await getAllParentLocations(
      organisationId,
      "",
      1,
      99999,
      [],
      [],
      [],
      ["name:asc"]
    );
    let the_options = [];
    let parentOptions = [];
    let subOptions = [];
    if (fetchData?.response?.length > 0) {
      fetchData.response.forEach((el) => {
        if (!(roleData === "super_admin")) {
          // Check if the element's ID is included in allIds
          if (allIds?.includes(el.id)) {
            // Add parent option normally
            parentOptions.push({
              key: el.id,
              value: el.id,
              text: el.attributes.name,
              image: { src: location_icon },
              className: "main-location-dropdown-option",
              disabled: false, // Parent option enabled
            });

            // Collect sub-options
            if (el?.attributes?.subLocations?.data?.length > 0) {
              el.attributes.subLocations.data.forEach((subEl) => {
                if (allIds?.includes(subEl.id)) {
                  subOptions.push({
                    key: subEl.id,
                    value: subEl.id,
                    text: subEl.attributes.name,
                    image: { src: sub_location_icon },
                    className: "sub-location-dropdown-option",
                    parentId: el.id, // Keep track of the parent
                  });
                }
              });
            }
          } else {
            // If the element's ID is not in allIds, check sub-locations
            const hasMatchingSubLocation = el?.attributes?.subLocations?.data?.some(
              (subEl) => allIds?.includes(subEl.id)
            );

            if (hasMatchingSubLocation) {
              // Add parent option as disabled
              parentOptions.push({
                key: el.id,
                value: el.id,
                text: el.attributes.name,
                image: { src: location_icon },
                className: "main-location-dropdown-option",
                disabled: true, // Disable the parent option
              });

              // Collect matching sub-options
              el?.attributes?.subLocations?.data?.length > 0 &&
                el.attributes.subLocations.data.forEach((subEl) => {
                  if (allIds?.includes(subEl.id)) {
                    subOptions.push({
                      key: subEl.id,
                      value: subEl.id,
                      text: subEl.attributes.name,
                      image: { src: sub_location_icon },
                      className: "sub-location-dropdown-option",
                      parentId: el.id, // Keep track of the parent
                    });
                  }
                });
            }
          }
        } else {
          // Add parent option
          parentOptions.push({
            key: el.id,
            value: el.id,
            text: el.attributes.name,
            className: "main-location-dropdown-option",
            image: { src: location_icon },
          });

          // Collect sub-options
          if (el?.attributes?.subLocations?.data?.length > 0) {
            el.attributes.subLocations.data.forEach((subEl) => {
              subOptions.push({
                key: subEl.id,
                value: subEl.id,
                text: subEl.attributes.name,
                image: { src: sub_location_icon },
                className: "sub-location-dropdown-option",
                parentId: el.id, // Keep track of the parent
              });
            });
          }
        }
      });

      // Sort sub-options alphabetically
      subOptions.sort((a, b) => a.text.localeCompare(b.text));

      // Merge parent options with sorted sub-options
      parentOptions.forEach((parentOption) => {
        the_options.push(parentOption);
        // Add sorted sub-options belonging to this parent
        subOptions.forEach((subOption) => {
          if (subOption.parentId === parentOption.key) {
            the_options.push(subOption);
          }
        });
      });

      // Update the options state
      setOptions((prev) => ({
        ...prev,
        locations: the_options,
      }));

      return the_options;
    }
  };

  useEffect(() => {
    if (toggled) {
      fetchLocationsOptions();
      fetchWeekOptions();
      fetchMonthOptions();
      fetchMonthDaysOptions();
      fetchYearsOptions();
      getInvLocations();
    }
  }, [toggled]);

  useEffect(() => {
    if (dueDateParam)
      setData({
        ...data,
        dueDate: dueDateParam
          ? format(new Date(dueDateParam), "yyyy-MM-dd")
          : format(new Date(), "yyyy-MM-dd"),
      });
  }, [dueDateParam]);

  useEffect(() => {
    if (data?.location) {
      setData({
        ...data,
        area: "",
        partsData: [],
        sets: [],
      });

      fetchArea(data?.location);
    }
  }, [data?.location]);

  useEffect(() => {
    if (data?.location && data?.area) {
      setData({
        ...data,
        partsData: [],
        sets: [],
      });

      setOptions((prev) => {
        return {
          ...prev,
          partsData: [],
          sets: [],
        };
      });
      fetchParts(data?.location, data?.area);
    }
  }, [data?.area]);

  /*
   * The `onValuesChange` function in JavaScript handles changes in form field values, updating state based on the type of field (boolean or dropdown) and performing validation.
   */
  const onValuesChange = (name, actualValue) => (event, value) => {
    setInitChange(true);
    let currentFieldValue = "";
    const boolean_fields = ["includeDueTime"];
    const dropdown_fields = [
      "customer",
      "location",
      "primaryAssignee",
      "area",
      "orderType",
      "category",
      "code",
      "people",
      "teams",
      "assets",
      "parts",
      "procedure",
      "repeatEveryWeek",
      "repeatEveryMonth",
      "repeatMonthDay",
      "repeatEveryYear",
      "endType",
    ];

    if (!boolean_fields.includes(name) || !dropdown_fields.includes(name)) {
      currentFieldValue = event.target.value;
      setData({
        ...data,
        [name]: event.target.value,
      });
    }

    if (boolean_fields.includes(name)) {
      currentFieldValue = value.checked;

      setData({
        ...data,
        [name]: value.checked,
      });
    }

    if (dropdown_fields.includes(name)) {
      currentFieldValue = actualValue || value.value;
      let resulteNewData;
      if (name == "location") {
        resulteNewData = {
          ...data,
          [name]: actualValue || value.value,
          customer: "",
          people: [],
          teams: [],
          assets: [],
          primaryAssignee: "",
        };

        setOptions({
          ...options,
          customers: [],
          people: [],
          primary_assignee: [],
          teams: [],
          assets: [],
        });
      } else if (name == "orderType") {
        resulteNewData = {
          ...data,
          [name]: actualValue || value.value,
          startDate: format(new Date(), "yyyy-MM-dd"),
          startTime: getLocalTime(organisationTimezone.split("+")[1]),
          dueDate: dueDateParam
            ? format(new Date(dueDateParam), "yyyy-MM-dd")
            : format(new Date(), "yyyy-MM-dd"),
          dueTime: "",
          endDate: format(new Date(), "yyyy-MM-dd"),
        };
      } else if (name === "primaryAssignee") {
        let selectedPerson = options?.people?.find(
          (person) => person.value === value.value
        );

        let updatedPeople = data?.people?.filter(
          (person) => person !== value.value
        );

        if (selectedPerson) {
          if (value.value) {
            resulteNewData = {
              ...data,
              people: updatedPeople,
              [name]: actualValue || value.value,
            };
          } else {
            resulteNewData = {
              ...data,
              people: [],
              [name]: actualValue || value.value,
            };
          }
        } else {
          // Set primary assignee without filtering if person doesn't exist
          resulteNewData = {
            ...data,
            [name]: actualValue || value.value,
            people: [],
          };
        }
      } else {
        resulteNewData = {
          ...data,
          [name]: actualValue || value.value,
        };
      }

      setData(resulteNewData);
    }
    let fieldValidation = validation[name];
    if (fieldValidation) {
      let currentValidation = {
        ...validation,
        [name]: {
          ...fieldValidation,
          error: fieldValidation.pattern(currentFieldValue) ? false : true,
        },
      };

      setValidation(currentValidation);
    }
  };

  /*
   * The fetchCustomerOptions function retrieves customer data, processes it, and returns an array of options with key, value, text, image, and locationId properties.
   */
  const fetchCustomerOptions = async () => {
    const fetchData = await await getAllCustomers(
      organisationId,
      "",
      1,
      99999,
      !(roleData === "super_admin")
        ? data?.location
          ? [data?.location]
          : allIds
        : data?.location
        ? [data?.location]
        : []
    );

    if (fetchData.response) {
      let the_options = fetchData.response.map((el) => {
        const the_image = el.attributes.logo.data
          ? IMAGES_URL + el.attributes.logo.data.attributes.url
          : LetteredAvatarImageUrl(el.attributes.name);
        return {
          key: el.id,
          value: el.id,
          text: el.attributes.name,
          image: { avatar: true, src: the_image, size: "tiny" },
          locationId: el?.attributes?.location?.data?.id || "",
        };
      });
      setOptions({
        ...options,
        customers: the_options,
      });

      return the_options;
    }
  };

  const svgToDataUrl = (svgComponent) => {
    const svgString = ReactDOMServer.renderToStaticMarkup(svgComponent);
    return `data:image/svg+xml;base64,${btoa(svgString)}`;
  };

  const fetchAssetsOptions = async (customerID, locations) => {
    const fetchData = await getAllAssetsOption(
      organisationId,
      "all_asset",
      1,
      9999,
      data?.location
        ? [data?.location]
        : !(roleData === "super_admin")
        ? data?.location
          ? [data?.location]
          : []
        : [],
      [],
      "",
      "",
      [],
      ["name:asc"]
    );

    let assetOptions = [];
    let parentToChildMap = new Map();
    let childToGrandChildMap = new Map();
    let hierarchicalOptions = [];

    if (fetchData?.response?.length > 0) {
      fetchData.response.forEach((el) => {
        const isDisposed = el?.attributes?.status === "dispose";

        // Access rule: Non-admin users can't access certain assets
        const isActive =
          !isDisposed &&
          (!(roleData === "super_admin")
            ? data?.location
              ? data?.location == el?.attributes?.location?.data?.id
              : allIds?.includes(el?.attributes?.location?.data?.id)
            : true);

        // Filter out disposed or inactive assets with no descendants
        if (
          isDisposed ||
          (!isActive && !el?.attributes?.storedAssets?.length)
        ) {
          return;
        }

        // Create parent asset option
        const parentAsset = {
          key: el.id,
          value: el.id,
          text: el.attributes.name,
          image: { src: parent_icon }, // Parent asset icon
          className: "main-location-dropdown-option",
          disabled: !isActive, // Disable only for non-admin roles
        };

        let hasAccessibleChildren = false;
        let hasAccessibleGrandchildren = false;

        parentToChildMap.set(el.id, []);
        assetOptions.push(parentAsset);

        // Collect child assets
        if (el?.attributes?.storedAssets?.length > 0) {
          el.attributes.storedAssets.forEach((childEl) => {
            if (childEl?.childAsset?.data) {
              const childAsset = childEl.childAsset.data;
              const childIsDisposed =
                childAsset?.attributes?.status === "dispose";

              const childIsActive =
                !childIsDisposed &&
                (!(roleData === "super_admin")
                  ? data?.location
                    ? data?.location ==
                      childAsset?.attributes?.location?.data?.id
                    : allIds?.includes(
                        childAsset?.attributes?.location?.data?.id
                      )
                  : true);

              // Filter out disposed or inactive child assets with no grandchildren
              if (
                childIsDisposed ||
                (!childIsActive && !childEl?.grandChildAsset?.data?.length)
              ) {
                return;
              }

              // Track whether there are any accessible children
              if (childIsActive) hasAccessibleChildren = true;

              const childOption = {
                key: childAsset.id,
                value: childAsset.id,
                text: `${childAsset.attributes.name}`,
                image: { src: child_icon }, // Child asset icon
                className: "sub-location-dropdown-option",
                parentId: el.id,
                disabled: !childIsActive, // Disable child based on role
              };

              parentToChildMap.get(el.id).push(childOption);
              childToGrandChildMap.set(childAsset.id, []);

              // Collect grandchild assets
              if (childEl?.grandChildAsset?.data?.length > 0) {
                childEl.grandChildAsset.data.forEach((grandChildEl) => {
                  const grandChildIsDisposed =
                    grandChildEl?.attributes?.status === "dispose";

                  const grandChildIsActive =
                    !grandChildIsDisposed &&
                    (!(roleData === "super_admin")
                      ? data?.location
                        ? data?.location ==
                          grandChildEl?.attributes?.location?.data?.id
                        : allIds?.includes(
                            grandChildEl?.attributes?.location?.data?.id
                          )
                      : true);

                  // Filter out disposed or inactive grandchild assets
                  if (grandChildIsDisposed || !grandChildIsActive) {
                    return;
                  }

                  // Track whether there are any accessible grandchildren
                  if (grandChildIsActive) hasAccessibleGrandchildren = true;

                  const grandChildOption = {
                    key: grandChildEl.id,
                    value: grandChildEl.id,
                    text: `${grandChildEl.attributes.name}`,
                    image: { src: gchild_icon }, // Grandchild asset icon
                    className: "grandchild-asset-dropdown-option",
                    parentId: el.id,
                    childParentId: childAsset.id,
                    disabled: !grandChildIsActive, // Disable based on role
                  };

                  childToGrandChildMap
                    .get(childAsset.id)
                    .push(grandChildOption);
                });
              }
            }
          });
        }

        // Ensure the parent asset is displayed if it has no accessible children or grandchildren
        if (!hasAccessibleChildren && !hasAccessibleGrandchildren && isActive) {
          // Clear the child options and only show the parent
          parentToChildMap.set(el.id, []);
        }
      });

      // Sort child and grandchild options alphabetically within each parent and child context
      parentToChildMap.forEach((children) => {
        children.sort((a, b) => a.text.localeCompare(b.text));
      });

      childToGrandChildMap.forEach((grandChildren) => {
        grandChildren.sort((a, b) => a.text.localeCompare(b.text));
      });

      // Combine all the options into a hierarchical structure
      assetOptions.forEach((parentOption) => {
        hierarchicalOptions.push(parentOption);

        const children = parentToChildMap.get(parentOption.key) || [];
        children.forEach((childOption) => {
          hierarchicalOptions.push(childOption);

          const grandChildren = childToGrandChildMap.get(childOption.key) || [];
          grandChildren.forEach((grandChildOption) => {
            hierarchicalOptions.push(grandChildOption);
          });
        });
      });
      setOptions((prev) => {
        return {
          ...prev,
          assets: hierarchicalOptions,
        };
      });

      return hierarchicalOptions;
    }
  };

  /*
   * The fetchPartsOptions function retrieves parts data based on assets ID and organizes it into options for selection.
   */
  const fetchPartsOptions = async (assetsID) => {
    const fetchData = await getAllPartsByAssets(assetsID, organisationId);

    if (fetchData) {
      let the_options = fetchData.map((el) => {
        const quantity = el.attributes.quantity ? el.attributes.quantity : 0;
        return {
          key: el.id,
          value: el.id,
          text: el.attributes.name + "(" + quantity + ")",
          description: el.attributes.asset.data?.attributes.name,
        };
      });

      setOptions({
        ...options,
        parts: the_options,
      });

      return the_options;
    }
  };

  /*
   * The function fetches active users by role and organizes their data into options for a dropdown menu.
   */
  const fetchPeopleOptions = async () => {
    const fetchData = await getActiveUsersByRole(
      ["user", "limited_admin", "admin"],
      organisationId,
      !(roleData === "super_admin")
        ? data?.location
          ? [data?.location]
          : allIds
        : data?.location
        ? [data?.location]
        : []
    );

    if (fetchData?.data) {
      // Filter out the primaryAssignee
      const filteredPeople = fetchData.data.filter(
        (person) => person.id !== data?.primaryAssignee
      );

      // Map filtered list to dropdown options
      const the_options = filteredPeople.map((el) => {
        const the_image = el.profileImage?.url
          ? IMAGES_URL + el.profileImage?.url
          : LetteredAvatarImageUrl(el?.firstName + " " + el.lastName);
        const teams_string_array = el?.teams?.map((one) => one.name);

        return {
          key: el.id,
          value: el.id,
          text:
            el.firstName +
            " " +
            el.lastName +
            " (" +
            t.people.roles[el.role.name] +
            ")",
          description: `${teams_string_array.join(" , ")}`,
          image: { avatar: true, src: the_image, size: "tiny" },
        };
      });

      // Update state with filtered options
      setOptions((prevOptions) => ({
        ...prevOptions,
        people: the_options,
      }));

      return the_options;
    }
  };

  const fetchPrimaryAssigneeOptions = async () => {
    const fetchData = await getActiveUsersByRole(
      ["user", "limited_admin", "admin"],
      organisationId,
      !(roleData === "super_admin")
        ? data?.location
          ? [data?.location]
          : allIds
        : data?.location
        ? [data?.location]
        : []
    );
    if (fetchData?.data) {
      let the_options = fetchData.data.map((el) => {
        const the_image = el.profileImage?.url
          ? IMAGES_URL + el.profileImage?.url
          : LetteredAvatarImageUrl(el?.firstName + " " + el.lastName);
        let teams_string_array = el?.teams?.map((one) => one.name);
        return {
          key: el.id,
          value: el.id,
          text:
            el.firstName +
            " " +
            el.lastName +
            " (" +
            t.people.roles[el.role.name] +
            ")",
          description: `${teams_string_array.join(" , ")}`,
          image: { avatar: true, src: the_image, size: "tiny" },
        };
      });
      setOptions((prevOptions) => ({
        ...prevOptions,
        primary_assignee: the_options,
      }));

      return the_options;
    }
  };

  /*
   * The function fetches team options data from an API and formats it for display.
   */
  const fetchTeamsOptions = async () => {
    const fetchData = await getAllTeams(
      organisationId,
      "",
      1,
      99999,
      [],
      [],
      [],
      !(roleData === "super_admin")
        ? data?.location
          ? [data?.location]
          : allIds
        : data?.location
        ? [data?.location]
        : []
    );

    if (fetchData.response) {
      let the_options = fetchData.response.map((el) => {
        const the_image = LetteredAvatarImageUrl(el.attributes.name);

        return {
          key: el.id,
          value: el.id,
          text: el.attributes.name,
          image: { avatar: true, src: the_image, size: "tiny" },
        };
      });
      setOptions((prevOptions) => ({
        ...prevOptions,
        teams: the_options,
      }));

      return the_options;
    }
  };

  /*
   * The function fetches procedure options from an API and maps the response data to a specific format
   */
  const fetchProceduresOptions = async () => {
    const fetchData = await getAllProcedures(false, organisationId);

    if (fetchData.response) {
      let the_options = fetchData.response.map((el) => {
        return {
          key: el.id,
          value: el.id,
          text: el.attributes.title,
        };
      });

      setOptions({
        ...options,
        procedures: the_options,
      });

      return the_options;
    }
  };

  /*
   * The `cancelHandler` function resets form fields, hides modals, and updates validation status for various form fields.
   */
  const cancelHandler = () => {
    if (fromWR) {
      setmodalPrevWidth(Math.max(window.innerWidth / 2, 514));
      setModalWidth(Math.max(window.innerWidth / 2, 514));
    } else {
      setModalWidth(Math.max(window.innerWidth / 2, 514));
    }
    setButtonStatus(0);
    if (WRtitle?.length) {
      setWRdata();
    }

    resetForm();

    untoggle();
    setNewCancelModal(false);
    toggleCancelModal(false);
    setInitChange(false);
    setValidation({
      title: {
        error: false,
        pattern: (title) => title != "",
        skipped: false,
        message: t.common.required_field,
      },
      category: {
        error: false,
        pattern: (category) => category != "",
        skipped: false,
        message: t.common.required_field,
      },
      endRepeat: {
        error:
          data?.orderType === "scheduled" &&
          data?.endType === "after" &&
          data?.endRepeat === "",
        pattern: (endRepeat) => endRepeat !== "",
        skipped: false,
        message: t.common.required_field,
      },
      endDate: {
        error:
          data?.orderType === "scheduled" &&
          data?.endType === "on" &&
          data?.endDate == null,
        pattern: (endRepeat) => endRepeat !== "",
        skipped: false,
        message: t.common.required_field,
      },

      orderType: {
        error: true,
        pattern: (orderType) => orderType != "",
        skipped: false,
        message: t.common.required_field,
      },
      location: {
        error: false,
        pattern: (location) => location != "",
        skipped: false,
        message: t.common.required_field,
      },

      procedure: {
        error: false,
        pattern: (procedure) => true,
        skipped: false,
        message: t.common.required_field,
      },
    });
  };

  const uploadFilessHandler = async (files = []) => {
    let theFiles = files.filter((el) => el.isChanged);
    theFiles = theFiles.map((el) => el.file);
    if (theFiles.length > 0) {
      let reponse = await uploadFile(theFiles);
      return reponse;
    } else {
      return false;
    }
  };

  const uploadAttachmentsHandler = async () => {
    let attachmentsResponse = [];
    let theFiles = attachments
      .filter((el) => el.isChanged)
      .map((el) => el.file);

    if (theFiles.length > 0) {
      let reponse = await uploadFile(theFiles);
      return reponse;
    } else {
      return false;
    }
  };

  /*
   * The `approveOrder` function asynchronously updates the status of a work request to 'approved' using the `updateWorkRequestStatus` function.
   */
  const approveOrder = async (orderID) => {
    const approveOrderResponse = await updateWorkRequestStatus(
      request.id,
      "approved",
      orderID,
      ""
    );

    return approveOrderResponse;
  };

  /*
   * The `onFileChange` function sets a new file attachment with random ID and file details when a file is selected.
   */
  const onFileChange = (files, name) => {
    setInitChange(true);
    var randomnumber = Math.floor(Math.random() * (0 - 100 + 1)) + 100;
    setAttachments({
      ...attachments,
      [name]: {
        file: files[0],
        id: randomnumber,
        name: files[0].name,
        type: files[0].type,
        cancelPopup: false,
        isChanged: true,
      },
    });
  };
  /*
   * The `validationHandler` function updates validation properties based on data inputs and checks for any errors in the data.
   */
  const validationHandler = () => {
    let currentValidation = {
      ...validation,
      title: {
        ...validation.title,
        error: validation.title.pattern(data.title) ? false : true,
        skipped: true,
      },
      procedure: {
        ...validation.procedure,
        error: validation.procedure.pattern(data.procedure) ? false : true,
        skipped: true,
      },
      category: {
        ...validation.category,
        error: validation.category.pattern(data.category) ? false : true,
        skipped: true,
      },

      location: {
        ...validation.location,
        error: validation.location.pattern(data.location) ? false : true,
        skipped: true,
      },
      orderType: {
        ...validation.orderType,
        error: validation.orderType.pattern(data.orderType) ? false : true,
        skipped: true,
      },
      endRepeat: {
        ...validation.endRepeat,
        error:
          data?.orderType === "scheduled" &&
          data?.endType === "after" &&
          data?.endRepeat === "",
        skipped: true,
      },

      endDate: {
        ...validation.endDate,
        error:
          data?.orderType === "scheduled" &&
          data?.endType === "on" &&
          data?.endDate == null,
        skipped: true,
      },
      daysRepeat: {
        ...validation.daysRepeat,
        error:
          data?.orderType === "scheduled" &&
          ["daily", "weekly"].includes(data?.repeatEvery)
            ? !areAnyDaysSelected
            : false,
      },
    };

    setDayFlag(!areAnyDaysSelected);

    setValidation(currentValidation);
    const validationToArray = Object.entries(currentValidation);
    const errorsInData = validationToArray.some((el) => el[1].error);
    return errorsInData;
  };

  const transformDataSet = (dataArray) => {
    return dataArray?.map((data) => ({
      set: data?.part, // Set the `set` field to `part` value
      location: data?.location ? data?.location : null, // Use `asset` if available, otherwise default to 58
      unitCost: data?.unitCost !== null ? Number(data?.unitCost) : null, // Use unitCost if positive, else 100
      quantity: data?.quantity !== null ? data?.quantity : 1, // Use quantity if positive, else 5
      totalCost: data?.totalCost !== null ? Number(data?.totalCost) : null, // Calculate or default
    }));
  };

  const transformDataPart = (dataArray) => {
    return dataArray?.map((data) => ({
      part: data?.part, // Set the `set` field to `part` value
      location: data?.location ? data?.location : null, // Use `asset` if available, otherwise default to 58
      unitCost: data?.unitCost !== null ? Number(data?.unitCost) : null, // Use unitCost if positive, else 100
      quantity: data?.quantity !== null ? data?.quantity : 1, // Use quantity if positive, else 5
      totalCost: data?.totalCost !== null ? Number(data?.totalCost) : null, // Calculate or default
    }));
  };
  /*
   * The function `createNewWorkOrderHandler` handles the creation of a new work order by validating data, uploading attachments, and making API calls to create the work order.
   */
  const createNewWorkOrderHandler = async () => {
    const errorsInData = validationHandler();

    if (!errorsInData) {
      if (data?.people?.length > 0 && !data?.primaryAssignee) {
        return toast.error("Please select primary assignee");
      }

      const parts = selectedFinalPartData.filter(
        (item) => item.type === "Part"
      );
      const sets = selectedFinalPartData.filter((item) => item.type === "Set");

      let setData = transformDataSet(sets);
      let partData = transformDataPart(parts);

      setButtonStatus(1);
      const assetImageReponse =
        attachments.image && attachments.image.isChanged
          ? await uploadFile([attachments.image.file])
          : null;

      const workOrderFile =
        attachments.warrantyFile && attachments?.warrantyFile?.length
          ? await uploadFilessHandler(attachments.warrantyFile)
          : null;
      let sendedDueTime = data?.dueTime
        ? format(new Date(data?.dueDate + "T" + data?.dueTime), "kk:mm:ss.SSS")
        : null;

      let sendedStartTime = data?.orderType
        ? data?.startTime
          ? format(
              new Date(data?.startDate + "T" + data?.startTime),
              "kk:mm:ss.SSS"
            )
          : format(
              new Date(
                data?.startDate +
                  "T" +
                  getLocalTime(organisationTimezone.split("+")[1])
              ),
              "kk:mm:ss.SSS"
            )
        : null;

      await createNewAsset2Handler(
        assetImageReponse?.status == 200 ? assetImageReponse.data[0].id : null,
        workOrderFile?.status == 200
          ? workOrderFile.data.map((el) => el.id)
          : null,
        sendedDueTime,
        partData,
        setData,
        sendedStartTime
      );
    } else {
      scrollToError();
    }
  };

  /*
   * The function `createNewAsset2Handler` handles the creation of new assets based on certain conditions and performs additional actions based on the response.
   */
  const createNewAsset2Handler = async (
    image,
    file,
    sendedDueTime,
    partData,
    setData,
    sendedStartTime
  ) => {
    const response =
      data.orderType == "scheduled"
        ? await createNewSchedule(
            data.title,
            data.description,
            data.customer ? data.customer : null,
            data.location,
            data.orderType,
            format(new Date(data.dueDate), "yyyy-MM-dd"),

            sendedDueTime ? sendedDueTime : "23:59:00.000",

            data.repeatEvery,
            data.daysRepeat,
            data.repeatEveryWeek,
            data.repeatEveryMonth,
            data.repeatMonthDay,
            data.repeatEveryYear,
            data.endRepeat,
            Number(data.estimationTime),
            Number(data.laborCost),
            data.priority,
            data.category,
            data.people,
            data.teams,
            data.assets,
            partData,
            data.procedure ? data.procedure : null,
            image,
            file,
            organisationId,
            currentUserId,
            data?.endType === "on"
              ? data?.endDate
                ? format(new Date(data.endDate), "yyyy-MM-dd")
                : null
              : null,
            data.endType,
            data.qrcode ? true : false,
            WRID ? WRID : null,
            setData,
            data?.area ? Number(data.area) : null,
            format(new Date(data?.startDate), "yyyy-MM-dd"),
            sendedStartTime,
            data?.primaryAssignee ? data?.primaryAssignee : null
          )
        : await createNewWorkOrder(
            data.title,
            data.description,
            data.customer ? data.customer : null,
            data.location,
            data.orderType,
            format(new Date(data.dueDate), "yyyy-MM-dd"),
            sendedDueTime ? sendedDueTime : "23:59:00.000",

            data.repeatEvery,

            data.daysRepeat,
            data.repeatEveryWeek,
            data.repeatEveryMonth,
            data.repeatMonthDay,
            data.repeatEveryYear,
            data.endRepeat,
            Number(data.estimationTime),
            Number(data.laborCost),
            data.priority,
            data.category,
            data.people,
            data.teams,
            data.assets,
            partData,
            data.procedure ? data.procedure : null,
            image,
            file,
            organisationId,
            currentUserId,
            data.qrcode ? true : false,

            WRID ? WRID : null,
            setData,
            data?.area ? Number(data.area) : null,
            data?.orderType == "reactive"
              ? format(new Date(data?.startDate), "yyyy-MM-dd")
              : null,
            sendedStartTime,
            data?.primaryAssignee ? data?.primaryAssignee : null
          );

    if (response && response.status == 200) {
      if (request) {
        const approveResponse = await approveOrder(response.data.id);
        if (approveResponse.status == 200) {
          setButtonStatus(0);

          untoggle();
          resetForm();
          toggleSuccessModal(true);
          if (WRID) {
            handleSuccess(true);
          } else {
            toast.success(t.workOrders.form.success.msg);
            const currentPathname = window.location.pathname;
            setRefresh(!refresh);
            if (currentPathname == "/work-orders") {
              setSearchTitle("");
              setSelectedDates([]);
              setFilterOptions({
                serialNumber: "",
                assignees: {
                  people: [],
                  teams: [],
                },
                people: [],
                orderType: [],
                teams: [],
                customers: [],
                locations: [],
                createdByUser: [],
                dueDate: {
                  value: 0,
                  text: "Any Date",
                },
                status: [],
                priority: [],
                categories: [],
              });

              if (initData?.length == 0) {
                const fetchData = await dispatch(
                  fetchWOInitData({
                    organisationId,
                    role: roleData,
                    allIds,
                    currentUserId,
                  })
                );
              }
            }
          }
        }
      } else {
        window.dispatchEvent(new Event("triggerNotificationRefresh"));
        untoggle();
        resetForm();
        toggleSuccessModal(true);
        if (WRID) {
          untoggle();
          handleSuccess(true);
        } else {
          toast.success(t.workOrders.form.success.msg);
          const currentPathname = window.location.pathname;
          setRefresh(!refresh);
          if (currentPathname == "/work-orders") {
            setSearchTitle("");
            setSelectedDates([]);
            setFilterOptions({
              serialNumber: "",
              people: [],
              teams: [],
              customers: [],
              locations: [],
              orderType: [],
              createdByUser: [],
              status: [],
              priority: [],
              categories: [],
            });

            if (initData?.length == 0) {
              const fetchData = await dispatch(
                fetchWOInitData({
                  organisationId,
                  role: roleData,
                  allIds,
                  currentUserId,
                })
              );
            }
          }
        }
      }
    } else if (response.status == 400) {
      if (response.error && response.error.message) {
        if (
          response.error.message ==
          "Insufficient quantity available for the part added in this scheduled WO."
        ) {
          toast.error(t.workOrders.part_error);
        } else if (
          response.error.message ==
          "Insufficient quantity available for the sets added in this scheduled WO."
        ) {
          toast.error(t.workOrders.set_error);
        } else {
          toast.error(response.error.message);
        }
      } else {
        toast.error(t.common.something_wrong);
      }
    } else {
      toast.error(t.common.something_wrong);
      untoggle();
    }
    setButtonStatus(0);
  };

  // add popups
  const handleAddCustomer = (e, { value }) => {
    setCustomerText(value);

    toggleAddCustomerModal(true);
  };

  const returnAddCustomer = (id) => {
    fetchCustomerOptions().then((resp_data) => {
      if (resp_data.map((option) => option.value).includes(id)) {
        setData({
          ...data,
          customer: id,
        });
      }
    });
  };

  const handleAddProcedure = (e, { value }) => {
    setProcedureText(value);
    toggleAddProcedureModal(true);
  };

  const returnAddProcedure = (id) => {
    fetchProceduresOptions().then((resp_data) => {
      if (resp_data.map((option) => option.value).includes(id)) {
        setData({
          ...data,
          procedure: id,
        });
      }
    });
  };

  const handleAddLocation = (e, { value }) => {
    setLocationText(value);
    toggleAddLocationModal(true);
  };

  const returnAddLocation = (id) => {
    fetchLocationsOptions(data.customer).then((resp_data) => {
      if (resp_data.map((option) => option.value).includes(id)) {
        setData({
          ...data,
          location: id,
        });
      }
    });
  };

  const handleAddTeam = (e, { value }) => {
    setTeamText(value);
    toggleAddTeamModal(true);
  };

  const returnAddTeam = (id) => {
    fetchTeamsOptions().then((resp_data) => {
      if (resp_data.map((option) => option.value).includes(id)) {
        const picked_before = data.teams;

        picked_before.push(id);
        setData({
          ...data,
          teams: picked_before,
        });
      }
    });
  };

  const handleAddPeople = (e, { value }) => {
    setPeopleText(value);
    toggleAddPeopleModal(true);
  };

  const returnAddPeople = (id) => {
    fetchPeopleOptions().then((resp_data) => {
      if (resp_data.map((option) => option.value).includes(id)) {
        const picked_before = data.people;

        picked_before.push(id);
        setData({
          ...data,
          people: picked_before,
        });
      }
    });
  };

  const handleAddAsset = (e, { value }) => {
    setAssetText(value);
    toggleAddAssetModal(true);
  };

  const returnAddAsset = (id) => {
    fetchAssetsOptions().then((resp_data) => {
      if (resp_data.map((option) => option.value).includes(id)) {
        const picked_before = data.assets;

        picked_before.push(id);
        setData({
          ...data,
          assets: picked_before,
        });
      }
    });
  };

  const returnAddPart = (id) => {
    fetchPartsOptions(data.assets).then((resp_data) => {
      if (resp_data.map((option) => option.value).includes(id)) {
        const picked_before = data.parts;

        picked_before.push(id);
        setData({
          ...data,
          parts: picked_before,
        });
      }
    });
  };

  const [modalWidth, setModalWidth] = useState(
    fromWR ? modalPrevWidth : Math.max(window.innerWidth / 2, 514)
  );

  return (
    <>
      <div
        style={{ maxWidth: fromWR ? `${modalPrevWidth}px` : `${modalWidth}px` }}
        clearing={true}
        className={
          toggled
            ? "aside-modal po active procedure"
            : "aside-modal po procedure"
        }
      >
        <>
          {" "}
          <Resizable
            size={{
              width: fromWR ? modalPrevWidth : modalWidth,
              height: "auto",
            }}
            minWidth={"514px"}
            onResize={(e, direction, ref, d) => {
              if (fromWR) {
                setmodalPrevWidth(ref.offsetWidth);
                setModalWidth(ref.offsetWidth);
              } else {
                setModalWidth(ref.offsetWidth);
              }
            }}
          >
            <div className="modal-header">
              <div className="flexbox justify-between">
                <div className="name">{t.workOrders.create_WO}</div>
                <div>
                  <span
                    className="c-pointer"
                    style={{ position: "relative" }}
                    onMouseOver={() => setCancleTooltip(true)}
                    onMouseLeave={() => setCancleTooltip(false)}
                    onClick={() =>
                      initChange ? setNewCancelModal(true) : cancelHandler()
                    }
                  >
                    <CloseIcon />
                    <ToolTip
                      color="#fff"
                      backgroundColor="#172B4D"
                      position={
                        selectedLanguage == "en"
                          ? "bottom center"
                          : "bottom center"
                      }
                      show={showCancleTooltip}
                      textBoxWidth="36px"
                      arrowAlign="center"
                      padding="5px 5px 5px 7px"
                      borderRadius="5px"
                      static
                    >
                      <span className="work-order-preview-tooltip">
                        {t.common.close}
                      </span>
                    </ToolTip>
                  </span>
                </div>
              </div>
            </div>
            <div
              style={{
                height: "90vh",
                overflowX: "hidden",
                overflowY: "scroll",
              }}
              className="body p-6"
            >
              {buttonStatus == 1 ? (
                <Dimmer active inverted>
                  <Loader inverted content="Loading" />
                </Dimmer>
              ) : (
                ""
              )}
              <Form.Field>
                <label className="label-item">
                  {t.workOrders.table.title}
                  <Asterisk color="#172B4D" />
                </label>
                <Input
                  autoComplete="new-password"
                  fluid
                  placeholder={t.workOrders.form.title_name}
                  disabled={request?.title ? true : false}
                  value={data.title}
                  onChange={onValuesChange("title")}
                  error={validation.title.error && validation.title.skipped}
                  onBlur={(e) => {
                    if (validation.title.pattern(data.title)) {
                      setValidation({
                        ...validation,
                        title: {
                          ...validation.title,
                          error: false,
                          skipped: true,
                        },
                      });
                    } else {
                      setValidation({
                        ...validation,
                        title: {
                          ...validation.title,
                          error: true,
                          skipped: true,
                        },
                      });
                    }
                  }}
                />
                {validation.title.error && validation.title.skipped ? (
                  <div className="label-error">{validation.title.message}</div>
                ) : (
                  ""
                )}
              </Form.Field>

              <FileUpload
                onFileChange={onFileChange}
                name="image"
                attachments={attachments}
                setAttachments={setAttachments}
              />

              <Form style={{ marginBottom: "20px" }}>
                <Form.Field>
                  <label className="label-item mt-5">
                    {t.workOrders.form.description}
                  </label>
                  <TextArea
                    autoComplete="new-password"
                    value={data.description}
                    onChange={onValuesChange("description")}
                    rows={3}
                    fluid
                    placeholder={t.workOrders.form.description_text}
                  />
                </Form.Field>
              </Form>

              <div className="priority-container">
                <label className="label-item">
                  {t.workOrders.table.priority}
                  <Asterisk color="#172B4D" />
                </label>

                <div className="priority-options">
                  {options?.priority?.map((priority) => (
                    <div
                      key={priority?.value}
                      className={`priority-btn ${
                        data?.priority === priority?.value
                          ? `selected-${priority?.value}`
                          : ""
                      }`}
                      onClick={() =>
                        setData({
                          ...data,
                          priority: priority.value,
                        })
                      }
                    >
                      <FlagwithFilled color={priority?.color} />
                      <span>{priority?.text}</span>
                    </div>
                  ))}
                </div>
              </div>

              <Form.Field>
                <label className="label-item mt-5">
                  {t.workOrders.table.category}
                  <Asterisk color="#172B4D" />
                </label>
                <Dropdown
                  placeholder={t.assets.form.select_category}
                  fluid
                  selection
                  clearable
                  search
                  options={options.categories}
                  noResultsMessage={t.common.no_results_found}
                  error={
                    validation.category.error && validation.category.skipped
                  }
                  value={data.category}
                  onChange={onValuesChange("category")}
                  onBlur={(e) => {
                    if (validation.category.pattern(data.category)) {
                      setValidation({
                        ...validation,
                        category: {
                          ...validation.category,
                          error: false,
                          skipped: true,
                        },
                      });
                    } else {
                      setValidation({
                        ...validation,
                        category: {
                          ...validation.category,
                          error: true,
                          skipped: true,
                        },
                      });
                    }
                  }}
                />
                {validation.category.error && validation.category.skipped ? (
                  <div className="label-error">
                    {validation.category.message}
                  </div>
                ) : (
                  ""
                )}
              </Form.Field>

              <Form.Field>
                <label className="label-item">
                  {t.workOrders.table.location}
                  <Asterisk color="#172B4D" />
                </label>
                <Dropdown
                  placeholder={t.workOrders.form.location_placeholder}
                  fluid
                  className="loc-dropdown"
                  selection
                  clearable
                  noResultsMessage={t.common.no_results_found}
                  search={(options, query) => {
                    const filteredOptions = [];
                    const addedKeys = new Set(); // To track and avoid duplicate entries

                    options.forEach((option) => {
                      const isParent = !option.parentId;
                      const matches = option.text
                        .toLowerCase()
                        .includes(query.toLowerCase());

                      // If the parent matches, add it and all related sub-locations
                      if (isParent && matches) {
                        if (!addedKeys.has(option.key)) {
                          filteredOptions.push(option);
                          addedKeys.add(option.key);
                        }
                        options.forEach((subOption) => {
                          if (
                            subOption.parentId === option.key &&
                            !addedKeys.has(subOption.key)
                          ) {
                            filteredOptions.push(subOption);
                            addedKeys.add(subOption.key);
                          }
                        });
                      }
                      // If a sub-location matches, add both the sub-location and its parent
                      else if (!isParent && matches) {
                        const parentOption = options.find(
                          (opt) => opt.key === option.parentId
                        );
                        if (parentOption && !addedKeys.has(parentOption.key)) {
                          filteredOptions.push(parentOption);
                          addedKeys.add(parentOption.key);
                        }
                        if (!addedKeys.has(option.key)) {
                          filteredOptions.push(option);
                          addedKeys.add(option.key);
                        }
                      }
                    });

                    return filteredOptions;
                  }}
                  onAddItem={handleAddLocation}
                  options={options.locations}
                  // onOpen={fetchLocationsOptions}
                  value={data.location}
                  onChange={onValuesChange("location")}
                  error={
                    validation.location.error && validation.location.skipped
                  }
                  onBlur={(e) => {
                    if (validation.location.pattern(data.location)) {
                      setValidation({
                        ...validation,
                        location: {
                          ...validation.location,
                          error: false,
                          skipped: true,
                        },
                      });
                    } else {
                      setValidation({
                        ...validation,
                        location: {
                          ...validation.location,
                          error: true,
                          skipped: true,
                        },
                      });
                    }
                  }}
                />
                {validation.location.error && validation.location.skipped ? (
                  <div className="label-error">
                    {validation.location.message}
                  </div>
                ) : (
                  ""
                )}
              </Form.Field>

              <Form.Field>
                <label className="label-item">{t.parts.filter.area}</label>
                <Dropdown
                  placeholder={t.parts.form.add_area}
                  fluid
                  selection
                  clearable
                  noResultsMessage={t.common.no_results_found}
                  search
                  disabled={!data.location && !(roleData === "super_admin")}
                  options={options.area}
                  value={data.area}
                  onChange={onValuesChange("area")}
                />
              </Form.Field>

              <Form.Field>
                <label className="label-item">
                  {t.workOrders.table.customer}
                </label>
                <Dropdown
                  placeholder={t.workOrders.form.customer_placeholder}
                  search
                  clearable
                  fluid
                  noResultsMessage={t.common.no_results_found}
                  selection
                  onAddItem={handleAddCustomer}
                  options={options.customers}
                  value={data.customer}
                  onChange={(e, value) => {
                    let findCustomer = value.options.find(
                      (one) => one.value == value.value
                    );
                    if (findCustomer) {
                      setData({
                        ...data,
                        customer: value.value,
                      });
                    } else {
                      setData({
                        ...data,
                        customer: "",
                      });
                    }
                  }}
                  onOpen={fetchCustomerOptions}
                />
              </Form.Field>

              <Form.Field>
                <label className="label-item">{t.workOrders.form.assets}</label>
                <Dropdown
                  placeholder={t.workOrders.form.assets_placeholder}
                  fluid
                  selection
                  noResultsMessage={t.common.no_results_found}
                  multiple
                  className="people-dropdown"
                  search
                  onOpen={fetchAssetsOptions}
                  options={options.assets}
                  value={data.assets}
                  onChange={(e, value) => {
                    // Find the currently selected options
                    const selectedOptions = options.assets.filter((option) =>
                      value?.value.includes(option.value)
                    );

                    let newSelectedValues = [...value?.value];

                    selectedOptions.forEach((option) => {
                      // When selecting a parent asset
                      if (
                        !option?.disabled &&
                        option.className === "main-location-dropdown-option"
                      ) {
                        // Find child assets of this parent
                        const childOptions = options.assets.filter(
                          (subOption) =>
                            subOption.parentId === option.key &&
                            !subOption.disabled
                        );

                        // Add child assets to the selected values if they are not disabled
                        newSelectedValues = [
                          ...newSelectedValues,
                          ...childOptions.map((child) => child.value),
                        ];

                        // For each child, find its grandchildren (if the child is selected)
                        childOptions.forEach((child) => {
                          const grandChildren = options.assets.filter(
                            (grandChild) =>
                              grandChild.childParentId === child.key &&
                              !grandChild.disabled
                          );

                          // Add grandchildren to the selected values if they are not disabled
                          newSelectedValues = [
                            ...newSelectedValues,
                            ...grandChildren.map(
                              (grandChild) => grandChild.value
                            ),
                          ];
                        });
                      }

                      // When selecting a child asset
                      if (
                        !option?.disabled &&
                        option.className === "sub-location-dropdown-option"
                      ) {
                        // Find grandchild assets of this child
                        const grandChildren = options.assets.filter(
                          (grandChild) =>
                            grandChild.childParentId === option.key &&
                            !grandChild.disabled
                        );

                        // Add grandchildren to the selected values if they are not disabled
                        newSelectedValues = [
                          ...newSelectedValues,
                          ...grandChildren.map(
                            (grandChild) => grandChild.value
                          ),
                        ];
                      }
                    });

                    // Update state with new selected values
                    setData({
                      ...data,
                      assets: [...new Set(newSelectedValues)], // Ensure unique values only
                    });
                  }}
                />
              </Form.Field>

              <Form.Field>
                <label className="label-item">
                  {t.workOrders.form.procedure}
                </label>
                <Dropdown
                  placeholder={t.workOrders.form.procedure_placeholder}
                  fluid
                  noResultsMessage={t.common.no_results_found}
                  selection
                  search
                  clearable
                  onAddItem={handleAddProcedure}
                  options={options.procedures}
                  value={data.procedure}
                  onChange={onValuesChange("procedure")}
                  onOpen={fetchProceduresOptions}
                  error={
                    validation.procedure.error && validation.procedure.skipped
                  }
                  onBlur={(e) => {
                    if (validation.procedure.pattern(data.procedure)) {
                      setValidation({
                        ...validation,
                        procedure: {
                          ...validation.procedure,
                          error: false,
                          skipped: true,
                        },
                      });
                    } else {
                      setValidation({
                        ...validation,
                        procedure: {
                          ...validation.procedure,
                          error: true,
                          skipped: true,
                        },
                      });
                    }
                  }}
                />
                {validation.procedure.error && validation.procedure.skipped ? (
                  <div className="label-error">
                    {validation.procedure.message}
                  </div>
                ) : (
                  ""
                )}
              </Form.Field>

              <label className="label-item mb-0">
                {t.workOrders.form.part_set}
              </label>

              {selectedFinalPartData?.length > 0 ? (
                <>
                  <div className="scroll-data" style={{ overflow: "auto" }}>
                    <div className={"mt-6"}>
                      <div className={`kanbar-header`}>
                        <div class="grid-container-element set wo-form2">
                          <div
                            style={{
                              minWidth: "86px",
                              backgroundColor: "#e9f2ff",
                            }}
                            class="grid-child-element set"
                          >
                            {t.workOrders.table.ID}
                          </div>
                          <div
                            style={{
                              minWidth: "140px",
                              backgroundColor: "#e9f2ff",
                            }}
                            class="grid-child-element set"
                          >
                            {t.workOrders.details_view.name}
                          </div>
                          <div
                            style={{
                              minWidth: "140px",
                              backgroundColor: "#e9f2ff",
                            }}
                            class="grid-child-element set"
                          >
                            {t.parts.form.inv_loc}
                          </div>
                          <div
                            style={{
                              minWidth: "120px",
                              backgroundColor: "#e9f2ff",
                            }}
                            class="grid-child-element set"
                          >
                            {t.parts.table.quantity}
                          </div>{" "}
                          <div
                            style={{
                              minWidth: "130px",
                              textAlign: "center",
                              backgroundColor: "#e9f2ff",
                            }}
                            class="grid-child-element set"
                          >
                            {t.parts.form.unit_cost}
                          </div>
                          <div
                            style={{
                              minWidth: "130px",
                              textAlign: "center",
                              backgroundColor: "#e9f2ff",
                            }}
                            class="grid-child-element set"
                          >
                            {t.parts.form.total_cost}
                          </div>
                          <div
                            style={{
                              minWidth: "40px",
                              backgroundColor: "#e9f2ff",
                            }}
                            class="grid-child-element set"
                          ></div>
                        </div>
                      </div>
                    </div>

                    {selectedFinalPartData.map((part, index) => (
                      <div
                        className="grid-container-element set wo-form2 form"
                        key={index}
                      >
                        <div
                          className="grid-child-element set form"
                          style={{
                            padding: "14px 9px",
                            minWidth: "86px",
                          }}
                        >
                          {part?.countId ? part.countId : null}
                        </div>

                        <div
                          className="grid-child-element set form"
                          style={{
                            padding: "14px 9px",
                            minWidth: "140px",
                          }}
                        >
                          {DisplayFormattedText(part?.name, 13)}
                        </div>
                        <div
                          className="grid-child-element set form"
                          style={{
                            padding: "14px 9px",
                            minWidth: "140px",
                          }}
                        >
                          {DisplayFormattedText(part?.location_name, 13)}
                        </div>
                        <div
                          className="wrap-input-set grid-child-element set form"
                          style={{
                            minWidth: "120px",
                          }}
                        >
                          <Input
                            autoComplete="new-password"
                            style={{ width: "52px", height: "34px" }}
                            type="number"
                            onWheel={(e) => e.target.blur()}
                            onKeyDown={(evt) => handleKeyDown(evt)}
                            value={part?.quantity}
                            className="d-flex"
                            min="1"
                            onChange={(e) => {
                              let allPartData = [...selectedFinalPartData];

                              if (Number(e.target.value)) {
                                let value = parseFloat(e.target.value).toFixed(
                                  4
                                );

                                allPartData[index].quantity = Number(value);

                                let totalCostData = handleDecimalPoints(
                                  Number(value * part?.unitCost)
                                );
                                allPartData[index].totalCost = totalCostData;

                                setSelectedFinalPartData(allPartData);
                              } else {
                                allPartData[index].quantity = "";

                                allPartData[index].totalCost = 0;

                                setSelectedFinalPartData(allPartData);
                              }
                            }}
                            error={selectedFinalPartData[index]?.quantity == ""}
                          >
                            <input />

                            <span
                              className={` align-items-center justify-content-center ${
                                selectedLanguage == "en" ? "ml-2" : "mr-2"
                              }`}
                              style={
                                part?.unitType
                                  ? {
                                      backgroundColor: "#E9F2FF",
                                      color: "#0C66E4",
                                      padding: "3px 4px",
                                      fontSize: "14px",
                                      minWidth:
                                        selectedLanguage == "en"
                                          ? "33px"
                                          : "48px",
                                      textAlign: "center",
                                    }
                                  : {}
                              }
                            >
                              {part?.unitType
                                ? HandleUnitType(part?.unitType)
                                : null}
                            </span>
                          </Input>
                          {selectedFinalPartData[index]?.quantity == "" ? (
                            <span className="label-error">
                              {t.common.required_field}
                            </span>
                          ) : null}
                        </div>
                        <div
                          className={
                            selectedLanguage == "en"
                              ? "grid-child-element set form mt-2"
                              : "grid-child-element set form mt-2"
                          }
                          style={{
                            minWidth: "130px",
                          }}
                        >
                          <div
                            style={{
                              width: "75px",
                              display: "inline-block",
                              textAlign: "end",
                            }}
                            className="number-cost-set"
                          >
                            {DisplayFormattedText(String(part?.unitCost), 7)}
                          </div>
                          <span
                            className={` align-items-center justify-content-center ${
                              selectedLanguage == "en" ? "ml-2" : "mr-2"
                            }`}
                            style={{
                              backgroundColor: "#E9F2FF",
                              color: "#0C66E4",
                              padding: "9px 3px 9px 2px",
                              fontSize: "14px",
                            }}
                          >
                            {organisationCurrency}
                          </span>
                        </div>

                        <div
                          className={
                            selectedLanguage == "en"
                              ? "grid-child-element set form mt-2"
                              : "grid-child-element set form mt-2"
                          }
                          style={{
                            minWidth: "130px",
                          }}
                        >
                          <div
                            style={{
                              width: "75px",
                              display: "inline-block",
                              textAlign: "end",
                            }}
                            className="number-cost-set"
                          >
                            {DisplayFormattedText(String(part?.totalCost), 7)}
                          </div>
                          <span
                            className={` align-items-center justify-content-center ${
                              selectedLanguage == "en" ? "ml-2" : "mr-2"
                            }`}
                            style={{
                              backgroundColor: "#E9F2FF",
                              color: "#0C66E4",
                              padding: "9px 3px 9px 2px",
                              fontSize: "14px",

                              textAlign: "end",
                            }}
                          >
                            {organisationCurrency}
                          </span>
                        </div>

                        <div
                          onClick={() => {
                            removeFromSelectedParts(
                              index,
                              selectedFinalPartData
                            );
                          }}
                          className="grid-child-element set form mt-2"
                        >
                          <DeleteIcon />
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              ) : null}

              <button
                className={`buttun-type-link ${
                  selectedFinalPartData?.length > 0 ? "mt-5" : "mt-2"
                }  mb-7`}
                basic
                type="button"
                style={{ background: "transparent" }}
                onClick={() => addParts(selectedTablePartData)}
              >
                <span className="customer-add-btn">
                  {" "}
                  <CustomerPlusIcon />{" "}
                </span>

                <p className={selectedLanguage == "en" ? "ml-2" : "mr-2"}>
                  {t.workOrders.form.add_part_set}
                </p>
              </button>

              <Form.Field>
                <label
                  className={
                    userPermissions?.part?.view ? "label-item" : "label-item"
                  }
                >
                  {t.workOrders.form.primary}
                </label>
                <Dropdown
                  placeholder={t.workOrders.form.primary_select}
                  fluid
                  noResultsMessage={t.common.no_results_found}
                  selection
                  search
                  clearable
                  options={options.primary_assignee}
                  value={data.primaryAssignee}
                  onChange={onValuesChange("primaryAssignee")}
                  onOpen={fetchPrimaryAssigneeOptions}
                />
              </Form.Field>

              <Form.Field>
                <label
                  className={
                    userPermissions?.part?.view ? "label-item" : "label-item"
                  }
                >
                  {t.workOrders.form.secondary}{" "}
                  <span
                    className="c-pointer"
                    style={{ position: "relative" }}
                    onMouseOver={() => setPeopleTooltip(true)}
                    onMouseLeave={() => setPeopleTooltip(false)}
                  >
                    {showPeopleTooltip ? (
                      <>
                        <InfoIconBlue />
                        <ToolTip
                          color="#fff"
                          backgroundColor="#172B4D"
                          position={
                            selectedLanguage == "ar" ? "left top" : "right top"
                          }
                          show={showPeopleTooltip}
                          textBoxWidth="130px"
                          arrowAlign="center"
                          fontSize="9px"
                          fontWeight="400"
                          fontFamily={
                            selectedLanguage == "ar"
                              ? "Noto Kufi Arabic"
                              : "Roboto"
                          }
                          padding="4px 14px 4px 7px"
                          lineHeight="16px"
                          borderRadius="6px"
                          static
                          moveDown="0px"
                        >
                          <span className="work-order-preview-tooltip parts">
                            {t.workOrders.form.add_people_tooltip}
                          </span>
                        </ToolTip>
                      </>
                    ) : (
                      <InfoIcon />
                    )}
                  </span>
                </label>
                <Dropdown
                  placeholder={t.workOrders.form.secondary_select}
                  fluid
                  noResultsMessage={t.common.no_results_found}
                  selection
                  search
                  disabled={!data.primaryAssignee}
                  multiple
                  onAddItem={handleAddPeople}
                  options={options.people}
                  value={data.people}
                  onChange={onValuesChange("people")}
                  onOpen={fetchPeopleOptions}
                />
              </Form.Field>

              <Form.Field>
                <label className="label-item">{t.workOrders.form.teams}</label>
                <Dropdown
                  placeholder={t.workOrders.form.teams_placeholder}
                  fluid
                  noResultsMessage={t.common.no_results_found}
                  selection
                  search
                  multiple
                  onAddItem={handleAddTeam}
                  options={options.teams}
                  value={data.teams}
                  onChange={onValuesChange("teams")}
                  onOpen={fetchTeamsOptions}
                />
              </Form.Field>

              <Form.Field>
                <label className="label-item">
                  {t.workOrders.form.order_type}
                  <Asterisk color="#172B4D" />
                </label>
                <Dropdown
                  placeholder={t.workOrders.form.add_order_type}
                  fluid
                  noResultsMessage={t.common.no_results_found}
                  selection
                  options={options.orderTypes}
                  value={data.orderType}
                  onChange={onValuesChange("orderType")}
                  error={
                    validation.orderType.error && validation.orderType.skipped
                  }
                  onBlur={(e) => {
                    if (validation.orderType.pattern(data.orderType)) {
                      setValidation({
                        ...validation,
                        orderType: {
                          ...validation.orderType,
                          error: false,
                          skipped: true,
                        },
                      });
                    } else {
                      setValidation({
                        ...validation,
                        orderType: {
                          ...validation.orderType,
                          error: true,
                          skipped: true,
                        },
                      });
                    }
                  }}
                />
                {validation.orderType.error && validation.orderType.skipped ? (
                  <div className="label-error">
                    {validation.orderType.message}
                  </div>
                ) : (
                  ""
                )}
              </Form.Field>

              <>
                <Grid className="mb-0" columns={2}>
                  <GridColumn className="pb-0">
                    <Form.Field>
                      <label className="label-item">
                        {t.workOrders.details_view.start_date}
                      </label>

                      <div
                        className="change-date-time-inll position-relative"
                        style={{ marginBottom: "-13px" }}
                      >
                        <DatePicker
                          minDate={
                            data?.orderType === "reactive"
                              ? new Date().setHours(0, 0, 0, 0)
                              : null
                          }
                          highlightToday={false}
                          style={{ width: "100%" }}
                          value={
                            data.startDate ? new Date(data.startDate) : null
                          }
                          onChange={(value) => {
                            if (value && value.valueOf()) {
                              setData({
                                ...data,
                                startDate: format(
                                  value.valueOf(),
                                  "yyyy-MM-dd"
                                ),
                                dueDate: format(value.valueOf(), "yyyy-MM-dd"),
                                endDate: format(value.valueOf(), "yyyy-MM-dd"),
                              });
                            } else {
                              setData({
                                ...data,
                                startDate: format(new Date(), "yyyy-MM-dd"),
                                dueDate: format(new Date(), "yyyy-MM-dd"),
                                endDate: format(new Date(), "yyyy-MM-dd"),
                              });
                            }
                          }}
                          showMonthDropdown
                          showYearDropdown
                          className={"active"}
                          weekDays={[
                            t.workOrders.details_view.sun,
                            t.workOrders.details_view.mon,
                            t.workOrders.details_view.tue,
                            t.workOrders.details_view.wed,
                            t.workOrders.details_view.thu,
                            t.workOrders.details_view.fri,
                            t.workOrders.details_view.sat,
                          ]}
                          months={[
                            t.common.january,
                            t.common.february,
                            t.common.march,
                            t.common.april,
                            t.common.may,
                            t.common.june,
                            t.common.july,
                            t.common.august,
                            t.common.september,
                            t.common.october,
                            t.common.november,
                            t.common.december,
                          ]}
                          format="DD/MM/YYYY"
                        ></DatePicker>
                        <img
                          className="position-absolute"
                          style={
                            selectedLanguage == "en"
                              ? { top: "8px", right: "7px" }
                              : { top: "8px", left: "7px" }
                          }
                          src={calendar_icon}
                        />
                      </div>
                    </Form.Field>
                  </GridColumn>

                  <GridColumn className="pb-0">
                    <Form.Field>
                      <label className="label-item">
                        {t.workOrders.details_view.start_time}
                      </label>
                      <div className="position-relative">
                        <Input
                          autoComplete="new-password"
                          type="time"
                          fluid
                          placeholder={t.workOrders.form.add_due_time}
                          value={data.startTime}
                          onChange={(e, { value }) => {
                            if (
                              data?.dueTime &&  value > data?.dueTime &&
                              data?.startDate == data?.dueDate
                            ) {
                              setData({
                                ...data,
                                dueTime: value,
                                startTime: value,
                              });
                            } else {
                              setData({
                                ...data,
                                startTime: value,
                              });
                            }
                          }}
                          onClick={(e) =>
                            e?.target?.showPicker && e.target.showPicker()
                          }
                        ></Input>

                        <img
                          className="mb-5 position-absolute"
                          style={
                            selectedLanguage == "en"
                              ? { top: "8px", right: "7px" }
                              : { top: "8px", left: "7px" }
                          }
                          src={clock_icon}
                        />
                      </div>
                    </Form.Field>
                  </GridColumn>
                </Grid>
              </>

              {data?.orderType && data?.orderType ? (
                <>
                  <Grid className="mt-0" columns={2}>
                    <GridColumn className="pt-0 pb-0">
                      <Form.Field>
                        <label className="label-item">
                          {t.workOrders.table.dueDate}
                        </label>

                        <div
                          className="change-date-time-inll position-relative"
                          style={{ marginBottom: "-13px" }}
                        >
                          <DatePicker
                            minDate={new Date(data.startDate)}
                            highlightToday={false}
                            style={{ width: "100%" }}
                            value={data.dueDate ? new Date(data.dueDate) : null}
                            onChange={(value) => {
                              if (value && value.valueOf()) {
                                setData({
                                  ...data,
                                  dueTime: "",
                                  dueDate: format(
                                    value.valueOf(),
                                    "yyyy-MM-dd"
                                  ),
                                });
                              } else {
                                setData({
                                  ...data,
                                  dueDate: format(new Date(), "yyyy-MM-dd"),
                                  dueTime: "",
                                });
                              }
                            }}
                            showMonthDropdown
                            showYearDropdown
                            placeholder={t.workOrders.form.add_due_date}
                            className={"active"}
                            weekDays={[
                              t.workOrders.details_view.sun,
                              t.workOrders.details_view.mon,
                              t.workOrders.details_view.tue,
                              t.workOrders.details_view.wed,
                              t.workOrders.details_view.thu,
                              t.workOrders.details_view.fri,
                              t.workOrders.details_view.sat,
                            ]}
                            months={[
                              t.common.january,
                              t.common.february,
                              t.common.march,
                              t.common.april,
                              t.common.may,
                              t.common.june,
                              t.common.july,
                              t.common.august,
                              t.common.september,
                              t.common.october,
                              t.common.november,
                              t.common.december,
                            ]}
                            format="DD/MM/YYYY"
                          ></DatePicker>
                          <img
                            className="position-absolute"
                            style={
                              selectedLanguage == "en"
                                ? { top: "8px", right: "7px" }
                                : { top: "8px", left: "7px" }
                            }
                            src={calendar_icon}
                          />
                        </div>
                      </Form.Field>
                    </GridColumn>

                    <GridColumn className="pt-0">
                      <Form.Field>
                        <label className="label-item">
                          {t.workOrders.form.due_time}
                        </label>
                        <div className="position-relative">
                          <Input
                            autoComplete="new-password"
                            type="time"
                            fluid
                            placeholder={t.workOrders.form.add_due_time}
                            value={data.dueTime}
                            onChange={(e, { value }) => {
                              if (
                                value < data?.startTime &&
                                data?.startDate == data?.dueDate
                              ) {
                                setData({
                                  ...data,
                                  dueTime: data?.startTime,
                                });
                              } else {
                                setData({
                                  ...data,
                                  dueTime: value,
                                });
                              }
                            }}
                            onClick={(e) =>
                              e?.target?.showPicker && e.target.showPicker()
                            }
                          ></Input>

                          <img
                            className="mb-5 position-absolute"
                            style={
                              selectedLanguage == "en"
                                ? { top: "8px", right: "7px" }
                                : { top: "8px", left: "7px" }
                            }
                            src={clock_icon}
                          />
                        </div>
                      </Form.Field>{" "}
                    </GridColumn>
                  </Grid>
                </>
              ) : null}

              {data.orderType == "scheduled" ? (
                <>
                  <label className="label-item mb-2 font14">
                    <span
                      className={selectedLanguage == "en" ? "mr-2" : "ml-2"}
                    >
                      <RepeatIcon />
                    </span>{" "}
                    {t.workOrders.details_view.repeat}
                  </label>

                  <div
                    className={
                      data.repeatEvery != "daily" ? "repeat-container-flex" : ""
                    }
                  >
                    {data.repeatEvery != "daily" ? (
                      data.repeatEvery == "weekly" ? (
                        <Form.Field>
                          <div className="flexbox align-center">
                            <Dropdown
                              className="week-dropdown"
                              style={{ margin: "0 5px" }}
                              fluid
                              selection
                              noResultsMessage={t.common.no_results_found}
                              options={options.weeks}
                              value={data?.repeatEveryWeek}
                              onChange={onValuesChange("repeatEveryWeek")}
                            />
                          </div>
                        </Form.Field>
                      ) : data.repeatEvery == "monthly" ? (
                        <Form.Field>
                          <div className="flexbox align-center">
                            <Dropdown
                              style={{ margin: "0 5px" }}
                              className="week-dropdown"
                              fluid
                              selection
                              noResultsMessage={t.common.no_results_found}
                              options={options.months}
                              value={data.repeatEveryMonth}
                              placeholder={t.workOrders.details_view.select_no}
                              onChange={onValuesChange("repeatEveryMonth")}
                            />
                          </div>
                        </Form.Field>
                      ) : data.repeatEvery == "yearly" ? (
                        <Form.Field>
                          <div className="flexbox align-center">
                            <Dropdown
                              style={{ margin: "0 5px" }}
                              fluid
                              selection
                              noResultsMessage={t.common.no_results_found}
                              className="week-dropdown"
                              options={options.years}
                              value={data.repeatEveryYear}
                              placeholder={t.workOrders.details_view.select_no}
                              onChange={onValuesChange("repeatEveryYear")}
                            />
                          </div>
                        </Form.Field>
                      ) : (
                        ""
                      )
                    ) : null}

                    <Dropdown
                      placeholder="Repeat Everly"
                      className="repeat-everly"
                      fluid
                      noResultsMessage={t.common.no_results_found}
                      selection
                      options={options.repeatEvery}
                      value={data.repeatEvery}
                      onChange={(e, value) => {
                        let findPriority = value.options.find(
                          (one) => one.value == value.value
                        );
                        if (findPriority.value == "daily") {
                          setData({
                            ...data,
                            repeatEvery: "daily",
                            daysRepeat: {
                              sat: false,
                              sun: false,
                              mon: false,
                              tue: false,
                              wed: false,
                              thu: false,
                              fri: true,
                            },
                          });
                        } else if (findPriority.value == "weekly") {
                          setData({
                            ...data,
                            repeatEvery: "weekly",
                            daysRepeat: {
                              sat: false,
                              sun: false,
                              mon: false,
                              tue: false,
                              wed: false,
                              thu: false,
                              fri: false,
                            },
                          });
                        } else if (findPriority.value == "monthly") {
                          setData({ ...data, repeatEvery: "monthly" });
                        } else if (findPriority.value == "yearly") {
                          setData({ ...data, repeatEvery: "yearly" });
                        }
                      }}
                    />
                  </div>
                  {["daily", "weekly", "monthly"].includes(data.repeatEvery) ? (
                    <label className="label-item mb-2 mt-5 font14">
                      <span
                        className={selectedLanguage == "en" ? "mr-2" : "ml-2"}
                      >
                        <RepeatOnIcon />
                      </span>{" "}
                      {t.workOrders.details_view.repeat_on}
                    </label>
                  ) : null}
                  {["daily", "weekly"].includes(data.repeatEvery) ? (
                    <>
                      <Button.Group basic fluid className="basic-btn">
                        <Button
                          onClick={() => {
                            setData({
                              ...data,
                              daysRepeat: {
                                ...data.daysRepeat,
                                sun: !data.daysRepeat.sun,
                              },
                            });
                          }}
                          className={
                            data.daysRepeat.sun ? "day-btn active" : "day-btn"
                          }
                        >
                          {t.workOrders.details_view.sun}
                        </Button>

                        <Button
                          onClick={() => {
                            setData({
                              ...data,
                              daysRepeat: {
                                ...data.daysRepeat,
                                mon: !data.daysRepeat.mon,
                              },
                            });
                          }}
                          className={
                            data.daysRepeat.mon ? "day-btn active" : "day-btn"
                          }
                        >
                          {t.workOrders.details_view.mon}
                        </Button>

                        <Button
                          onClick={() => {
                            setData({
                              ...data,
                              daysRepeat: {
                                ...data.daysRepeat,
                                tue: !data.daysRepeat.tue,
                              },
                            });
                          }}
                          className={
                            data.daysRepeat.tue ? "day-btn active" : "day-btn"
                          }
                        >
                          {t.workOrders.details_view.tue}
                        </Button>

                        <Button
                          onClick={() => {
                            setData({
                              ...data,
                              daysRepeat: {
                                ...data.daysRepeat,
                                wed: !data.daysRepeat.wed,
                              },
                            });
                          }}
                          className={
                            data.daysRepeat.wed ? "day-btn active" : "day-btn"
                          }
                        >
                          {t.workOrders.details_view.wed}
                        </Button>

                        <Button
                          onClick={() => {
                            setData({
                              ...data,
                              daysRepeat: {
                                ...data.daysRepeat,
                                thu: !data.daysRepeat.thu,
                              },
                            });
                          }}
                          className={
                            data.daysRepeat.thu ? "day-btn active" : "day-btn"
                          }
                        >
                          {t.workOrders.details_view.thu}
                        </Button>

                        <Button
                          onClick={() => {
                            setData({
                              ...data,
                              daysRepeat: {
                                ...data.daysRepeat,
                                fri: !data.daysRepeat.fri,
                              },
                            });
                          }}
                          className={
                            data.daysRepeat.fri ? "day-btn active" : "day-btn"
                          }
                        >
                          {t.workOrders.details_view.fri}
                        </Button>

                        <Button
                          onClick={() => {
                            setData({
                              ...data,
                              daysRepeat: {
                                ...data.daysRepeat,
                                sat: !data.daysRepeat.sat,
                              },
                            });
                          }}
                          className={
                            data.daysRepeat.sat ? "day-btn active" : "day-btn"
                          }
                        >
                          {t.workOrders.details_view.sat}
                        </Button>
                      </Button.Group>

                      {dayFlag ? (
                        <div className="label-error">
                          {t.common.required_field}
                        </div>
                      ) : (
                        ""
                      )}
                    </>
                  ) : ["monthly"].includes(data.repeatEvery) ? (
                    <Dropdown
                      style={{ margin: "0 5px" }}
                      fluid
                      selection
                      noResultsMessage={t.common.no_results_found}
                      className="week-dropdown"
                      options={options.monthsDays}
                      value={data.repeatMonthDay}
                      placeholder={t.workOrders.details_view.select_day}
                      onChange={onValuesChange("repeatMonthDay")}
                    />
                  ) : null}

                  <Form.Field>
                    <label className="label-item font14 mt-5">
                      <span
                        className={selectedLanguage == "en" ? "mr-2" : "ml-2"}
                      >
                        <EndSchedualIcon />
                      </span>{" "}
                      {t.workOrders.details_view.ends}
                    </label>

                    <Form>
                      <div className="change-radio-icon-color d-flex align-items-center side-dropdown">
                        <div>
                          {selectedLanguage === "ar" ? (
                            <div className="d-flex align-items-center">
                              <Radio
                                name="radioGroup"
                                value="on"
                                checked={data.endType === "on"}
                                onChange={onValuesChange("endType")}
                              />
                              <span className="mr-2">
                                {t.workOrders.details_view.on}
                              </span>
                            </div>
                          ) : (
                            <Radio
                              label={
                                <label className="mb-0">
                                  {t.workOrders.details_view.on}
                                </label>
                              }
                              name="radioGroup"
                              value="on"
                              checked={data.endType === "on"}
                              onChange={onValuesChange("endType")}
                            />
                          )}
                        </div>

                        <div className="">
                          <div className="position-relative">
                            <DatePicker
                              value={
                                data.endDate ? new Date(data.endDate) : null
                              }
                              style={{
                                borderRadius: "8px",
                                border: "2px solid #E4E6EA",
                              }}
                              minDate={new Date(data.startDate)}
                              onChange={(value) => {
                                if (value && value.valueOf()) {
                                  setValidation({
                                    ...validation,
                                    endDate: {
                                      ...validation.endDate,
                                      error: false,
                                      skipped: true,
                                    },
                                  });

                                  setData({
                                    ...data,

                                    endDate: format(
                                      value.valueOf(),
                                      "yyyy-MM-dd"
                                    ),
                                  });
                                } else {
                                  setValidation({
                                    ...validation,
                                    endDate: {
                                      ...validation.endDate,
                                      error: true,
                                      skipped: true,
                                    },
                                  });

                                  setData({
                                    ...data,
                                    endDate: null,
                                  });
                                }
                              }}
                              showMonthDropdown
                              showYearDropdown
                              placeholder={t.workOrders.form.end_date}
                              className={"active"}
                              weekDays={[
                                t.workOrders.details_view.sun,
                                t.workOrders.details_view.mon,
                                t.workOrders.details_view.tue,
                                t.workOrders.details_view.wed,
                                t.workOrders.details_view.thu,
                                t.workOrders.details_view.fri,
                                t.workOrders.details_view.sat,
                              ]}
                              months={[
                                t.common.january,
                                t.common.february,
                                t.common.march,
                                t.common.april,
                                t.common.may,
                                t.common.june,
                                t.common.july,
                                t.common.august,
                                t.common.september,
                                t.common.october,
                                t.common.november,
                                t.common.december,
                              ]}
                              format="DD/MM/YYYY"
                            ></DatePicker>

                            <img
                              className="position-absolute"
                              style={
                                selectedLanguage == "en"
                                  ? { top: "6px", right: "7px" }
                                  : { top: "6px", left: "7px" }
                              }
                              src={calendar_icon}
                            />
                          </div>
                        </div>
                      </div>
                      {validation?.endDate?.error &&
                      validation?.endDate?.skipped ? (
                        <div className="label-error">
                          {validation?.endDate?.message}
                        </div>
                      ) : (
                        ""
                      )}
                      <div
                        className={`mt-3 change-radio-icon-color d-flex align-items-center side-dropdown ${
                          selectedLanguage && selectedLanguage === "ar"
                            ? ""
                            : "side-dropdown-after"
                        }`}
                      >
                        <div>
                          {selectedLanguage === "ar" ? (
                            <div className="d-flex align-items-center">
                              <Radio
                                name="radioGroup"
                                value="after"
                                checked={data.endType === "after"}
                                onChange={onValuesChange("endType")}
                              />
                              <span className="mr-2">
                                {t.workOrders.details_view.after}
                              </span>
                            </div>
                          ) : (
                            <Radio
                              label={
                                <label className="mb-0">
                                  {t.workOrders.details_view.after}
                                </label>
                              }
                              name="radioGroup"
                              value="after"
                              checked={data.endType === "after"}
                              onChange={onValuesChange("endType")}
                            />
                          )}
                        </div>

                        <div className="d-flex flex-column">
                          <div className="d-flex">
                            <Input
                              autoComplete="new-password"
                              highlightToday={false}
                              type="number"
                              min="0"
                              className="work-input"
                              placeholder={t.workOrders.form.enterNumber}
                              value={data.endRepeat || ""}
                              onChange={onValuesChange("endRepeat")}
                              error={
                                validation?.endRepeat?.error &&
                                validation?.endRepeat?.skipped
                              }
                              onKeyDown={handleKeyDown}
                              onBlur={() => {
                                const isValid = validation.endRepeat.pattern(
                                  data.endRepeat
                                );
                                setValidation((prev) => ({
                                  ...prev,
                                  endRepeat: {
                                    ...prev.endRepeat,
                                    error: !isValid,
                                    skipped: true,
                                  },
                                }));
                              }}
                            />
                            <span
                              className={`label-item font14 mt-1 ${
                                selectedLanguage === "en" ? "ml-3" : "mr-3"
                              }`}
                            >
                              {t.workOrders.title}
                            </span>
                          </div>
                        </div>
                      </div>
                      {validation?.endRepeat?.error &&
                        validation?.endRepeat?.skipped && (
                          <div className="label-error mt-1">
                            {validation?.endRepeat?.message}
                          </div>
                        )}
                      <div className="mt-3 change-radio-icon-color d-flex align-items-center side-dropdown">
                        <div>
                          {selectedLanguage === "ar" ? (
                            <div className="d-flex align-items-center">
                              <Radio
                                name="radioGroup"
                                value="never"
                                checked={data.endType === "never"}
                                onChange={onValuesChange("endType")}
                              />
                              <span className="mr-2">
                                {t.workOrders.details_view.never}
                              </span>
                            </div>
                          ) : (
                            <Radio
                              label={
                                <label className="mb-0">
                                  {t.workOrders.details_view.never}
                                </label>
                              }
                              name="radioGroup"
                              value="never"
                              checked={data.endType === "never"}
                              onChange={onValuesChange("endType")}
                            />
                          )}
                        </div>
                      </div>
                    </Form>
                  </Form.Field>
                </>
              ) : (
                ""
              )}
              <Form.Field>
                <label
                  className={`label-item ${
                    data?.orderType === "scheduled" ? "mt-5" : ""
                  }`}
                >
                  {" "}
                  {t.workOrders.details_view.estimation_time}
                </label>

                <Input
                  autoComplete="new-password"
                  type="number"
                  min="0"
                  fluid
                  placeholder={t.workOrders.form.time_placeholder}
                  value={data.estimationTime}
                  onChange={onValuesChange("estimationTime")}
                  onKeyDown={(evt) => handleKeyDown(evt)}
                >
                  <input />

                  <span
                    className={`mr-2 d-flex align-items-center justify-content-center ${
                      selectedLanguage == "en" ? "ml-5" : "mr-5"
                    }`}
                    style={{
                      padding: "5px",
                      backgroundColor: "#E9F2FF",
                      color: "#0C66E4",
                    }}
                  >
                    {t.common.hrs}
                  </span>
                </Input>
              </Form.Field>

              <Form.Field>
                <label className="label-item">
                  {t.workOrders.form.labor_cost}
                </label>
                <Input
                  autoComplete="new-password"
                  type="number"
                  min="0"
                  fluid
                  placeholder={t.workOrders.form.add_labor_cost}
                  value={data.laborCost}
                  onChange={onValuesChange("laborCost")}
                  onKeyDown={(evt) => handleKeyDown(evt)}
                >
                  <input />
                  <span
                    className={`mr-2 p-1 d-flex align-items-center justify-content-center ${
                      selectedLanguage == "en" ? "ml-5" : "mr-5"
                    }`}
                    style={{ backgroundColor: "#E9F2FF", color: "#0C66E4" }}
                  >
                    {organisationCurrency}
                  </span>
                </Input>
              </Form.Field>

              <Form.Field>
                <FileUpload
                  onFileChange={onFileChange}
                  name="warrantyFile"
                  attachments={attachments}
                  onCertificateFileChange={onCertificateFileChange}
                  setAttachments={setAttachments}
                />
              </Form.Field>

              <Divider />

              <div className="form-button-container">
                <button
                  onClick={() =>
                    initChange
                      ? setNewCancelModal(initChange && true)
                      : cancelHandler()
                  }
                  className="form-action-button"
                >
                  {t.assets.form.cancel}
                </button>

                <button
                  onClick={createNewWorkOrderHandler}
                  className="form-action-button blue"
                  disabled={buttonStatus == 1}
                >
                  {t.common.submit}
                </button>
              </div>
            </div>
          </Resizable>
        </>
      </div>
      <div
        className={
          toggled ? "aside-modal-background active" : "aside-modal-background"
        }
        onClick={() => (initChange ? setNewCancelModal(true) : cancelHandler())}
      ></div>

      {/* success modal */}

      <SuccessModal
        closeCallback={async () => {
          const currentPathname = window.location.pathname;
          setRefresh(!refresh);
          if (currentPathname == "/work-orders") {
            setSearchTitle("");
            setSelectedDates([]);
            setFilterOptions({
              serialNumber: "",

              people: [],
              teams: [],
              customers: [],
              locations: [],
              createdByUser: [],

              status: [],
              priority: [],
              categories: [],
            });

            if (initData?.length == 0) {
              const fetchData = await dispatch(
                fetchWOInitData({
                  organisationId,
                  role: roleData,
                  allIds,
                  currentUserId,
                })
              );
            }
          }
        }}
      >
        <span>{t.workOrders.form.success.msg}</span>
      </SuccessModal>

      {/* cancel modal */}
      <NewCancelModal
        yesCallback={() => {
          cancelHandler();
        }}
        title={t.parts.form.changes}
      >
        <p style={{ lineHeight: "24px" }}> {t.common.cancel_2statement}</p>
      </NewCancelModal>

      <Modal
        size="large"
        open={addPartModal}
        className="assets-modal part-list-modal"
        style={{ height: "770px" }}
        onClose={() => {
          handleClosePartModal();
        }}
      >
        <div className="header-container">
          <span className="assets-header">
            {t.workOrders.form.add_part_set}
          </span>
          <div>
            <span
              className="c-pointer text-right"
              onClick={() => {
                handleClosePartModal();
              }}
            >
              <CloseIcon />
            </span>
          </div>
        </div>

        <Modal.Content className="mt-2">
          <div style={{ gap: "10px" }} className="d-flex">
            <Dropdown
              placeholder={t.parts.form.inv_loc}
              style={{ minWidth: "300px", padding: "6px 2px 1px 12px" }}
              className={
                selectedLanguage == "en" ? "loc-dropdown" : "loc-dropdown wos"
              }
              selection
              noResultsMessage={t.common.no_results_found}
              search
              clearable
              options={options.inv_location}
              value={selectedInvLoc}
              onChange={(e, value) => {
                setSelectedInvLoc(value?.value);
              }}
            />
            <div
              style={{ width: "-webkit-fill-available" }}
              className="set-input"
            >
              <span className="search-svg-set">
                <SearchIcon />
              </span>
              <Input
                style={{ width: "-webkit-fill-available" }}
                placeholder={t.workOrders.form.search_parts}
                value={partSearchTitle}
                onChange={(e) => setPartSearchTitle(e.target.value)}
              />
            </div>
          </div>
          {loading ? (
            <Dimmer active inverted>
              <Loader inverted content={t.common.loading} />
            </Dimmer>
          ) : (
            <>
              <div style={{}} className=" mt-5  bulk-upload-table">
                <DataTable
                  className="wo-table-div"
                  conditionalRowStyles={conditionalRowStyles}
                  columns={[
                    {
                      name: (
                        <div className={selectedLanguage == "en" ? "" : "mt-3"}>
                          <Checkbox
                            checked={areAllSelected}
                            disabled={partsData?.every(
                              (item) => item?.disable == true
                            )}
                            onChange={(e, value) =>
                              handleSelectAll(value.checked)
                            }
                          />
                        </div>
                      ),
                      width: "50px",
                      cell: (row) => (
                        <div className="d-flex align-items-center">
                          <div>
                            <Checkbox
                              checked={row?.selectedPart}
                              disabled={row?.disable}
                              className="set-checkbox-gery"
                              onChange={(e, value) => {
                                if (!row?.disable) {
                                  handleRowSelected(
                                    row,
                                    value.checked,
                                    selectedTablePartData
                                  );
                                }
                              }}
                            />
                          </div>
                        </div>
                      ),
                    },
                    {
                      minWidth: "100px",
                      name: t.workOrders.table.ID,

                      sortField: "name",
                      sortable: true,
                      cell: (row) => (
                        <div className="d-flex align-items-center">
                          <div>
                            <span className="id-text-style">
                              {row?.countId ? row?.countId : ""}
                            </span>
                          </div>
                        </div>
                      ),
                    },
                    {
                      minWidth: "240px",
                      name: t.workOrders.details_view.name,

                      cell: (row) => (
                        <div className="d-flex align-items-center">
                          <div>
                            <span className="id-text-style">
                              {row?.name
                                ? DisplayFormattedText(
                                    row?.name,
                                    selectedLanguage == "en" ? 23 : 20
                                  )
                                : ""}
                            </span>
                          </div>
                        </div>
                      ),
                    },
                    {
                      minWidth: "200px",
                      name: t.parts.form.inv_loc,

                      cell: (row) => (
                        <div className="d-flex align-items-center">
                          <div>
                            <span className="id-text-style">
                              {row?.location_name
                                ? DisplayFormattedText(
                                    row?.location_name,
                                    selectedLanguage == "en" ? 20 : 18
                                  )
                                : ""}
                            </span>
                          </div>
                        </div>
                      ),
                    },
                    {
                      minWidth: "200px",
                      name: t.assets.title,
                      cell: (row) => {
                        const locationData = row?.asset;
                        if (locationData?.length > 0) {
                          const firstLocation = locationData[0]?.name;
                          const remainingCount = locationData.length - 1;
                          const remainingLocations = locationData
                            .slice(1)
                            .map((location) => location?.name)
                            .join(", ");
                          const displayText =
                            remainingCount > 0
                              ? `${firstLocation} ... +${remainingCount}`
                              : firstLocation;
                          return (
                            <span
                              title={locationData
                                .map((location) => location?.name)
                                .join(", ")}
                            >
                              {displayText}
                            </span>
                          );
                        } else {
                          return "";
                        }
                      },
                    },
                    {
                      minWidth: "150px",
                      name: t.parts.form.avl_qty,
                      right: true,
                      cell: (row) => (
                        <div className="d-flex align-items-center">
                          <div>
                            <span className="id-text-style">
                              {row?.type == "Set" ? (
                                row?.status == "outOfStock" ? (
                                  <div
                                    style={{
                                      color: "#788CA5",
                                      width: "103px",
                                      textAlign: "end",
                                      fontWeight: 400,
                                    }}
                                  >
                                    {t.parts.status.out_of_stock}
                                  </div>
                                ) : (
                                  <div
                                    style={{
                                      color: "black",
                                      width: "103px",
                                      textAlign: "end",
                                      fontWeight: 400,
                                    }}
                                  >
                                    {t.parts.status.inStock}
                                  </div>
                                )
                              ) : row?.status == "outOfStock" ? (
                                <div
                                  style={{
                                    color: "#788CA5",
                                    width: "103px",
                                    textAlign: "end",
                                    fontWeight: 400,
                                  }}
                                >
                                  {t.parts.status.out_of_stock}
                                </div>
                              ) : row?.availableQty ? (
                                <>
                                  <div className="id-text-style d-flex align-items-center">
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <span
                                        style={{
                                          textAlign: "end",
                                          minWidth: "65px",

                                          display: "inline-block",
                                        }}
                                      >
                                        {row?.availableQty
                                          ? row?.availableQty
                                          : 0}
                                      </span>
                                      <span
                                        className={`align-items-center justify-content-center ${
                                          selectedLanguage === "en"
                                            ? "ml-2"
                                            : "mr-2"
                                        }`}
                                        style={{
                                          backgroundColor: "#E9F2FF",
                                          color: "#0C66E4",

                                          padding: "9px 3px 9px 2px",
                                          fontSize: "14px",
                                          minWidth:
                                            selectedLanguage == "en"
                                              ? "33px"
                                              : "48px",
                                          display: "inline-block",
                                          textAlign: "center",
                                        }}
                                      >
                                        {row?.unitType
                                          ? HandleUnitType(row?.unitType)
                                          : null}
                                      </span>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                0
                              )}
                            </span>
                          </div>
                        </div>
                      ),
                    },
                    {
                      minWidth: "150px",
                      name: t.parts.form.unit_cost,
                      right: true,

                      cell: (row) => (
                        <div className="id-text-style d-flex align-items-center">
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <span
                              style={{
                                textAlign: "end",
                                minWidth: "65px",

                                display: "inline-block",
                              }}
                            >
                              {row?.unitCost ? row?.unitCost : 0}
                            </span>
                            <span
                              className={`align-items-center justify-content-center ${
                                selectedLanguage === "en" ? "ml-2" : "mr-2"
                              }`}
                              style={{
                                backgroundColor: "#E9F2FF",
                                color: "#0C66E4",
                                minWidth: "33px",
                                padding: "9px 3px 9px 2px",
                                fontSize: "14px",
                                display: "inline-block",
                              }}
                            >
                              {organisationCurrency}
                            </span>
                          </div>
                        </div>
                      ),
                    },
                  ]}
                  data={getPaginatedData(partsData)}
                  fixedHeader
                  customStyles={customStyles}
                  onRowClicked={(row) => {
                    if (!row.disable) {
                      handleRowSelected(
                        row,
                        row?.selectedPart ? false : true,
                        selectedTablePartData
                      );
                    }
                  }}
                  noDataComponent={<span>{t.sets.no_data_wo}</span>}
                />
              </div>

              {partsData?.length > 0 && (
                <div className="d-flex justify-content-end">
                  <Pagination
                    ofString={t.common.of}
                    total={partsData.length}
                    prevItem={
                      selectedLanguage === "en" ? <ArrowLeft /> : <ArrowRight />
                    }
                    nextItem={
                      selectedLanguage === "en" ? <ArrowRight /> : <ArrowLeft />
                    }
                    page={currentPage}
                    limit={pageSize}
                    handleNext={() => {
                      setCurrentPage(currentPage + 1);
                    }}
                    handlePrevious={() => {
                      setCurrentPage(currentPage - 1);
                    }}
                    parts={true}
                  />
                </div>
              )}
            </>
          )}
        </Modal.Content>
        {!loading && (
          <div
            className={`modal-button-container ${
              selectedLanguage == "en" ? "mr-8" : "ml-10"
            }`}
          >
            <button
              className="modal-form-action-button black"
              onClick={() => {
                handleClosePartModal();
              }}
            >
              {t.assets.form.cancel}
            </button>

            <button
              onClick={() => handleAddPartData()}
              className="modal-form-action-button light-blue"
            >
              {t.common.add}
            </button>
          </div>
        )}
      </Modal>

      {/* on the go popups */}
      <AddCustomerModal
        toggled={addCustomerModal}
        untoggle={() => {
          toggleAddCustomerModal(false);
        }}
        returnAddCustomer={returnAddCustomer}
        title={customerText}
      />

      <AddLocationModal
        toggled={addLocationModal}
        untoggle={() => {
          toggleAddLocationModal(false);
        }}
        customer={data.customer}
        returnAddLocation={returnAddLocation}
        title={locationText}
      />

      <AddPeopleModal
        toggled={addPeopleModal}
        untoggle={() => {
          toggleAddPeopleModal(false);
        }}
        returnAddPeople={returnAddPeople}
        title={peopleText}
      />

      <AddTeamModal
        toggled={addTeamModal}
        untoggle={() => {
          toggleAddTeamModal(false);
        }}
        returnAddTeam={returnAddTeam}
        title={teamText}
      />

      <AddProcedureModal
        toggled={addProcedureModal}
        untoggle={() => {
          toggleAddProcedureModal(false);
        }}
        returnAddProcedure={returnAddProcedure}
        title={procedureText}
        cloneList={[]}
      />

      <AddAssetModal
        toggled={addAssetModal}
        untoggle={() => {
          toggleAddAssetModal(false);
        }}
        returnAddAsset={returnAddAsset}
        title={assetText}
      />
    </>
  );
}

export default Add;
