import React, { useEffect, useRef, useState } from "react";
import Moment from "react-moment";
import Tooltip from "react-tooltip";
import ReactDOM from "react-dom";

import {
  Divider,
  Grid,
  Image,
  Image as SemanticImage,
  Icon,
  Modal,
  Input,
  GridColumn,
  TextArea,
  Form,
  Loader,
} from "semantic-ui-react";

import {
  addWOAttachments,
  deleteWOAttachments,
  getActiveUsersByRole,
  getAllSchedualWOList,
  getAllTeams,
  getWOByDetails,
  IMAGES_URL,
  updateCloseNotes,
  updateWorkOrderAssign,
  updateWorkOrderPrimaryAssign,
  uploadFile,
} from "../../../config/functions";
import { updateWorkOrderStatus } from "../../../config/functions";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectTranslations } from "../../../config/i18n/slice";
import { LetteredAvatarImageUrl } from "../../../components/common/userProfilePlaceholder";
import { usePermissionsSimplified } from "../../../../hooks/usePermissionsSimplified";
import {
  AddIconWithCircle,
  AreaDetailsIcon,
  ArrowDetailsIcon,
  Attachment,
  CategortDetailsIcon,
  CloseIcon,
  CloseIconWO,
  CloseNoteIcon,
  DateDetailsIcon,
  DeleteIcon,
  DetailsAssetIcon,
  DuedateIcon,
  EndSchedualIcon,
  FilesIcon,
  FlagDetailsIcon,
  FlagwithFilled,
  LocationDetailsIcon,
  OrderTypeIcon,
  PrimaryIcon,
  RepeatIcon,
  RepeatOnIcon,
  SchedualWOIcon,
  SchoolIcon,
  SearchIcon,
  SecondaryIcon,
  ShowInstanceIcon,
  Success,
  TeamsIcon,
} from "../../../../Assets/Icons/svg";

import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { scrollToError } from "../../../../utilFunctions/scrollToError";
import moment from "moment";
import { format } from "date-fns";
import useDisplayFormattedText from "../../../../hooks/useDisplayFormattedText";
import AssigneeModal from "./detailsPageComponents/assigneeModal";
import StatusChangeModal from "./detailsPageComponents/statusChangeModal";
import ShowInstance from "./detailsPageComponents/showInstance";
import { useDropzone } from "react-dropzone";
import RenderFiles from "../../../components/common/RenderFiles";

const FileUpload = ({
  name = "warrantyFile",
  onCertificateFileChange,
  totalFiles,
}) => {
  const fileTypes = {
    images: [
      "image/jpeg",
      "image/jpg",
      "image/png",
      "image/gif",
      "image/svg+xml",
      "image/webp",
      "image/heic",
    ],
    documents: [
      "text/plain",
      "application/pdf",
      "text/csv",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ],
    video: ["video/mp4", "video/quicktime", "video/webm", "video/hevc"],
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      const allowedFileTypes =
        name === "image"
          ? [...fileTypes.images, ...fileTypes.video]
          : [...fileTypes.images, ...fileTypes.documents, ...fileTypes.video];

      // Validate file types
      const invalidFiles = acceptedFiles.filter(
        (file) => !allowedFileTypes.includes(file.type)
      );

      // Show toast if there are invalid files
      if (invalidFiles?.length > 0) {
        toast.error(t.common.invalid_msg);
        return;
      }
      let totalFile = totalFiles + acceptedFiles?.length;
      let totalAttachment = organisationImage?.numberOfAttachments
        ? organisationImage?.numberOfAttachments
        : 10;
      let totalSize = organisationImage?.attachmentsSize
        ? organisationImage?.attachmentsSize
        : 200;
      if (name !== "image" && totalFile > totalAttachment) {
        toast.error(
          `${t.common.number_error_1} ${totalAttachment} ${t.common.number_error_2}`
        );
        return;
      }
      const validFiles = acceptedFiles.filter((file) => {
        const isImage = fileTypes.images.includes(file.type);
        const isDocument = fileTypes.documents.includes(file.type);
        const isVideo = fileTypes.video.includes(file.type);

        const validSize =
          (isVideo || isImage || isDocument) &&
          file.size < totalSize * 1024 * 1024;

        if (!validSize) {
          toast.error(
            `${t.common.file_upload_valid} ${totalSize} ${t.common.file_upload_second}`
          );
          return false;
        }
        return true;
      });

      onCertificateFileChange(validFiles, name);
    },
  });

  const t = useSelector(selectTranslations);
  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return "en";
    }
  });

  const organisationImage = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation;
    } else {
      return null;
    }
  });

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      {
        <div className="detail-file-container d-flex align-items-center justify-content-between">
          <div className="d-flex">
            <span className="mt-1">
              <FilesIcon height="24" width="24" />
            </span>
            <div className="pl-4 pr-4 d-flex align-items-center">
              <p className="p1 m-0">{t.common.add_files}</p>
            </div>
          </div>
          <div>
            <AddIconWithCircle />
          </div>
        </div>
      }
    </div>
  );
};

const Details = ({
  startDue,
  data,
  negative,
  untoggle,
  setRefresh,
  refresh,
  setFilterOptions,
  setSelectedDates,
  setSearchTitle,
  setModalWidth,
  setActiveIndex,
  setTotalPartCount,
  totalPartCount,
}) => {
  const t = useSelector(selectTranslations);
  const { checkForLinkPermission } = usePermissionsSimplified();
  const organisationCurrency = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.currency;
    } else {
      return "JOD";
    }
  });

  

  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return "en";
    }
  });

  let allQtyOptions = [
    {
      key: 0,
      value: "pieces",
      text: t.parts.qty_unit.piece,
    },
    {
      key: 1,
      value: "items",
      text: t.parts.qty_unit.items,
    },
    {
      key: 2,
      value: "units",
      text: t.parts.qty_unit.unit,
    },
    {
      key: 3,
      value: "liters (L)",
      text: selectedLanguage == "en" ? "l" : "لتر",
    },
    {
      key: 4,
      value: "gallons (gal)",
      text: selectedLanguage == "en" ? "gal" : "جالون",
    },
    {
      key: 5,
      value: "cubic meters (m³)",
      text: selectedLanguage == "en" ? "m³" : "م³",
    },
    {
      key: 6,
      value: "cubic feet (ft³)",
      text: selectedLanguage == "en" ? "ft³" : "ق³",
    },
    {
      key: 7,
      value: "barrels",
      text: t.parts.vol_unit.barrels,
    },
    {
      key: 8,
      value: "kilograms (kg)",
      text: selectedLanguage == "en" ? "kg" : "كجم",
    },
    {
      key: 9,
      value: "pounds (lb)",
      text: selectedLanguage == "en" ? "lb" : "رطل",
    },

    {
      key: 10,
      value: "tons",
      text: t.parts.mass_unit.tons,
    },
    {
      key: 11,
      value: "metric tonnes",
      text: t.parts.mass_unit.m_tons,
    },
  ];

  const addWOFiles = async (files) => {
    const response = await addWOAttachments(files, data?.id);

    if (response && response?.status === 200) {
      setIsEditingFile(false);
      setTotalPartCount((prev) => {
        return {
          ...prev,
          files: response?.data?.data?.attachments,
        };
      });
      toast.success(t.workOrders.form.files_updated);
    } else {
      toast.error(t.common.something_wrong);
    }

    setFileLoading(false);
  };

  const deleteFile = async (fileID) => {
    const response = await deleteWOAttachments(fileID, data?.id);

    if (response && response?.status === 200) {
      setIsEditingFile(false);
      setTotalPartCount((prev) => {
        return {
          ...prev,
          files: response?.data?.data?.attachments,
        };
      });
      toast.success(t.workOrders.form.files_deleted);
    } else {
      toast.error(t.common.something_wrong);
    }

    setFileLoading(false);
  };

  const onCertificateFileChange = async (files, name) => {
    setFileLoading(true);
    const uploadedFiles = await Promise.all(
      Array.from(files).map(async (fileObj) => {
        // Use map() instead of forEach()

        try {
          const formData = new FormData();
          formData.append("file", fileObj);

          const uploadResponse = await uploadFile(formData);

          if (uploadResponse?.status === 200) {
            return uploadResponse.data[0]?.id || null; // Return only the ID
          } else {
            toast.error(t.common.something_wrong);
            return { ...fileObj, isUploading: false };
          }
        } catch (error) {
          toast.error(t.common.something_wrong);
          return { ...fileObj, isUploading: false };
        }
      })
    );
    if (uploadedFiles?.length > 0) {
      addWOFiles(uploadedFiles);
    } else {
      setFileLoading(false);
    }
  };

  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });

  const roleData = useSelector((state) => {
    if (state?.auth?.user?.id) {
      return state.auth.user.role.type;
    } else {
      return null;
    }
  });

  const [options, setOptions] = useState({
    people: {
      initOptions: [],
      options: [],
    },
    teams: {
      initOptions: [],
      options: [],
    },
    primaryAssignee: {
      initOptions: [],
      options: [],
    },
  });

  const [isEditing, setIsEditing] = useState(false);
  const [isEditingFile, setIsEditingFile] = useState(false);
  const handleSearchChange = (e) => {
    setSearchTeam(e.target.value);

    if (e.target.value) {
      const filtered = options?.teams?.initOptions?.filter((option) =>
        option.text.toLowerCase().includes(e.target.value.toLowerCase())
      );
      setOptions({
        ...options,
        teams: {
          ...options.teams,
          options: filtered,
        },
      });
    } else {
      setOptions({
        ...options,
        teams: {
          ...options.teams,
          options: options?.teams?.initOptions,
        },
      });
    }
  };
  const editCloseNotes = async (notes) => {
    const response = await updateCloseNotes(notes, data?.id);

    if (response && response?.status === 200) {
      setIsEditing(false);
      toast.success(t.workOrders.form.notes_updated);
    } else {
      toast.error(t.common.something_wrong);
    }
  };

  const handleSearchChangePeople = (e) => {
    setSearchPeople(e.target.value);

    if (e.target.value) {
      const filtered = options?.people?.initOptions?.filter((option) =>
        option.text.toLowerCase().includes(e.target.value.toLowerCase())
      );
      setOptions({
        ...options,
        people: {
          ...options.people,
          options: filtered,
        },
      });
    } else {
      setOptions({
        ...options,
        people: {
          ...options.people,
          options: options?.people?.initOptions,
        },
      });
    }
  };
  const handleSearchChangePrimary = (e) => {
    setSearchPrimary(e.target.value);

    if (e.target.value) {
      const filtered = options?.primaryAssignee?.initOptions?.filter((option) =>
        option.text.toLowerCase().includes(e.target.value.toLowerCase())
      );
      setOptions({
        ...options,
        primaryAssignee: {
          ...options.primaryAssignee,
          options: filtered,
        },
      });
    } else {
      setOptions({
        ...options,
        primaryAssignee: {
          ...options.primaryAssignee,
          options: options?.primaryAssignee?.initOptions,
        },
      });
    }
  };

  const handleTeamSelect = async (team) => {
    let teams = [...selectedTeams];

    const isSelected = selectedTeams?.some(
      (selected) => selected.key === team.key
    );

    if (isSelected) {
      // Deselect by filtering out the team
      teams = teams.filter((selected) => selected.key !== team.key);
    } else {
      // Select by adding the team
      teams = [...teams, team];
    }

    await handleRemoveTeams(teams);
  };
  const handlePrimarySelect = async (team) => {
    let teams = selectedPrimaryAssignee;

    const isSelected = teams?.key === team.key;

    if (isSelected) {
      // Deselect by filtering out the team
      teams = null;
    } else {
      // Select by adding the team
      teams = team;
    }

    await handleRemovePrimaryAssignee(teams);
  };

  const handlePeopleSelect = async (team) => {
    let teams = [...selectedPeople];

    const isPrimaryAssignee = selectedPrimaryAssignee?.key === team.key;

    if (isPrimaryAssignee) {
      toast.error(`${team?.text} is already added as the Primary Assignee.`);
      return;
    }

    const isSelected = teams?.some((selected) => selected.key === team.key);

    if (isSelected) {
      teams = teams.filter((selected) => selected.key !== team.key);
    } else {
      teams = [...teams, team];
    }

    await handleRemovePeople(teams);
  };
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownTeamsOpen(false);
      setDropdownPeopleOpen(false);
      setDropdownPrimaryOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [dropdownTeamsOpen, setDropdownTeamsOpen] = useState(false);
  const [dropdownPeopleOpen, setDropdownPeopleOpen] = useState(false);
  const [dropdownPrimaryOpen, setDropdownPrimaryOpen] = useState(false);
  const { DisplayFormattedText } = useDisplayFormattedText();
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [searchTeam, setSearchTeam] = useState("");
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [hoveredPrimaryIndex, setHoveredPrimaryIndex] = useState(null);
  const [searchPrimary, setSearchPrimary] = useState("");
  const [selectedPeople, setSelectedPeople] = useState([]);
  const [selectedPrimaryAssignee, setSelectedPrimaryAssignee] = useState({});
  const [searchPeople, setSearchPeople] = useState([]);
  const [schedualWOdata, setschedualWOdata] = useState({
    completedWO: [],
    nonCompletedWO: [],
  });
  const history = useHistory();
  const [openModal, setModal] = useState(false);
  const [approveModal, setApproveModal] = useState(false);
  const [onHoldFlag, setOnHoldFlag] = useState(false);
  const [closeFlag, setCloseFlag] = useState(false);
  const [openFlag, setOpenFlag] = useState(false);
  const [reOpenFlag, setReOpenFlag] = useState(false);
  const [totalCostTooltip, setTotalCostTooltip] = useState(false);
  const [viewSchedualPopup, setViewSchedualPopup] = useState(false);
  const [inProgressFlag, setInProgressFlag] = useState(false);
  const [inCancleFlag, setInCancleFlag] = useState(false);
  const [assignModal, setAssignModal] = useState(false);
  const [instancesModal, setInstancesModal] = useState(false);
  const [instancesModalLoading, setInstancesModalLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [fileLoading, setFileLoading] = useState(false);
  const [DeleteSuccessModal, setDeleteSuccessModal] = useState(false);

  const dropdownRef = useRef(null);
  const [assignData, setAssignData] = useState({
    people: [],
    teams: [],
  });

  const [isDropdownVisible, setDropdownVisible] = useState(false);

  const toggleDropdown = () => {
    if (
      ["cancelled"].includes(data?.attributes?.status) ||
      !["super_admin", "admin", "limited_admin"].includes(roleData)
    ) {
      if (
        roleData == "user" &&
        ["inProgress", "onHold"].includes(data?.attributes?.status) &&
        checkValidUser()
      ) {
        setDropdownVisible(!isDropdownVisible);
      } else {
        setDropdownVisible(false);
      }
    } else {
      setDropdownVisible(!isDropdownVisible);
    }
  };

  const getTotalParts = async () => {
    let res = await getWOByDetails(organisationId, data?.id);
    if (res?.length > 0) {
      let partCount =
        res[0]?.attributes?.part?.length + res[0]?.attributes?.set?.length;

      setTotalPartCount({
        partCount:
          res[0]?.attributes?.part?.length + res[0]?.attributes?.set?.length,
        additionalCost: res[0]?.attributes?.additionalCost,
        estimationTime: res[0]?.attributes?.estimationTime,
        actualTime: res[0]?.attributes?.actualTime,
        totalCost: res[0]?.attributes?.totalCost,
        laborCost: res[0]?.attributes?.laborCost,
        subTotal: res[0]?.attributes?.subTotal,
        people: res[0]?.attributes?.people,
        teams: res[0]?.attributes?.teams,
        totalLaborCost: res[0]?.attributes?.laborTotalCost,
        primaryAssignee: res[0]?.attributes?.primaryAssignee,
        closeOutNotes: res[0]?.attributes?.closeOutNotes,
        files: res[0]?.attributes?.attachments,
      });

      if (res[0]?.attributes?.teams?.data?.length > 0) {
        const initialSelectedTeams = res[0]?.attributes?.teams?.data?.map(
          (team) => {
            const the_image = team?.attributes?.images?.data?.attributes?.url
              ? team?.attributes?.images?.data?.attributes?.url
              : LetteredAvatarImageUrl(team?.attributes?.name);

            return {
              key: team.id,
              value: team.id,
              text: team?.attributes?.name,
              image: { avatar: true, src: the_image, size: "tiny" },
            };
          }
        );

        setSelectedTeams(initialSelectedTeams);
      } else {
        setSelectedTeams([]);
      }

      if (res[0]?.attributes?.people?.data?.length > 0) {
        const initialSelectedTeams = res[0]?.attributes?.people?.data?.map(
          (team) => {
            const the_image = team?.attributes?.profileImage?.data?.attributes
              ?.url
              ? IMAGES_URL +
                team?.attributes?.profileImage?.data?.attributes?.url
              : LetteredAvatarImageUrl(
                  team?.attributes?.firstName + " " + team?.attributes?.lastName
                );

            return {
              key: team?.id,
              value: team?.id,
              text:
                team?.attributes?.firstName +
                " " +
                team?.attributes?.lastName +
                " (" +
                t.people.roles[team?.attributes?.role?.data?.attributes?.name] +
                ")",
              image: { avatar: true, src: the_image, size: "tiny" },
            };
          }
        );

        setSelectedPeople(initialSelectedTeams);
      } else {
        setSelectedPeople([]);
      }

      if (res[0]?.attributes?.primaryAssignee?.data) {
        const team = res[0]?.attributes?.primaryAssignee?.data;

        const the_image = team?.attributes?.profileImage?.data?.attributes?.url
          ? IMAGES_URL + team?.attributes?.profileImage?.data?.attributes?.url
          : LetteredAvatarImageUrl(
              team?.attributes?.firstName + " " + team?.attributes?.lastName
            );

        const initialSelectedTeam = {
          key: team?.id,
          value: team?.id,
          text:
            team?.attributes?.firstName +
            " " +
            team?.attributes?.lastName +
            " (" +
            t.people.roles[team?.attributes?.role?.data?.attributes?.name] +
            ")",
          image: { avatar: true, src: the_image, size: "tiny" },
        };

        setSelectedPrimaryAssignee(initialSelectedTeam);
      } else {
        setSelectedPrimaryAssignee({});
      }
    } else {
    }
  };

  const getAllSchedualWO = async () => {
    const completedWO = [];
    const nonCompletedWO = [];
    setInstancesModal(true);
    setInstancesModalLoading(true);
    const res = await getAllSchedualWOList(
      organisationId,
      data.attributes?.unique_hash
    );

    if (res && res?.status == 200) {
      res.data.data.forEach((workOrder) => {
        const { attributes } = workOrder;
        const formattedWO = {
          date: {
            day: new Date(attributes.dueDate).getDate(),
            month: new Date(attributes.dueDate).toLocaleString("en-US", {
              month: "short",
            }),
          },
          workOrderNumber: attributes.countId,
          title: attributes.title,
          timestamp: `${moment(attributes.dueDate).format("MMMM D, YYYY")} ${
            attributes?.dueTime
              ? moment(attributes?.dueTime, "HH:mm:ss").format("HH:mm")
              : "00:00"
          }`,
        };

        if (attributes.status === "completed") {
          completedWO.push(formattedWO);
        } else {
          nonCompletedWO.push(formattedWO);
        }
      });
    } else {
      toast.error(t.common.something_wrong);
    }

    setInstancesModalLoading(false);

    setschedualWOdata({ completedWO, nonCompletedWO });
  };

  useEffect(() => {
    if (!selectedPrimaryAssignee?.key) {
      fetchPeopleOptions();
    }
    getTotalParts();
    fetchTeamsOptions();
    fetchPrimaryOptions();
  }, []);

  const handleCancel = () => {
    setOpenFlag(false);
    setReOpenFlag(false);
    setApproveModal(false);
    setOnHoldFlag(false);
    setInProgressFlag(false);
    setInCancleFlag(true);
    setModal(true);
    setCloseFlag(false);
  };

  const handleRemoveTeams = async (teams) => {
    let newPeopleData = teams?.map((person) => person?.key);

    let response = await updateWorkOrderPrimaryAssign(
      data?.id,
      null,
      newPeopleData,
      null,
      data?.attributes?.orderType
    );

    if (response && response.status === 200) {
      getTotalParts();
      setSelectedDates([]);
    }
  };

  const handleRemovePeople = async (teams) => {
    let newPeopleData = teams?.map((person) => person?.key);

    let response = await updateWorkOrderPrimaryAssign(
      data?.id,
      null,
      null,
      newPeopleData,
      data?.attributes?.orderType
    );

    if (response && response.status === 200) {
      getTotalParts();
      setSelectedDates([]);
    }
  };

  const handleRemovePrimaryAssignee = async (teams) => {
    let people = [...selectedPeople];

    people = people
      ?.filter((person) => person?.key !== teams?.key)
      .map((person) => person?.key);

    let response = await updateWorkOrderPrimaryAssign(
      data?.id,
      teams?.key ? teams.key : null,
      null,
      teams?.key ? people : [],
      data?.attributes?.orderType,
      true
    );

    if (response && response.status === 200) {
      getTotalParts();
      setSelectedDates([]);
    }
  };

  const handleClose = () => {
    setOpenFlag(false);
    setReOpenFlag(false);
    setApproveModal(false);
    setOnHoldFlag(false);
    setInProgressFlag(false);
    setInCancleFlag(false);
    setCloseFlag(true);
    setModal(true);
  };

  const handleComplete = () => {
    setOpenFlag(false);
    setReOpenFlag(false);
    setApproveModal(true);
    setCloseFlag(false);
    setOnHoldFlag(false);
    setInProgressFlag(false);
    setInCancleFlag(false);
    setModal(true);
  };
  const handleReopen = () => {
    setOpenFlag(false);
    setApproveModal(false);
    setCloseFlag(false);
    setOnHoldFlag(false);
    setInProgressFlag(false);
    setInCancleFlag(false);
    setModal(true);
    setReOpenFlag(true);
  };
  const handleHold = () => {
    setOpenFlag(false);
    setReOpenFlag(false);
    setApproveModal(false);
    setCloseFlag(false);
    setOnHoldFlag(true);
    setInProgressFlag(false);
    setInCancleFlag(false);
    setModal(true);
  };
  const handleOpen = () => {
    setOpenFlag(true);
    setApproveModal(false);
    setReOpenFlag(false);
    setCloseFlag(false);
    setOnHoldFlag(false);
    setInProgressFlag(false);
    setInCancleFlag(false);
    setModal(true);
  };

  const currentUserId = useSelector((state) => {
    if (state?.auth?.user?.id) {
      return state.auth.user.id;
    } else {
      return null;
    }
  });

  const [validation, setValidation] = useState({
    people: {
      error: false,
      pattern: (code) => code != "",
      skipped: false,
      message: t.common.required_field,
    },
  });

  const fetchTeamsOptions = async () => {
    const fetchData = await getAllTeams(
      organisationId,
      "",
      1,
      99999,
      [],
      [],
      [],
      !(roleData === "super_admin")
        ? data?.attributes?.location?.data?.id
        : data?.attributes?.location?.data?.id
    );

    if (fetchData.response) {
      let the_options = fetchData.response.map((el) => {
        const the_image = el?.attributes?.images?.data?.attributes?.url
          ? el?.attributes?.images?.data?.attributes?.url
          : LetteredAvatarImageUrl(el.attributes.name);

        return {
          key: el.id,
          value: el.id,
          text: el.attributes.name,
          image: { avatar: true, src: the_image, size: "tiny" },
        };
      });
      setOptions((prevOptions) => ({
        ...prevOptions,
        teams: {
          initOptions: the_options,
          options: the_options,
        },
      }));

      return the_options;
    }
  };

  const onValuesChange = (name, actualValue) => (event, value) => {
    let currentFieldValue = "";
    const dropdown_fields = ["people", "teams"];

    if (dropdown_fields.includes(name)) {
      currentFieldValue = actualValue || value.value;
      let resulteNewData = {
        ...assignData,
        [name]: actualValue || value.value,
      };

      setAssignData(resulteNewData);
    }
    let fieldValidation = validation[name];
    if (fieldValidation) {
      let currentValidation = {
        ...validation,
        [name]: {
          ...fieldValidation,
          error: fieldValidation.pattern(currentFieldValue) ? false : true,
        },
      };
      setValidation(currentValidation);
    }
  };

  const fetchPeopleOptions = async () => {
    const fetchData = await getActiveUsersByRole(
      ["user", "limited_admin", "admin"],
      organisationId,
      !(roleData === "super_admin")
        ? data?.attributes?.location?.data?.id
        : data?.attributes?.location?.data?.id
    );

    if (fetchData.data) {
      // Filter out the primary assignee by ID
      const filteredData = fetchData?.data?.filter(
        (el) => el.id !== selectedPrimaryAssignee?.key
      );

      const the_options = filteredData?.map((el) => {
        const the_image = el.profileImage?.url
          ? IMAGES_URL + el.profileImage?.url
          : LetteredAvatarImageUrl(el?.firstName + " " + el.lastName);

        let teams_string_array = el?.teams?.map((one) => one.name);

        return {
          key: el.id,
          value: el.id,
          text:
            el.firstName +
            " " +
            el.lastName +
            " (" +
            t.people.roles[el.role.name] +
            ")",
          description: `${teams_string_array.join(" , ")}`,
          image: { avatar: true, src: the_image, size: "tiny" },
        };
      });

      setOptions((prevOptions) => ({
        ...prevOptions,
        people: {
          initOptions: the_options,
          options: the_options,
        },
      }));

      return the_options;
    }
  };

  useEffect(() => {
    if (selectedPrimaryAssignee?.key) {
      fetchPeopleOptions();
    }
  }, [selectedPrimaryAssignee]);

  const fetchPrimaryOptions = async () => {
    const fetchData = await getActiveUsersByRole(
      ["user", "limited_admin", "admin"],
      organisationId,
      !(roleData === "super_admin")
        ? data?.attributes?.location?.data?.id
        : data?.attributes?.location?.data?.id
    );

    if (fetchData.data) {
      let the_options = fetchData.data.map((el) => {
        const the_image = el.profileImage?.url
          ? IMAGES_URL + el.profileImage?.url
          : LetteredAvatarImageUrl(el?.firstName + " " + el.lastName);
        let teams_string_array = el?.teams?.map((one) => one.name);
        return {
          key: el.id,
          value: el.id,
          text:
            el.firstName +
            " " +
            el.lastName +
            " (" +
            t.people.roles[el.role.name] +
            ")",

          description: `${teams_string_array.join(" , ")}`,
          image: { avatar: true, src: the_image, size: "tiny" },
        };
      });
      setOptions((prevOptions) => ({
        ...prevOptions,
        primaryAssignee: {
          initOptions: the_options,
          options: the_options,
        },
      }));

      return the_options;
    }
  };
  const calculateCurrentCount = (total, percentage = 0) => {
    const numerator = total * (Number(percentage) / 100); // Calculate current count

    return `(${numerator}/${total}) ${percentage}%`;
  };
  const handleAssign = async () => {
    let currentValidation = {
      ...validation,
      people: {
        ...validation.people,
        error: validation.people.pattern(assignData.people) ? false : true,
        skipped: true,
      },
    };
    setValidation(currentValidation);
    const validationToArray = Object.entries(currentValidation);
    const errorsInData = validationToArray.some((el) => el[1].error);

    if (!errorsInData) {
      const response = await updateWorkOrderAssign(
        data.id,
        assignData.teams,
        assignData.people,
        data.attributes.orderType
      );
      if (response && response.status == 200) {
        toast.success(t.workOrders.WO_assign_success);
        untoggle();
        setActiveIndex(0);
        setModalWidth(Math.max(window.innerWidth / 2, 514));
        setAssignData({
          teams: [],
          people: [],
        });
        setRefresh(!refresh);

        const currentPathname = window.location.pathname;

        if (currentPathname == "/work-orders") {
          setSearchTitle("");
          setSelectedDates([]);
          setFilterOptions({
            serialNumber: "",

            people: [],
            teams: [],
            customers: [],
            locations: [],
            createdByUser: [],

            status: [],
            priority: [],
            categories: [],
          });
        }
      } else if (response.status == 400) {
        if (response.error && response.error.message) {
          toast.error(response.error.message);
        } else {
          toast.error(t.common.something_wrong);
        }
      } else {
        toast.error(t.common.something_wrong);
        untoggle();
        setActiveIndex(0);
        setModalWidth(Math.max(window.innerWidth / 2, 514));
      }
    } else {
      scrollToError();
    }
  };

  const statusOptions = [
    {
      value: "open",
      color: "blue",
      label: t.workOrders.work_order_status.open,
    },
    {
      value: "reOpen",
      color: "light-purple",
      label: t.workOrders.work_order_status.reOpen,
    },
    {
      value: "cancelled",
      color: "darkgrey",
      label: t.workOrders.work_order_status.cancel,
    },
    {
      value: "inProgress",
      color: "yellow",
      label: t.workOrders.work_order_status.inProgress,
    },
    {
      value: "onHold",
      color: "red",
      label: t.workOrders.work_order_status.onHold,
    },
    {
      value: "inReview",
      color: "green",
      label: t.workOrders.work_order_status.inReview,
    },
    {
      value: "completed",
      color: "darkblue",
      label: t.workOrders.work_order_status.completed,
    },
  ];
  const priorityOptions = [
    {
      value: "none",
      label: t.workOrders.priority_status.none,
      color: null,
    },
    {
      value: "low",
      label: t.workOrders.priority_status.low,

      color: "#4FAB68",
    },
    {
      value: "medium",
      label: t.workOrders.priority_status.medium,

      color: "#E2B203",
    },
    {
      value: "high",
      label: t.workOrders.priority_status.high,

      color: "#D14040",
    },
  ];

  const workOrderPriorityColor = priorityOptions.find(
    (pro) => pro.value == data?.attributes.priority
  );

  const categoryOptions = [
    {
      value: "damage",
      label: t.workOrders.form.category_options[0],
    },
    {
      value: "corrective action Downtime",
      label: t.workOrders.form.category_options[1],
    },
    {
      value: "planned Downtime",
      label: t.workOrders.form.category_options[2],
    },
    {
      value: "unplanned Downtime",
      label: t.workOrders.form.category_options[3],
    },
    {
      value: "inspection",
      label: t.workOrders.form.category_options[4],
    },
    {
      value: "electrical",
      label: t.workOrders.form.category_options[5],
    },
    {
      value: "mechanical",
      label: t.workOrders.work_order_category.Mechanical,
    },
    {
      value: "HVAC",
      label: t.workOrders.work_order_category.HVAC,
    },
    {
      value: "preventive maintenance",
      label: t.workOrders.form.category_options[8],
    },
    {
      value: "project",
      label: t.workOrders.form.category_options[9],
    },
    {
      value: "safety",
      label: t.workOrders.work_order_category.Safety,
    },
    {
      value: "sop",
      label: t.workOrders.form.category_options[11],
    },
  ];

  const categoryStatus = categoryOptions.find(
    (status) => status.value == data?.attributes?.category
  );

  let customerName;

  if (data?.attributes?.customer?.data) {
    customerName = (
      <div
        className="d-flex"
        onClick={() =>
          history.push(
            `/customers?id=${data?.attributes?.customer?.data?.attributes?.countId}`
          )
        }
      >
        <Image
          avatar
          title={data?.attributes.customer?.data?.attributes.name}
          src={LetteredAvatarImageUrl(
            data?.attributes.customer?.data?.attributes?.name
          )}
        />
        <div
          className="work-order-link mt-1"
          basic
          style={{ marginLeft: "4px" }}
        >
          {data?.attributes.customer?.data?.attributes.name}
          {selectedLanguage == "en" ? (
            <Icon name="angle right" />
          ) : (
            <Icon name="angle left" />
          )}
        </div>
      </div>
    );
  } else {
    customerName = t.common.na;
  }

  const checkValidUser = () => {
    if (roleData === "user") {
      if (data?.attributes?.people?.data?.length > 0) {
        return data?.attributes?.people?.data?.some(
          (item) => item?.id === currentUserId
        );
      }

      if (data?.attributes?.teams?.data?.length > 0) {
        return data.attributes.teams.data.some((team) =>
          team?.attributes?.users?.data?.some(
            (user) => user?.id === currentUserId
          )
        );
      }
    }

    return false;
  };

  const handleSave = async () => {
    if ((inCancleFlag || openFlag || reOpenFlag) && !message) {
      toast.error(t.workOrders.submit_error);
      return;
    }
    setLoading(true);
    let res = await updateWorkOrderStatus(
      data.id,
      inCancleFlag
        ? "cancelled"
        : inProgressFlag
        ? "inProgress"
        : reOpenFlag
        ? "reOpen"
        : approveModal
        ? "inReview"
        : closeFlag
        ? "completed"
        : openFlag
        ? "open"
        : "onHold",
      message,
      data?.attributes?.orderType
    );

    setModal(false);
    setMessage("");
    setLoading(false);
    if (res && res.status == 200) {
      window.dispatchEvent(new Event("triggerNotificationRefresh"));
      toast.success(
        inCancleFlag
          ? t.workOrders.success_cancel
          : inProgressFlag
          ? t.workOrders.success_progress
          : reOpenFlag
          ? t.workOrders.success_reOpen
          : approveModal
          ? t.workOrders.success_approve
          : closeFlag
          ? t.workOrders.success_decline
          : openFlag
          ? t.workOrders.success_open
          : t.workOrders.success_hold
      );

      toggleDeleteSuccessModal("close");
    } else if (res.status == 400) {
      if (res?.error && res?.error?.message) {
        toast.error(res?.error.message);
      } else {
        toast.error(t.common.something_wrong);
      }
    } else {
      toast.error(t.common.something_wrong);
      setModal(false);
      setMessage("");
      setLoading(false);
    }
  };
  const toggleDeleteSuccessModal = (data) => {
    setDeleteSuccessModal(!DeleteSuccessModal);
    const currentPathname = window.location.pathname;
    if (data) {
      untoggle();
      setActiveIndex(0);
      setModalWidth(Math.max(window.innerWidth / 2, 514));
      setRefresh(!refresh);

      if (currentPathname == "/work-orders") {
        setSearchTitle("");
        setSelectedDates([]);
        setFilterOptions({
          serialNumber: "",

          people: [],
          teams: [],
          customers: [],
          locations: [],
          createdByUser: [],

          status: [],
          priority: [],
          categories: [],
        });
      }
      setOnHoldFlag(false);
      setInCancleFlag(false);
      setApproveModal(false);
    }
  };

  let assetData = data.attributes?.assets?.data;
  let assetString = "";

  if (assetData?.length > 0) {
    assetString = assetData.map((each) => each.attributes?.name).join(", ");
  } else {
    assetString = t.common.na;
  }
  let addedPartsData = data?.attributes?.report?.data?.attributes?.parts;
  let addedcostData = "";
  let addedpartData = "";

  if (addedPartsData?.length > 0) {
    addedcostData = addedPartsData.map((each, index) => {
      return (
        <div>
          <div className="d-flex align-items-start mt-5">
            <label className="workorder-intro-label work-order-label">
              {t.workOrders.form.added_cost + (index + 1)}
            </label>
            <p className="workorder-intro-value set-width">
              {each?.amount ? `${each.amount}` : 0} {organisationCurrency}
            </p>
          </div>
        </div>
      );
    });
  } else {
    addedcostData = (
      <div>
        <div className="d-flex align-items-start mt-5">
          <label className="workorder-intro-label work-order-label">
            {t.workOrders.form.added_cost}
          </label>
          <p className="workorder-intro-value set-width">{t.common.na}</p>
        </div>
      </div>
    );
  }

  if (addedPartsData?.length > 0) {
    addedpartData = addedPartsData?.map((each, index) => {
      return (
        <div>
          <div className="d-flex align-items-start mt-5">
            <label className="workorder-intro-label work-order-label">
              {t.workOrders.form.added_parts + (index + 1)}
            </label>
            <p className="workorder-intro-value set-width">
              {each?.part?.data?.attributes?.name
                ? each?.part?.data?.attributes?.name
                : t.common.na}
            </p>
          </div>
        </div>
      );
    });
  } else {
    addedpartData = (
      <div>
        <div className="d-flex align-items-start mt-5">
          <label className="workorder-intro-label work-order-label">
            {t.workOrders.form.added_parts}
          </label>
          <p className="workorder-intro-value set-width">{t.common.na}</p>
        </div>
      </div>
    );
  }

  const teamProfiles =
    selectedTeams?.length > 0 ? (
      <>
        {selectedTeams.length < 5 ? (
          selectedTeams.map((team, index) => (
            <div
              key={team.key}
              className={`primary-profile-container ${
                ["open", "reOpen"].includes(data?.attributes?.status)
                  ? "hover-div"
                  : ""
              }`}
              style={{
                marginRight: index !== 0 ? "-11px" : "0",
              }}
            >
              <div
                onMouseEnter={() => setHoveredIndex(index)}
                onMouseLeave={() => setHoveredIndex(null)}
              >
                <Image
                  avatar
                  data-tip
                  data-for={`tooltip-team-${index}`}
                  src={team?.image?.src}
                  style={{
                    width:
                      selectedLanguage === "en" && index !== 0
                        ? "44px"
                        : "34px",
                    height: "34px",
                    right:
                      selectedLanguage === "ar" && index !== 0
                        ? "0px"
                        : index === 0 && selectedLanguage === "en"
                        ? "-8px"
                        : "-9px",
                  }}
                  className={index === 0 ? "img-first" : ""}
                />
                {hoveredIndex === index && (
                  <Tooltip
                    className="custom-primary-tooltip"
                    id={`tooltip-team-${index}`}
                    place="top"
                    effect="solid"
                  >
                    {team.text}
                  </Tooltip>
                )}
              </div>

              <span
                className="close-icon-wo"
                style={{
                  top:
                    index === 0
                      ? selectedLanguage === "ar"
                        ? "-9px"
                        : "-12px"
                      : "-12px",
                  right:
                    index === 0
                      ? selectedLanguage === "ar"
                        ? "3px"
                        : "-7px"
                      : "5px",
                  cursor: "pointer",
                }}
                onClick={(e) => {
                  if (["open", "reOpen"].includes(data?.attributes?.status)) {
                    e.stopPropagation();
                    handleTeamSelect(team);
                  }
                }}
              >
                <CloseIconWO />
              </span>
            </div>
          ))
        ) : (
          <>
            {selectedTeams.slice(0, 4).map((team, index) => (
              <div
                key={team.key}
                className={`primary-profile-container ${
                  ["open", "reOpen"].includes(data?.attributes?.status)
                    ? "hover-div"
                    : ""
                }`}
                style={{
                  marginRight: index !== 0 ? "-11px" : "0",
                }}
              >
                <div
                  onMouseEnter={() => setHoveredIndex(index)}
                  onMouseLeave={() => setHoveredIndex(null)}
                >
                  <Image
                    avatar
                    data-tip
                    data-for={`tooltip-team-${index}`}
                    src={team?.image?.src}
                    style={{
                      // width: index === 0 ? "34px" : "44px",
                      width:
                        selectedLanguage === "en" && index !== 0
                          ? "44px"
                          : "34px",
                      height: "34px",
                      right:
                        selectedLanguage === "ar" && index !== 0
                          ? "0px"
                          : index === 0 && selectedLanguage === "en"
                          ? "-8px"
                          : "-9px",
                    }}
                    className={index === 0 ? "img-first" : ""}
                  />

                  {hoveredIndex === index && (
                    <Tooltip
                      className="custom-primary-tooltip"
                      id={`tooltip-team-${index}`}
                      place="top"
                      effect="solid"
                    >
                      {team.text}
                    </Tooltip>
                  )}
                </div>

                <span
                  className="close-icon-wo"
                  style={{
                    top:
                      index === 0
                        ? selectedLanguage === "ar"
                          ? "-9px"
                          : "-12px"
                        : "-12px",
                    right:
                      index === 0
                        ? selectedLanguage === "ar"
                          ? "3px"
                          : "-7px"
                        : "5px",
                    cursor: "pointer",
                  }}
                  onClick={(e) => {
                    if (["open", "reOpen"].includes(data?.attributes?.status)) {
                      e.stopPropagation();
                      handleTeamSelect(team);
                    }
                  }}
                >
                  <CloseIconWO />
                </span>
              </div>
            ))}

            <div
              onMouseEnter={() => setHoveredIndex("overflowTeam")}
              onMouseLeave={() => setHoveredIndex(null)}
              style={{ position: "relative" }}
            >
              <Image
                key="overflow"
                className="more-image"
                avatar
                src={`https://ui-avatars.com/api/?name=${selectedTeams?.length -
                  4}%2B&background=E4E6EA&bold=true&color=788CA5`}
                style={{
                  width: selectedLanguage === "en" ? "44px" : "34px",
                  height: "34px",
                  right: "-8px",
                }}
                data-tip
                data-for="overflow-tooltip"
              />
              {hoveredIndex === "overflowTeam" &&
                ReactDOM.createPortal(
                  <Tooltip
                    id="overflow-tooltip"
                    place="bottom"
                    className="custom-primary-tooltip"
                    effect="solid"
                    style={{
                      position: "absolute",
                      top: "-40px",
                      left: "50%",
                      transform: "translateX(-50%)",
                      zIndex: 1050,
                    }}
                  >
                    {selectedTeams.slice(4).map((team, index) => (
                      <div key={index}>{team?.text}</div>
                    ))}
                  </Tooltip>,
                  document.body
                )}
            </div>
          </>
        )}
      </>
    ) : (
      <span
        style={
          ["open", "reOpen"].includes(data?.attributes?.status)
            ? { color: "#788CA5" }
            : {}
        }
        className="ml-3 mr-3 font14"
      >
        {["open", "reOpen"].includes(data?.attributes?.status)
          ? t.workOrders.add_team
          : t.common.na}
      </span>
    );

  const peopleProfiles =
    selectedPeople?.length > 0 ? (
      <>
        {selectedPeople.length < 4 ? (
          selectedPeople.map((team, index) => (
            <div
              key={team.key}
              className={`primary-profile-container ${
                ["open", "reOpen"].includes(data?.attributes?.status) &&
                selectedPrimaryAssignee?.key
                  ? "hover-div"
                  : ""
              }`}
              style={{
                marginRight: index !== 0 ? "-11px" : "0",
              }}
            >
              <div
                onMouseEnter={() => setHoveredIndex(index)}
                onMouseLeave={() => setHoveredIndex(null)}
              >
                <Image
                  avatar
                  data-tip
                  data-for={`tooltip-${index}`}
                  src={team?.image?.src}
                  style={{
                    width:
                      selectedLanguage === "en" && index !== 0
                        ? "44px"
                        : "34px",
                    height: "34px",
                    right:
                      selectedLanguage === "ar" && index !== 0
                        ? "0px"
                        : index === 0 && selectedLanguage === "en"
                        ? "-8px"
                        : "-9px",
                  }}
                  className={index === 0 ? "img-first" : ""}
                />
                {hoveredIndex === index && (
                  <Tooltip
                    className="custom-primary-tooltip"
                    id={`tooltip-${index}`}
                    place="top"
                    effect="solid"
                  >
                    {team.text}
                  </Tooltip>
                )}
              </div>

              <span
                className="close-icon-wo c-pointer"
                style={{
                  top:
                    index === 0
                      ? selectedLanguage === "ar"
                        ? "-9px"
                        : "-12px"
                      : "-12px",
                  right:
                    index === 0
                      ? selectedLanguage === "ar"
                        ? "3px"
                        : "-7px"
                      : "5px",
                }}
                onClick={(e) => {
                  if (
                    ["open", "reOpen"].includes(data?.attributes?.status) &&
                    selectedPrimaryAssignee?.key
                  ) {
                    e.stopPropagation();
                    handlePeopleSelect(team);
                  }
                }}
              >
                <CloseIconWO />
              </span>
            </div>
          ))
        ) : (
          <>
            {selectedPeople?.slice(0, 3).map((team, index) => (
              <div
                className={`primary-profile-container ${
                  ["open", "reOpen"].includes(data?.attributes?.status) &&
                  selectedPrimaryAssignee?.key
                    ? "hover-div"
                    : ""
                }`}
                key={team.key}
                style={{
                  marginRight: index !== 0 ? "-11px" : "0",
                }}
              >
                <div
                  onMouseEnter={() => setHoveredIndex(index)}
                  onMouseLeave={() => setHoveredIndex(null)}
                >
                  <Image
                    avatar
                    data-tip
                    data-for={`tooltip-${index}`}
                    src={team?.image?.src}
                    style={{
                      width:
                        selectedLanguage === "en" && index !== 0
                          ? "44px"
                          : "34px",
                      height: "34px",
                      right:
                        selectedLanguage === "ar" && index !== 0
                          ? "0px"
                          : index === 0 && selectedLanguage === "en"
                          ? "-8px"
                          : "-9px",
                    }}
                    className={index === 0 ? "img-first" : ""}
                  />
                  {hoveredIndex === index && (
                    <Tooltip
                      className="custom-primary-tooltip"
                      id={`tooltip-${index}`}
                      place="top"
                      effect="solid"
                    >
                      {team.text}
                    </Tooltip>
                  )}
                </div>

                <span
                  className="close-icon-wo"
                  style={{
                    top:
                      index === 0
                        ? selectedLanguage === "ar"
                          ? "-9px"
                          : "-12px"
                        : "-12px",
                    right:
                      index === 0
                        ? selectedLanguage === "ar"
                          ? "3px"
                          : "-7px"
                        : "5px",
                    cursor: "pointer",
                  }}
                  onClick={(e) => {
                    if (
                      ["open", "reOpen"].includes(data?.attributes?.status) &&
                      selectedPrimaryAssignee?.key
                    ) {
                      e.stopPropagation();
                      handlePeopleSelect(team);
                    }
                  }}
                >
                  <CloseIconWO />
                </span>
              </div>
            ))}

            <div
              onMouseEnter={() => setHoveredIndex("overflow")}
              onMouseLeave={() => setHoveredIndex(null)}
              style={{ position: "relative" }}
              className="primary-profile-container"
            >
              <Image
                key="overflow"
                className="more-image"
                avatar
                src={`https://ui-avatars.com/api/?name=${selectedPeople?.length -
                  3}%2B&background=E4E6EA&bold=true&color=788CA5`}
                style={{
                  width: selectedLanguage === "en" ? "44px" : "34px",
                  height: "34px",
                  right: "-8px",
                }}
                data-tip
                data-for="overflow-tooltip"
              />
              {hoveredIndex === "overflow" &&
                ReactDOM.createPortal(
                  <Tooltip
                    id="overflow-tooltip"
                    className="custom-primary-tooltip"
                    place="bottom"
                    effect="solid"
                    style={{
                      position: "absolute",
                      top: "-40px",
                      left: "50%",
                      transform: "translateX(-50%)",
                      zIndex: 1050,
                    }}
                  >
                    {selectedPeople.slice(3).map((team, index) => (
                      <div key={index}>{team.text}</div>
                    ))}
                  </Tooltip>,

                  document.body
                )}
            </div>
          </>
        )}
      </>
    ) : (
      <span
        style={
          ["open", "reOpen"].includes(data?.attributes?.status)
            ? { color: "#788CA5" }
            : {}
        }
        className="ml-3 mr-3 font14"
      >
        {["open", "reOpen"].includes(data?.attributes?.status)
          ? t.workOrders.add_assignees
          : t.common.na}
      </span>
    );

  const primaryProfiles = selectedPrimaryAssignee?.key ? (
    <div
      key={selectedPrimaryAssignee.key}
      className={`primary-profile-container ${
        ["open", "reOpen"].includes(data?.attributes?.status) ? "hover-div" : ""
      }`}
    >
      <div>
        <Image
          avatar
          data-tip
          data-for={`tooltip-primary`}
          src={selectedPrimaryAssignee?.image?.src}
          style={{
            width: "34px",
            height: "34px",
            right: selectedLanguage === "ar" ? "0px" : "-8px",
          }}
          className="img-first wo-detail-img"
        />

        <Tooltip
          className="custom-primary-tooltip"
          id={"tooltip-primary"}
          place="top"
          effect="solid"
        >
          {selectedPrimaryAssignee.text}
        </Tooltip>
      </div>

      <span
        className="close-icon-wo"
        style={{
          top: selectedLanguage === "ar" ? "-9px" : "-12px",
          right: selectedLanguage === "ar" ? "3px" : "-7px",
          cursor: "pointer",
        }}
        onClick={(e) => {
          if (["open", "reOpen"].includes(data?.attributes?.status)) {
            e.stopPropagation();
            handlePrimarySelect(selectedPrimaryAssignee);
          }
        }}
      >
        <CloseIconWO />
      </span>
    </div>
  ) : (
    <span
      style={
        ["open", "reOpen"].includes(data?.attributes?.status)
          ? { color: "#788CA5" }
          : {}
      }
      className="ml-3 mr-3 font14"
    >
      {["open", "reOpen"].includes(data?.attributes?.status)
        ? t.workOrders.add_assignee
        : t.common.na}
    </span>
  );

  return (
    <>
      {negative &&
      (data?.attributes?.status == "open" ||
        data?.attributes?.status == "inProgress") ? (
        <>
          <div className="alert ml-0 mr-0 mt-0">
            {t.workOrders.details_view.past_due_statement}{" "}
            <Moment
              date={
                data?.attributes?.dueDate +
                "T" +
                (data?.attributes?.dueTime
                  ? data?.attributes?.dueTime
                  : "08:00:00")
              }
              format="DD/MM/YYYY LT"
            />
          </div>
        </>
      ) : data?.attributes?.status == "cancelled" ? (
        <div className="alert ml-0 mr-0 mt-0 ">
          <div
            className={
              selectedLanguage == "en"
                ? " text-dark text-left"
                : "text-dark text-right"
            }
          >
            <span className="user-img">
              {t.common.reason}:{" "}
              {selectedLanguage === "en"
                ? data?.attributes?.reason?.find(
                    (item) => item?.status === "cancelled"
                  )?.comment
                : data?.attributes?.reason?.find(
                    (item) => item?.status === "cancelled"
                  )?.arcomment}
            </span>
          </div>
        </div>
      ) : null}
      <div className="preview-details-wrapper">
        <div className="intro"></div>
        <div className="data-header">
          <div className="header-title d-flex mb-4 justify-content-between align-items-start">
            <div className="header-pill-container d-flex justify-content-between align-items-baseline">
              <p className="detail-title mt-5" style={{ width: "auto" }}>
                {data?.attributes?.title}
              </p>
            </div>
            <div className="status-container mt-7">
              {/* Button */}
              <div className={`status-button ${data?.attributes?.status}`}>
                <div
                  style={
                    (!["cancelled"].includes(data?.attributes?.status) &&
                      ["super_admin", "admin", "limited_admin"].includes(
                        roleData
                      )) ||
                    (roleData == "user" &&
                      checkValidUser() &&
                      ["inProgress", "onHold"].includes(
                        data?.attributes?.status
                      ))
                      ? selectedLanguage == "en"
                        ? { borderRight: "1px solid white" }
                        : { borderLeft: "1px solid white" }
                      : {}
                  }
                  className="status-text"
                  onClick={toggleDropdown}
                >
                  {statusOptions.find(
                    (status) => status.value === data?.attributes?.status
                  ).label || t.common.na}
                </div>

                {(!["cancelled"].includes(data?.attributes?.status) &&
                  ["super_admin", "admin", "limited_admin"].includes(
                    roleData
                  )) ||
                (roleData === "user" &&
                  checkValidUser() &&
                  ["inProgress", "onHold"].includes(
                    data?.attributes?.status
                  )) ? (
                  <div
                    className={`status-arrow ${
                      selectedLanguage == "en" ? "" : "mt-1"
                    }`}
                    onClick={toggleDropdown}
                  >
                    {isDropdownVisible ? (
                      <ArrowDetailsIcon />
                    ) : (
                      <ArrowDetailsIcon />
                    )}
                  </div>
                ) : null}
              </div>

              {/* Dropdown */}
              {isDropdownVisible && (
                <div className="status-dropdown-wo">
                  {data?.attributes?.status == "inReview" ? (
                    <div onClick={handleOpen} className="status-item open">
                      {t.workOrders.work_order_status.open}
                    </div>
                  ) : null}
                  {data?.attributes?.status == "inProgress" ? (
                    <div onClick={handleHold} className="status-item on-hold">
                      {t.workOrders.work_order_status.onHold}
                    </div>
                  ) : null}
                  {data?.attributes?.status == "onHold" ? (
                    <div
                      onClick={handleComplete}
                      className="status-item completed"
                    >
                      {t.workOrders.work_order_status.inReview}
                    </div>
                  ) : null}

                  {data?.attributes?.status == "completed" ? (
                    <div onClick={handleReopen} className="status-item reOpen">
                      {t.workOrders.work_order_status.reOpen}
                    </div>
                  ) : null}
                  {data?.attributes?.status == "inReview" ? (
                    <div
                      onClick={handleClose}
                      className="status-item closed mt-2"
                    >
                      {t.workOrders.work_order_status.completed}
                    </div>
                  ) : null}
                  {data?.attributes?.status == "open" ||
                  data?.attributes?.status == "reOpen" ? (
                    <div
                      onClick={handleCancel}
                      className="status-item canceled"
                    >
                      {t.workOrders.work_order_status.cancelled}
                    </div>
                  ) : null}
                </div>
              )}
            </div>
          </div>
          {data?.attributes?.image?.data?.attributes?.url ? (
            <div
              style={
                data?.attributes?.image?.data?.attributes?.ext === ".webm" ||
                data?.attributes?.image?.data?.attributes?.ext === ".mp4" ||
                data?.attributes?.image?.data?.attributes?.ext === ".hevc" ||
                data?.attributes?.image?.data?.attributes?.ext === ".mov"
                  ? {}
                  : data?.attributes?.image?.data?.attributes
                  ? { borderRadius: "8px", background: "lightgrey" }
                  : {}
              }
              className="d-flex justify-content-center align-items-center mb-5"
            >
              {data?.attributes?.image?.data?.attributes?.ext === ".webm" ||
              data?.attributes?.image?.data?.attributes?.ext === ".mp4" ||
              data?.attributes?.image?.data?.attributes?.ext === ".hevc" ||
              data?.attributes?.image?.data?.attributes?.ext === ".mov" ? (
                <video width="500" height={"156"} controls>
                  <source
                    src={IMAGES_URL + data.attributes.image.data.attributes.url}
                    type={"video/mp4"}
                  />
                  Your browser does not support the video tag.
                </video>
              ) : (
                <SemanticImage
                  src={
                    data?.attributes.image?.data?.attributes
                      ? IMAGES_URL + data.attributes.image.data.attributes.url
                      : toAbsoluteUrl("/media/default-img.png")
                  }
                  size="medium"
                  className={
                    data?.attributes?.image?.data?.attributes
                      ? "data-header-image mb-5"
                      : "no-img"
                  }
                />
              )}
            </div>
          ) : null}
        </div>

        <div className="details-wrapper">
          <div className="notes-container">
            <p className="d-flex mb-2">
              {" "}
              <CloseNoteIcon />{" "}
              <span className="ml-1 font-weight-bold">
                {" "}
                {t.workOrders.form.description}
              </span>
            </p>
            <p>
              {data?.attributes?.description
                ? data?.attributes?.description
                : t.common.na}
            </p>
          </div>

          <div className="notes-container mt-5">
            <Grid columns={2}>
              <GridColumn
                className={`${selectedLanguage == "en" ? "pr-0" : "pl-0"} pt-0`}
              >
                {" "}
                <div className="d-flex align-items-start mt-5">
                  <div className="icon-container d-flex align-items-center">
                    {" "}
                    <div>
                      {" "}
                      <CategortDetailsIcon />
                    </div>
                    <label className="workorder-intro-label work-order-label">
                      {" "}
                      {t.workOrders.table.category}
                    </label>
                  </div>

                  <div className="workorder-intro-value ">
                    {categoryStatus?.label || t.common.na}
                  </div>
                </div>
              </GridColumn>
              <GridColumn
                className={`${selectedLanguage == "en" ? "pr-0" : "pl-0"} pt-0`}
              >
                {" "}
                <div className="d-flex align-items-start mt-5">
                  <div className="icon-container d-flex align-items-center">
                    {" "}
                    <div>
                      <FlagDetailsIcon />
                    </div>
                    <label className="workorder-intro-label work-order-label">
                      {" "}
                      {t.workOrders.table.priority}
                    </label>
                  </div>

                  <div
                    className={` gap-0 mt-0 workorder-intro-value ${data.attributes.priority}`}
                  >
                    <FlagwithFilled color={workOrderPriorityColor?.color} />
                    <span className="ml-3 mr-3">
                      {workOrderPriorityColor.label}{" "}
                    </span>
                  </div>
                </div>
              </GridColumn>
            </Grid>

            <Grid columns={2} className="mt-0">
              <GridColumn
                className={`${selectedLanguage == "en" ? "pr-0" : "pl-0"} pt-0`}
              >
                {" "}
                <div className="d-flex align-items-start mt-3">
                  <div className="icon-container d-flex align-items-center">
                    {" "}
                    <div>
                      {" "}
                      <LocationDetailsIcon />
                    </div>
                    <label className="workorder-intro-label work-order-label">
                      {" "}
                      {t.workOrders.table.location}
                    </label>
                  </div>

                  <div className="workorder-intro-value ">
                    {data?.attributes?.location?.data ? (
                      <React.Fragment>
                        {checkForLinkPermission("location") ? (
                          <span
                            className={`detail-intro-link`}
                            basic
                            onClick={() =>
                              history.push(
                                `/locations?id=${data?.attributes?.location?.data?.attributes?.countId}`
                              )
                            }
                          >
                            {data?.attributes?.location?.data?.attributes?.name}
                          </span>
                        ) : (
                          <span className=" ml-1 mr-1">
                            {data?.attributes?.location?.data?.attributes?.name}
                          </span>
                        )}
                      </React.Fragment>
                    ) : (
                      t.common.na
                    )}
                  </div>
                </div>
              </GridColumn>
              <GridColumn
                className={`${selectedLanguage == "en" ? "pr-0" : "pl-0"} pt-0`}
              >
                {" "}
                <div className="d-flex align-items-start mt-3">
                  <div className="icon-container d-flex align-items-center">
                    {" "}
                    <div>
                      <AreaDetailsIcon />
                    </div>
                    <label className="workorder-intro-label work-order-label">
                      {" "}
                      {t.parts.filter.area}
                    </label>
                  </div>

                  <div className="workorder-intro-value">
                    {data?.attributes?.area?.data?.attributes.name ||
                      t.common.na}
                  </div>
                </div>
              </GridColumn>
            </Grid>

            <Grid columns={2} className="mt-0">
              <GridColumn
                className={`${selectedLanguage == "en" ? "pr-0" : "pl-0"} pt-0`}
              >
                {" "}
                <div className="d-flex align-items-start mt-3">
                  <div className="icon-container d-flex align-items-center">
                    {" "}
                    <div>
                      {" "}
                      <SchoolIcon />
                    </div>
                    <label className="workorder-intro-label work-order-label">
                      {" "}
                      {t.workOrders.table.customer}
                    </label>
                  </div>

                  <div className="workorder-intro-value">{customerName}</div>
                </div>
              </GridColumn>
              <GridColumn
                className={`${selectedLanguage == "en" ? "pr-0" : "pl-0"} pt-0`}
              >
                {" "}
                <div className="d-flex align-items-start mt-3">
                  <div className="icon-container d-flex align-items-center">
                    {" "}
                    <div>
                      <DetailsAssetIcon />
                    </div>
                    <label className="workorder-intro-label work-order-label">
                      {" "}
                      {t.workOrders.form.assets}
                    </label>
                  </div>

                  <div className="workorder-intro-value">
                    {data?.attributes?.assets?.data?.length
                      ? data.attributes.assets.data.map((asset, index) => (
                          <React.Fragment key={index}>
                            {checkForLinkPermission("asset") ? (
                              <p
                                className={`detail-intro-link mb-1 ${
                                  selectedLanguage == "en"
                                    ? "text-left"
                                    : "text-right"
                                }`}
                                basic
                                onClick={() =>
                                  history.push(
                                    `/assets?id=${asset?.attributes?.countId}`
                                  )
                                }
                              >
                                {asset.attributes.name}
                              </p>
                            ) : (
                              <p className={`ml-1 mr-1`} basic>
                                {asset.attributes.name}
                              </p>
                            )}
                          </React.Fragment>
                        ))
                      : t.common.na}
                  </div>
                </div>
              </GridColumn>
            </Grid>
          </div>

          <div className="notes-container mt-5">
            <Grid columns={2}>
              <GridColumn
                className={`${selectedLanguage == "en" ? "pr-0" : "pl-0"} pt-0`}
              >
                {" "}
                <div className="d-flex align-items-start mt-5">
                  <div className="icon-container d-flex align-items-start">
                    {" "}
                    <div>
                      {" "}
                      <PrimaryIcon />
                    </div>
                    <label className="workorder-intro-label work-order-label">
                      {" "}
                      {t.workOrders.form.primary}
                    </label>
                  </div>

                  <div
                    className={`workorder-intro-value  ${
                      ["open", "reOpen"].includes(data?.attributes?.status)
                        ? "grey-container-hover"
                        : ""
                    }`}
                    style={{ marginLeft: "8px" }}
                  >
                    <div
                      className="team-dropdown-container"
                      style={{ position: "relative" }}
                    >
                      <div
                        className={`multi-profile-container no-hover ${
                          ["open", "reOpen"].includes(data?.attributes?.status)
                            ? "c-pointer"
                            : ""
                        } ${selectedLanguage == "en" ? "" : "rtl-body"}`}
                        onClick={() => {
                          if (
                            ["open", "reOpen"].includes(
                              data?.attributes?.status
                            )
                          ) {
                            setDropdownPrimaryOpen(true);
                          }
                        }}
                      >
                        {primaryProfiles}
                      </div>
                      {dropdownPrimaryOpen && (
                        <div
                          className="dropdown-tooltip people"
                          ref={dropdownRef}
                        >
                          <div className="table-searchbar m-3">
                            <SearchIcon />
                            <Input
                              autoComplete="new-password"
                              className="dropdown-search-input search"
                              value={searchPrimary}
                              onChange={(e) => handleSearchChangePrimary(e)}
                            />
                          </div>
                          <div className="dropdown-options scroll-data">
                            {options?.primaryAssignee?.options.map((team) => (
                              <div
                                key={team.key}
                                className={`dropdown-item teams-options d-flex align-items-center c-pointer ${
                                  selectedPrimaryAssignee?.key === team.key
                                    ? "avatar-selected"
                                    : ""
                                }`}
                                onClick={() => handlePrimarySelect(team)}
                              >
                                <Image
                                  avatar
                                  title={team.text}
                                  src={team?.image?.src}
                                  style={{
                                    width: "20px",
                                    height: "20px",
                                  }}
                                />

                                <span>
                                  {DisplayFormattedText(team?.text, 26)}
                                </span>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </GridColumn>
              <GridColumn
                className={`${selectedLanguage == "en" ? "pr-0" : "pl-0"} pt-0`}
              >
                {" "}
                <div className="d-flex align-items-start mt-5">
                  <div className="icon-container d-flex align-items-start">
                    {" "}
                    <div>
                      <SecondaryIcon />
                    </div>
                    <label className="workorder-intro-label work-order-label">
                      {" "}
                      {t.workOrders.form.secondary}
                    </label>
                  </div>

                  <div
                    className={`workorder-intro-value  ${
                      ["open", "reOpen"].includes(data?.attributes?.status)
                        ? "grey-container-hover"
                        : ""
                    }`}
                    style={{ marginLeft: "8px" }}
                  >
                    <div
                      className="team-dropdown-container"
                      style={{ position: "relative" }}
                    >
                      <div
                        className={`multi-profile-container no-hover ${
                          selectedLanguage == "en" ? "" : "rtl-body"
                        } ${
                          ["open", "reOpen"].includes(
                            data?.attributes?.status
                          ) && selectedPrimaryAssignee?.key
                            ? "c-pointer"
                            : ""
                        }`}
                        onClick={() => {
                          if (
                            ["open", "reOpen"].includes(
                              data?.attributes?.status
                            ) &&
                            selectedPrimaryAssignee?.key
                          ) {
                            setDropdownPeopleOpen(true);
                          }
                        }}
                      >
                        {peopleProfiles}
                      </div>
                      {dropdownPeopleOpen && (
                        <div
                          className="dropdown-tooltip people"
                          ref={dropdownRef}
                        >
                          <div className="table-searchbar m-3">
                            <SearchIcon />
                            <Input
                              autoComplete="new-password"
                              className="dropdown-search-input search"
                              value={searchPeople}
                              onChange={(e) => handleSearchChangePeople(e)}
                            />
                          </div>
                          <div className="dropdown-options scroll-data">
                            {options?.people?.options.map((team) => (
                              <div
                                key={team.key}
                                className={`dropdown-item teams-options d-flex align-items-center c-pointer ${
                                  selectedPeople?.some(
                                    (selected) => selected.key === team.key
                                  )
                                    ? "avatar-selected"
                                    : ""
                                }`}
                                onClick={() => handlePeopleSelect(team)}
                              >
                                <Image
                                  avatar
                                  title={team.text}
                                  src={team?.image?.src}
                                  style={{
                                    width: "20px",
                                    height: "20px",
                                  }}
                                />
                                <span>
                                  {DisplayFormattedText(team?.text, 26)}
                                </span>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </GridColumn>
            </Grid>

            <Grid columns={1} className="mt-0">
              <GridColumn
                className={`${selectedLanguage == "en" ? "pr-0" : "pl-0"} pt-0`}
              >
                {" "}
                <div className="d-flex align-items-start mt-3">
                  <div className="icon-container d-flex align-items-start">
                    {" "}
                    <div>
                      {" "}
                      <TeamsIcon />
                    </div>
                    <label className="workorder-intro-label work-order-label">
                      {" "}
                      {t.workOrders.form.teams}
                    </label>
                  </div>

                  <div
                    className={`workorder-intro-value  ${
                      ["open", "reOpen"].includes(data?.attributes?.status)
                        ? "grey-container-hover"
                        : ""
                    }`}
                    style={{ marginLeft: "8px" }}
                  >
                    <div
                      className="team-dropdown-container"
                      style={{ position: "relative" }}
                    >
                      <div
                        className={`multi-profile-container no-hover ${
                          ["open", "reOpen"].includes(data?.attributes?.status)
                            ? "c-pointer"
                            : ""
                        } ${selectedLanguage == "en" ? "" : "rtl-body"}`}
                        onClick={() => {
                          if (
                            ["open", "reOpen"].includes(
                              data?.attributes?.status
                            )
                          ) {
                            setDropdownTeamsOpen(true);
                          }
                        }}
                      >
                        {teamProfiles}
                      </div>

                      {dropdownTeamsOpen && (
                        <div
                          className="dropdown-tooltip team"
                          ref={dropdownRef}
                        >
                          <div className="table-searchbar m-3">
                            <SearchIcon />
                            <Input
                              autoComplete="new-password"
                              className="dropdown-search-input search"
                              value={searchTeam}
                              onChange={(e) => handleSearchChange(e)}
                            />
                          </div>
                          <div className="dropdown-options scroll-data">
                            {options?.teams?.options?.map((team) => (
                              <div
                                key={team.key}
                                className={`dropdown-item teams-options d-flex align-items-center c-pointer ${
                                  selectedTeams?.some(
                                    (selected) => selected.key === team.key
                                  )
                                    ? "avatar-selected"
                                    : ""
                                }`}
                                onClick={() => handleTeamSelect(team)}
                              >
                                <Image
                                  avatar
                                  title={team?.text}
                                  src={team?.image?.src}
                                  style={{
                                    width: "20px",
                                    height: "20px",
                                  }}
                                />

                                <span>
                                  {DisplayFormattedText(team?.text, 26)}
                                </span>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </GridColumn>
              <GridColumn
                className={`${selectedLanguage == "en" ? "pr-0" : "pl-0"} pt-0`}
              >
                {" "}
              </GridColumn>
            </Grid>
          </div>
          {data?.attributes?.orderType == "reactive" ? (
            <div className="notes-container mt-5">
              <Grid columns={2}>
                <GridColumn
                  className={`${
                    selectedLanguage == "en" ? "pr-0" : "pl-0"
                  } pt-0`}
                >
                  {" "}
                  <div className="d-flex align-items-start mt-5">
                    <div className="icon-container d-flex align-items-start">
                      {" "}
                      <div>
                        {" "}
                        <OrderTypeIcon />
                      </div>
                      <label className="workorder-intro-label work-order-label">
                        {" "}
                        {t.workOrders.form.order_type}
                      </label>
                    </div>

                    <div
                      className="workorder-intro-value"
                      style={{ minWidth: "182px" }}
                    >
                      {t.workOrders.form.order_type_1}
                    </div>
                  </div>
                </GridColumn>
              </Grid>

              <Grid columns={2} className="mt-0">
                <GridColumn
                  className={`${
                    selectedLanguage == "en" ? "pr-0" : "pl-0"
                  } pt-0`}
                >
                  {" "}
                  <div className="d-flex align-items-start mt-3">
                    <div className="icon-container d-flex align-items-start">
                      {" "}
                      <div>
                        {" "}
                        {startDue  ? (
                          <DuedateIcon color={"#f64e60"} />
                        ) : (
                          <DateDetailsIcon color={"#788CA5"} />
                        )}
                      </div>
                      <label
                        className={`workorder-intro-label work-order-label ${startDue ? "text-danger" : ""} `}
                      >
                        {" "}
                        {t.workOrders.form.start_date_new}
                      </label>
                    </div>

                    <div className={`workorder-intro-value ${startDue ? "text-danger" : ""}`}>
                      {data?.attributes?.startDate ? (
                        format(
                          new Date(
                            data?.attributes?.startDate +
                              "T" +
                              `${data?.attributes?.startTime || "08:00:00"}`
                          ),
                          "dd/MM/yyyy hh:mm a"
                        )
                      ) : (
                        <span>{t.common.na}</span>
                      )}
                    </div>
                  </div>
                </GridColumn>
                <GridColumn
                  className={`${
                    selectedLanguage == "en" ? "pr-0" : "pl-0"
                  } pt-0`}
                >
                  {" "}
                  <div className="d-flex align-items-start mt-3">
                    <div className="icon-container d-flex align-items-start">
                      {" "}
                      <div>
                        {" "}
                        {negative ? (
                          <DuedateIcon color={"#f64e60"} />
                        ) : (
                          <DateDetailsIcon color={"#788CA5"} />
                        )}
                      </div>
                      <label
                        className={`workorder-intro-label work-order-label ${
                          negative ? "text-danger" : ""
                        }`}
                      >
                        {" "}
                        {t.workOrders.form.due_date_new}
                      </label>
                    </div>

                    <div
                      className={`workorder-intro-value ${
                        negative ? "text-danger" : ""
                      }`}
                    >
                      {data?.attributes?.dueDate ? (
                        <>
                          {format(
                            new Date(
                              data?.attributes?.dueDate +
                                "T" +
                                `${data?.attributes?.dueTime || "08:00:00"}`
                            ),
                            "dd/MM/yyyy hh:mm a"
                          )}
                        </>
                      ) : (
                        <span>{t.common.na}</span>
                      )}
                    </div>
                  </div>
                </GridColumn>
              </Grid>
            </div>
          ) : (
            <div className="notes-container mt-5">
              <Grid columns={2}>
                <GridColumn
                  className={`${
                    selectedLanguage == "en" ? "pr-0" : "pl-0"
                  } pt-0`}
                >
                  {" "}
                  <div className="d-flex align-items-start mt-5">
                    <div className="icon-container d-flex align-items-start">
                      {" "}
                      <div>
                        {" "}
                        <OrderTypeIcon />
                      </div>
                      <label className="workorder-intro-label work-order-label">
                        {" "}
                        {t.workOrders.form.order_type}
                      </label>
                    </div>

                    <div
                      className="workorder-intro-value"
                      style={{ minWidth: "146px" }}
                    >
                      {t.workOrders.form.order_type_2}
                    </div>
                  </div>
                </GridColumn>

                <GridColumn
                  className={`${
                    selectedLanguage == "en" ? "pr-0" : "pl-0"
                  } pt-0`}
                >
                  {" "}
                  <div className="d-flex align-items-start mt-5">
                    <div className="icon-container d-flex align-items-baseline ">
                      {" "}
                      <div>
                        {" "}
                        <SchedualWOIcon height="20" width="20" />
                      </div>
                      <label
                        onMouseOver={() => setViewSchedualPopup(true)}
                        onMouseLeave={() => setViewSchedualPopup(false)}
                        className="workorder-intro-label blue c-pointer"
                      >
                        {" "}
                        {t.workOrders.details_view.view_schedual}
                      </label>
                      {viewSchedualPopup ? (
                        <div className="custom-tooltip schedual-details">
                          <div className="tooltip-content schedual">
                            <h3>{t.workOrders.details_view.schedule}</h3>
                            <div className="cost-breakdown">
                              <div className="cost-row">
                                <label className="time-label">
                                  <span
                                    className={
                                      selectedLanguage == "en" ? "mr-2" : "ml-2"
                                    }
                                  >
                                    <RepeatIcon />
                                  </span>
                                  {t.workOrders.details_view.repeat}
                                  {": "}
                                </label>

                                <span className="cost-value">
                                  &nbsp;
                                  {data?.attributes?.repeatEvery === "daily"
                                    ? t.workOrders.details_view.daily
                                    : data?.attributes?.repeatEvery === "weekly"
                                    ? `${data?.attributes?.repeatEveryWeek} ${t.workOrders.details_view.weeks}`
                                    : data?.attributes?.repeatEvery ===
                                      "monthly"
                                    ? `${data?.attributes?.repeatEveryMonth} ${t.workOrders.details_view.months}`
                                    : data?.attributes?.repeatEvery === "yearly"
                                    ? `${data?.attributes?.repeatEveryYear} ${t.workOrders.details_view.years}`
                                    : null}
                                </span>
                              </div>
                              <Divider className="mb-2 mt-2" />
                              <div className="cost-row">
                                <label className="time-label">
                                  <span
                                    className={
                                      selectedLanguage == "en" ? "mr-2" : "ml-2"
                                    }
                                  >
                                    <RepeatOnIcon />
                                  </span>
                                  {t.workOrders.details_view.repeat_on}
                                  {": "}
                                </label>

                                <span className="cost-value">
                                  &nbsp;
                                  {data?.attributes?.daysRepeat
                                    ? Object.keys(data.attributes.daysRepeat)
                                        .filter(
                                          (day) =>
                                            day !== "id" &&
                                            data.attributes.daysRepeat[day]
                                        )
                                        .map((day) => {
                                          const formattedDay =
                                            day.charAt(0).toUpperCase() +
                                            day.slice(1);
                                          return t.common.days[formattedDay];
                                        })
                                        .join(", ")
                                    : null}
                                </span>
                              </div>
                              <Divider className="mb-2 mt-2" />
                              <div className="cost-row">
                                <label className="time-label">
                                  <span
                                    className={
                                      selectedLanguage == "en" ? "mr-2" : "ml-2"
                                    }
                                  >
                                    <EndSchedualIcon />
                                  </span>
                                  {t.workOrders.details_view.ends}
                                  {": "}
                                </label>

                                <span className="cost-value">
                                  &nbsp;
                                  {data?.attributes?.endType == "on"
                                    ? data?.attributes?.endDate
                                      ? moment(
                                          data?.attributes?.endDate
                                        ).format("MMM DD, YYYY")
                                      : t.common.na
                                    : data?.attributes?.endType == "after"
                                    ? `${data?.attributes?.endRepeat} ${t.workOrders.title}`
                                    : t.workOrders.details_view.never}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      <div>
                        {" "}
                        <ShowInstanceIcon />
                      </div>
                      <label
                        onClick={() => getAllSchedualWO()}
                        className="workorder-intro-label blue c-pointer"
                      >
                        {" "}
                        {t.workOrders.details_view.view_instance}
                      </label>
                    </div>
                  </div>
                </GridColumn>
              </Grid>
              <Grid columns={2}>
                <GridColumn
                  className={`${
                    selectedLanguage == "en" ? "pr-0" : "pl-0"
                  } pt-0`}
                >
                  {" "}
                  <div className="d-flex align-items-start mt-5">
                    <div className="icon-container d-flex align-items-start">
                      {" "}
                      <div>
                        {" "}
                        {startDue  ? (
                          <DuedateIcon color={"#f64e60"} />
                        ) : (
                          <DateDetailsIcon color={"#788CA5"} />
                        )}
                      </div>
                      <label className={`workorder-intro-label work-order-label ${startDue ? "text-danger" : ""}`}>
                        {" "}
                        {t.workOrders.form.start_date_new}
                      </label>
                    </div>

                    <div className={`workorder-intro-value ${startDue ? "text-danger" : ""}`}>
                      {data?.attributes?.startDate ? (
                        <>
                          {format(
                            new Date(
                              data?.attributes?.startDate +
                                "T" +
                                `${data?.attributes?.startTime || "08:00:00"}`
                            ),
                            "dd/MM/yyyy hh:mm a"
                          )}
                        </>
                      ) : (
                        <span>{t.common.na}</span>
                      )}
                    </div>
                  </div>
                </GridColumn>

                <GridColumn
                  className={`${
                    selectedLanguage == "en" ? "pr-0" : "pl-0"
                  } pt-0`}
                >
                  {" "}
                  <div className="d-flex align-items-start mt-5">
                    <div className="icon-container d-flex align-items-start">
                      {" "}
                      <div>
                        {" "}
                        {negative ? (
                          <DuedateIcon color={"#f64e60"} />
                        ) : (
                          <DateDetailsIcon color={"#788CA5"} />
                        )}
                      </div>
                      <label className={`workorder-intro-label work-order-label  ${
                          negative ? "text-danger" : ""
                        }`}>
                        {" "}
                        {t.workOrders.form.due_date_new}
                      </label>
                    </div>

                    <div className={`workorder-intro-value  ${
                        negative ? "text-danger" : ""
                      }`}>
                      {data?.attributes?.dueDate ? (
                        <>
                          {format(
                            new Date(
                              data?.attributes?.dueDate +
                                "T" +
                                `${data?.attributes?.dueTime || "08:00:00"}`
                            ),
                            "dd/MM/yyyy hh:mm a"
                          )}
                        </>
                      ) : (
                        <span>{t.common.na}</span>
                      )}
                    </div>
                  </div>
                </GridColumn>
              </Grid>
            </div>
          )}

          <div className="metrics-card-container mt-5">
            <div className="metrics-card">
              <div className="metrics-grid">
                <div
                  onClick={() =>
                    setActiveIndex(data?.attributes?.procedure?.data ? 1 : 0)
                  }
                  className="metrics-item c-pointer"
                >
                  <h2>{t.workOrders.form.procedure}</h2>
                  <div className="value c-pointer">
                    {data?.attributes?.procedure?.data
                      ? calculateCurrentCount(
                          Number(
                            data?.attributes?.procedure?.data?.attributes
                              ?.procedureItems
                          ),
                          Number(
                            data?.attributes?.report?.data?.attributes
                              ?.procedureCompletion || 0
                          )
                        )
                      : t.common.na}
                  </div>
                </div>

                <div
                  onClick={() =>
                    setActiveIndex(data?.attributes?.procedure?.data ? 2 : 1)
                  }
                  className="metrics-item c-pointer"
                >
                  <h2>{t.workOrders.form.part_set}</h2>
                  <div className="value c-pointer">
                    {totalPartCount?.partCount ? totalPartCount?.partCount : 0}
                  </div>
                </div>

                <div
                  onClick={() =>
                    setActiveIndex(data?.attributes?.procedure?.data ? 3 : 2)
                  }
                  className="metrics-item c-pointer"
                >
                  <h2> {t.workOrders.actual_times}</h2>
                  <div className="value c-pointer">
                    {" "}
                    {totalPartCount?.actualTime
                      ? `${moment
                          .utc(
                            moment
                              .duration(totalPartCount?.actualTime)
                              .asMilliseconds()
                          )
                          .locale("en")
                          .format("HH:mm:ss")}`
                      : t.common.na}
                  </div>
                  <div className="sub-value">
                    {t.workOrders.filter.estimation_time_by}
                    <br />
                    {totalPartCount?.estimationTime
                      ? `${totalPartCount?.estimationTime} Hrs`
                      : t.common.na}
                  </div>
                </div>
                <div
                  onClick={() =>
                    setActiveIndex(data?.attributes?.procedure?.data ? 4 : 3)
                  }
                  className="metrics-item c-pointer"
                >
                  <h2>{t.workOrders?.form?.additional_cost}</h2>
                  <div className="value c-pointer">
                    {totalPartCount?.additionalCost
                      ? `${Number(totalPartCount?.additionalCost).toFixed(
                          2
                        )} ${organisationCurrency}`
                      : t.common.na}
                  </div>
                </div>
                <div
                  onMouseOver={() => setTotalCostTooltip(true)}
                  onMouseLeave={() => setTotalCostTooltip(false)}
                  className="metrics-item c-pointer no-hover"
                >
                  <h2>{t.workOrders.labor.total_cost}</h2>
                  <div className="value black">
                    {" "}
                    {totalPartCount?.totalCost
                      ? `${Number(totalPartCount?.totalCost).toFixed(
                          2
                        )} ${organisationCurrency}`
                      : t.common.na}
                  </div>
                  <div className="sub-value">
                    {t.workOrders.form.labor_cost}
                    <br />
                    {totalPartCount?.laborCost
                      ? `${Number(totalPartCount?.laborCost).toFixed(2)}` +
                        " " +
                        organisationCurrency
                      : t.common.na}
                  </div>
                  {totalCostTooltip ? (
                    <div className="custom-tooltip">
                      <div className="tooltip-content">
                        <h3>{t.workOrders.labor.total_cost}</h3>
                        <div className="cost-breakdown">
                          <div className="cost-row">
                            <span className="cost-label parts-cost">
                              {t.workOrders.cost.partsCosts}:{" "}
                            </span>
                            <span className="cost-value">
                              &nbsp;{" "}
                              {totalPartCount?.subTotal % 1 === 0
                                ? totalPartCount?.subTotal
                                : totalPartCount?.subTotal?.toFixed(2)}{" "}
                              {organisationCurrency}
                            </span>
                          </div>
                          <Divider className="mb-2 mt-2" />
                          <div className="cost-row">
                            <span className="cost-label">
                              {" "}
                              {t.workOrders.cost.laborCosts}:
                            </span>
                            <span className="cost-value">
                              &nbsp;{" "}
                              {totalPartCount?.totalLaborCost % 1 === 0
                                ? Number(totalPartCount?.totalLaborCost)
                                : Number(
                                    totalPartCount?.totalLaborCost?.toFixed(2)
                                  )}{" "}
                              {organisationCurrency}
                            </span>
                          </div>
                          <Divider className="mb-2 mt-2" />
                          <div className="cost-row">
                            <span className="cost-label">
                              {t.workOrders.cost.addCost} : {"  "}
                            </span>
                            <span className="cost-value">
                              &nbsp;{" "}
                              {Number(totalPartCount?.additionalCost % 1) === 0
                                ? Number(totalPartCount?.additionalCost)
                                : totalPartCount?.additionalCost?.toFixed(
                                    2
                                  )}{" "}
                              {organisationCurrency}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>

          {data?.attributes?.status !== "open" ? (
            <div
              onClick={() => {
                if (
                  !["cancelled", "completed"].includes(data?.attributes?.status)
                ) {
                  setIsEditing(true);
                }
              }}
              className={`notes-container mt-5 ${
                !["cancelled", "completed"].includes(data?.attributes?.status)
                  ? "c-pointer"
                  : ""
              }`}
            >
              <p className="d-flex mb-2">
                {" "}
                <CloseNoteIcon />{" "}
                <span className="ml-1 font-weight-bold">
                  {" "}
                  {t.workOrders?.form?.close_out}
                </span>
              </p>
              {isEditing ? (
                <Form className="closeout-notes-form mb-0">
                  <TextArea
                    autoComplete="new-password"
                    placeHolder={t.workOrders.form.add_notes}
                    value={totalPartCount?.closeOutNotes}
                    className="edit-notes"
                    onChange={(e) =>
                      setTotalPartCount({
                        ...totalPartCount,
                        closeOutNotes: e.target.value,
                      })
                    }
                    rows={isEditing ? 2 : 1}
                    fluid
                    onBlur={() => {
                      editCloseNotes(totalPartCount?.closeOutNotes);
                    }}
                  />
                </Form>
              ) : totalPartCount?.closeOutNotes ? (
                <p className="editable-text">
                  {totalPartCount?.closeOutNotes
                    ? totalPartCount?.closeOutNotes
                    : t.common.na}
                </p>
              ) : !["cancelled", "completed"].includes(
                  data?.attributes?.status
                ) ? (
                <Form className="closeout-notes-form mb-0">
                  <TextArea
                    autoComplete="new-password"
                    placeHolder={t.workOrders.form.add_notes}
                    value={totalPartCount?.closeOutNotes}
                    className="edit-notes"
                    onChange={(e) =>
                      setTotalPartCount({
                        ...totalPartCount,
                        closeOutNotes: e.target.value,
                      })
                    }
                    rows={isEditing ? 2 : 1}
                    fluid
                    onBlur={() => {
                      editCloseNotes(totalPartCount?.closeOutNotes);
                    }}
                  />
                </Form>
              ) : (
                <p className="editable-text">
                  {totalPartCount?.closeOutNotes
                    ? totalPartCount?.closeOutNotes
                    : t.common.na}
                </p>
              )}
            </div>
          ) : null}

          <div
            onClick={() => {
              if (
                !["cancelled", "completed"].includes(data?.attributes?.status)
              ) {
                setIsEditingFile(true);
              }
            }}
            className={`notes-container mt-5 ${
              !["cancelled", "completed"].includes(data?.attributes?.status)
                ? "c-pointer"
                : ""
            }`}
          >
            <p className="d-flex mb-2">
              {" "}
              <Attachment />{" "}
              <span className="ml-2 mr-2 workorder-intro-label">
                {" "}
                {t.workOrders.form.file}
              </span>
            </p>

            {/* <p className="workorder-intro-value mb-3">
              {totalPartCount?.files?.data?.length
                ? totalPartCount?.files?.data.map((attachment, index) => (
                    <React.Fragment key={index}>
                      <span
                        className={`detail-intro-link mb-1 ${
                          selectedLanguage == "en" ? "text-left" : "text-right"
                        }`}
                        basic
                        onClick={() => {
                          window.open(attachment?.attributes?.url, "_blank");
                        }}
                      >
                        {attachment?.attributes?.name}
                      </span>
                      <br />
                    </React.Fragment>
                  ))
                : t.common.na}
            </p> */}

            <div className="non-media-container">
              {totalPartCount?.files?.data?.map((item, index) => (
                <div
                  key={index}
                  className={`image-dropzone  c-pointer blue-border wo-dropzone p-1 file d-flex justify-content-between text-start mt-5`}
                >
                  <div
                    onClick={() => {
                      window.open(item?.attributes?.url, "_blank");
                    }}
                    className=""
                  >
                    <label className="d-flex c-pointer font14 align-items-center m-1">
                      <div
                        className={`${
                          selectedLanguage === "en" ? "ml-4 mr-4" : "ml-4 mr-4"
                        }`}
                      >
                        <RenderFiles file={item?.attributes?.mime} />
                      </div>
                      {item?.attributes?.name}
                    </label>
                  </div>
                  {!["cancelled", "completed"].includes(
                    data?.attributes?.status
                  ) ? (
                    <div
                      className={`${
                        selectedLanguage === "en" ? "mr-3" : "ml-3"
                      }`}
                      onClick={() => deleteFile(item?.id)}
                    >
                      <DeleteIcon />
                    </div>
                  ) : null}
                </div>
              ))}
            </div>

            {fileLoading ? (
              <Loader className="mt-3" active inline="centered" />
            ) : isEditingFile ||
              !["cancelled", "completed"].includes(data?.attributes?.status) ? (
              <div
                className={`files-container ${
                  totalPartCount?.files?.data ? "mt-5" : ""
                }`}
              >
                <FileUpload
                  totalFiles={totalPartCount?.files?.data?.length}
                  onCertificateFileChange={onCertificateFileChange}
                />
              </div>
            ) : !totalPartCount?.files?.data ? (
              <p className="font14">
                {t.common.na}{" "}
              </p>
            ) : null}
          </div>

          <div className="d-flex justify-content-between">
            <div className="d-flex align-items-start mt-5">
              <label className="workorder-intro-label  grey">
                {t.workOrders.table.createdByUser}
              </label>
              <p
                style={{ marginTop: "-1px", fontSize: "12px" }}
                className="workorder-intro-value set-width grey"
              >
                {data?.attributes?.createdByUser?.data?.attributes?.firstName &&
                data?.attributes?.createdByUser?.data?.attributes?.lastName
                  ? data?.attributes?.createdByUser?.data?.attributes
                      ?.firstName +
                    " " +
                    data?.attributes?.createdByUser?.data?.attributes?.lastName
                  : t.common.na}
                {", "}
                <Moment
                  format="DD/MM/YYYY LT"
                  date={data?.attributes?.createdAt}
                />
              </p>
            </div>

            <div className="d-flex align-items-start mt-5">
              <label className="workorder-intro-label   grey">
                {t.common.update_by}
              </label>
              <p
                style={{ marginTop: "-1px", fontSize: "12px" }}
                className="workorder-intro-value set-width grey"
              >
                {data?.attributes?.updatedByUser?.data
                  ? data?.attributes?.updatedByUser?.data?.attributes
                      ?.firstName +
                    " " +
                    data?.attributes?.updatedByUser?.data?.attributes
                      ?.lastName +
                    ", " +
                    moment(data?.attributes?.updatedAt).format("DD/MM/YYYY LT")
                  : t.common.na}
              </p>
            </div>
          </div>
        </div>

        <StatusChangeModal
          handleSave={handleSave}
          loading={loading}
          onHoldFlag={onHoldFlag}
          selectedLanguage={selectedLanguage}
          setMessage={setMessage}
          message={message}
          t={t}
          openModal={openModal}
          openFlag={openFlag}
          closeFlag={closeFlag}
          reOpenFlag={reOpenFlag}
          inCancleFlag={inCancleFlag}
          setModal={setModal}
          approveModal={approveModal}
          inProgressFlag={inProgressFlag}
        />
        <Modal
          size="tiny"
          open={DeleteSuccessModal}
          onClose={() => {
            toggleDeleteSuccessModal("close");
          }}
          style={{ minHeight: "195px" }}
          className={`delete-result-modal success`}
        >
          <div className="text-right mt-5 mr-5 ml-5">
            <span
              className="c-pointer"
              onClick={() => {
                toggleDeleteSuccessModal("close");
              }}
            >
              <CloseIcon />
            </span>
          </div>
          <Modal.Content>
            <div style={{ textAlign: "center", marginTop: "-9px" }}>
              <div className="mb-5">
                {inProgressFlag ? (
                  <Success />
                ) : !onHoldFlag ? (
                  approveModal ? (
                    <Success />
                  ) : (
                    <Success />
                  )
                ) : (
                  <Success />
                )}
              </div>
              <p className="status-modal-popup-message">
                {inCancleFlag
                  ? t.workOrders.success_cancel
                  : inProgressFlag
                  ? t.workOrders.success_progress
                  : !onHoldFlag
                  ? approveModal
                    ? t.workOrders.success_approve
                    : t.workOrders.success_decline
                  : t.workOrders.success_hold}{" "}
              </p>
            </div>
          </Modal.Content>
          <Modal.Actions></Modal.Actions>
        </Modal>

        <AssigneeModal
          fetchPeopleOptions={fetchPeopleOptions}
          t={t}
          onValuesChange={onValuesChange}
          options={options}
          setAssignData={setAssignData}
          setValidation={setValidation}
          setAssignModal={setAssignModal}
          validation={validation}
          assignModal={assignModal}
          assignData={assignData}
          fetchTeamsOptions={fetchTeamsOptions}
          selectedLanguage={selectedLanguage}
          handleAssign={handleAssign}
        />
        <ShowInstance
          instancesModal={instancesModal}
          setInstancesModalLoading={setInstancesModalLoading}
          instancesModalLoading={instancesModalLoading}
          schedualWOdata={schedualWOdata}
          setInstancesModal={setInstancesModal}
          selectedLanguage={selectedLanguage}
        />
      </div>{" "}
    </>
  );
};

export default Details;
