import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectTranslations } from "../../../config/i18n/slice";
import ProgressBar from "@ramonak/react-progress-bar";
import {
  Icon,
  Loader,
  Dimmer,
  Segment,
  List,
} from "semantic-ui-react";
import {

  IMAGES_URL,

} from "../../../config/functions";
import {
  AmountIcon,
  Attachment,
  CheckboxIcon,
  ChecklistIcon,
  CSVSvg,
  DateIcon,
  DocSvg,
  DownArrow,
  EvidanceIcon,
  FileIcon,
  GreyFileIcon,
  GreyWOIcon,
  HeadingIcon,
  InspectionIcon,
  MultipleChoiceIcon,
  NumberIcon,
  PdfGreyIcon,
  PhotoIcon,
  PhotoSvg,
  RequiredIcon,
  SignatureIcon,
  TextIcon,
  TextSvg,
  UpArrow,
  UpArrowAr,
  VideoGreyIcon,
  ZipSvg,
} from "../../../../Assets/Icons/svg";
import Asterisk from "../../../components/common/Asterisk";
import { useHistory } from "react-router-dom";

function ProcedureItems({
  targetedData,
  procedureItemsCount,
  WOStatus,
  procedureData,
}) {
  const t = useSelector(selectTranslations);
  const [procedureFields, setProcedureFields] = useState([]);
  const [procedureItemsVisible, setProcedureItemsVisible] = useState({});

  const [headerProcedureFields, setHeaderProcedureFields] = useState([]);

  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return "en";
    }
  });
  const history = useHistory();
  const fieldTypes = [
    {
      key: "checkbox",
      value: "checkbox",
      text: t.procedures.form.type_options[0],
      icon: <CheckboxIcon width="24px" height="24px" />,
    },
    {
      key: "text",
      value: "text",
      text: t.procedures.form.type_options[1],
      icon: <TextIcon width="24px" height="24px" />,
    },
    {
      key: "number",
      value: "number",
      text: t.procedures.form.type_options[2],
      icon: <NumberIcon width="24px" height="24px" />,
    },
    {
      key: "amount",
      value: "amount",
      text: t.procedures.form.type_options[3],
      icon: <AmountIcon width="24px" height="24px" />,
    },
    {
      key: "multipleChoice",
      value: "multipleChoice",
      text: t.procedures.form.type_options[4],
      icon: <MultipleChoiceIcon width="24px" height="24px" />,
    },
    {
      key: "checklist",
      value: "checklist",
      text: t.procedures.form.type_options[5],
      icon: <ChecklistIcon width="24px" height="24px" />,
    },
    {
      key: "inspectionCheck",
      value: "inspectionCheck",
      text: t.procedures.form.type_options[6],
      icon: <InspectionIcon width="24px" height="24px" />,
    },
    {
      key: "signature",
      value: "signature",
      text: t.procedures.form.type_options[7],
      icon: <SignatureIcon width="24px" height="24px" />,
    },
    {
      key: "date",
      value: "date",
      text: t.procedures.form.type_options[8],
      icon: <DateIcon width="24px" height="24px" />,
    },
    {
      key: "file",
      value: "file",
      text: t.procedures.form.type_options[9],
      icon: <FileIcon width="24px" height="24px" />,
    },
    {
      key: "photo",
      value: "photo",
      text: t.procedures.form.type_options[10],
      icon: <PhotoIcon width="24px" height="24px" />,
    },
    {
      key: "heading",
      value: "heading",
      text: t.procedures.form.heading,
      icon: <HeadingIcon width="24px" height="24px" />,
    },
  ];

  const inspectionTypes = [
    {
      type: "yesNoNA",
      options: [
        { label: "Yes", value: "yes" },
        { label: "No", value: "no" },
        { label: "N/A", value: "na" },
      ],
    },
    {
      type: "goodFairPoorNA",
      options: [
        { label: "Good", value: "good" },
        { label: "Fair", value: "fair" },
        { label: "Poor", value: "poor" },
        { label: "N/A", value: "na" },
      ],
    },
    {
      type: "safeRiskNA",
      options: [
        { label: "Safe", value: "safe" },
        { label: "Risk", value: "risk" },
        { label: "N/A", value: "na" },
      ],
    },
    {
      type: "passFailNA",
      options: [
        { label: "Pass", value: "pass" },
        { label: "Fail", value: "fail" },
        { label: "N/A", value: "na" },
      ],
    },
    {
      type: "compliantNonCompliantNA",
      options: [
        { label: "Compliant", value: "compliant" },
        { label: "Non-Compliant", value: "non-compliant" },
        { label: "N/A", value: "na" },
      ],
    },
    {
      type: "scaleFive",
      options: [
        { label: "0", value: "0" },
        { label: "1", value: "1" },
        { label: "2", value: "2" },
        { label: "3", value: "3" },
        { label: "4", value: "4" },
        { label: "5", value: "5" },
      ],
    },
  ];
  useEffect(() => {
    if (targetedData?.attributes?.reportItems?.length > 0) {
      let newFields = targetedData.attributes.reportItems.map((field) => ({
        ...field,
        isExpanded: false,
      }));
      setProcedureFields(newFields);
    } else {
      let newFields = procedureData?.data?.attributes?.fields?.map((field) => ({
        ...field,
        isExpanded: false,
      }));
      setProcedureFields(newFields);
    }

    if (targetedData?.attributes?.headerReportItems?.length > 0) {
      let newFields = targetedData.attributes?.headerReportItems.map(
        (field) => ({
          ...field,
          isExpanded: false,
        })
      );

      setHeaderProcedureFields(newFields);
    } else {
      let newFields = procedureData?.data?.attributes?.headers?.map(
        (field) => ({
          ...field,
          isExpanded: false,
        })
      );

      setHeaderProcedureFields(newFields);
    }
  }, [
    targetedData?.attributes?.reportItems,
    targetedData?.attributes?.headerReportItems,
  ]);
  const generateSubFields = (field) => {
    const generatedOptions = (options) => {
      return options?.map((option, index) => {
        return (
          <div className="procedure-item-option">
            <p>{option.option}</p>
            <input type="checkbox" checked={option.isChecked} />
          </div>
        );
      });
    };

    const generatedCheckOptions = (options, field) => {
      return options?.map((option, index) => {
        return (
          <div className="procedure-item-option">
            <p>{option.option}</p>
            <input
              type="checkbox"
              checked={field?.value == false ? false : true}
            />
          </div>
        );
      });
    };

    const generatedMultiOptions = (options) => {
      return options?.map((option, index) => {
        return (
          <div className="procedure-item-option">
            <p>{option.option}</p>
            <input
              className="procedure-item-option-radio"
              type="checkbox"
              checked={option.isChecked}
            />
          </div>
        );
      });
    };

    const generatedInspectionOptions = (field) => {
      let foundType = null;

      // Find the type based on field value
      for (let inspectionType of inspectionTypes) {
        // for (let option of inspectionType.options) {
        if (inspectionType?.type === field?.inspectionType) {
          foundType = inspectionType;
          break;
        }
        // }
        if (foundType) break;
      }

      let filteredArray = foundType; 

      let filteredFailed = filteredArray?.options?.filter(
        (option) => option?.value == field?.value
      );
      filteredFailed = filteredFailed ? filteredFailed[0] : null;

      let logicAnswer = filteredArray?.options?.filter(
        (option) => option.value == field?.value
      );
      logicAnswer = logicAnswer ? logicAnswer[0] : null;

      return (
        <div className="wrapper-new-label-inspection-check">
          <label
            style={
              selectedLanguage == "en"
                ? { marginLeft: "-5px" }
                : { marginRight: "-5px" }
            }
            className="title-text-isc"
          >
            {t.procedures.form.type_options[6]}
          </label>
          <div className="mt-3 mb-5">
            {filteredArray?.options?.map((option) => (
              <span
                key={option.value}
                style={{ fontSize: "12px" }}
                className={`btn new-added-add-logic-txt option-inspection ${
                  filteredFailed?.value == option?.value
                    ? ["no", "poor", "fail", "risk", "non-compliant"].includes(
                        option?.value
                      )
                      ? "red-box"
                      : ["yes", "good", "compliant", "pass", "safe"].includes(
                          option?.value
                        )
                      ? "green-box"
                      : ["fair"].includes(option?.value)
                      ? "yellow-box"
                      : option?.value == "na"
                      ? "grey-box"
                      : ["0", "1", "2", "3", "4", "5"].includes(
                          String(option?.value)
                        )
                      ? "blue-box"
                      : ""
                    : ""
                }`}
              >
                {option.label
                  ? t.procedures.inspectionTypes[option.label.toLowerCase()] ||
                    option.label
                  : ""}
              </span>

            ))}
          </div>
        
          {field?.actions?.length &&
          (field?.actions[0]?.evidences?.data ||
            field?.actions[0]?.workOrder?.data) ? (
            <>
              <div
                style={
                  selectedLanguage == "en"
                    ? { marginLeft: "-5px" }
                    : { marginRight: "-5px" }
                }
                className="title-text-isc"
              >
                {t.procedures.logic}
              </div>

              <div className="logic-box-for-isc mt-3">
                {field?.actions?.length
                  ? field.actions.map((e) => {
                      if (e?.evidences?.data && e?.evidences?.data?.length) {
                        return (
                          <>
                            <label className="procedure-item-action mt-3">
                              {t.procedures.form.required_evidence}
                            </label>
                            <Segment secondary className="mt-1">
                              <List divided relaxed>
                                {e.evidences.data.map((evid) => {
                                  return (
                                    <List.Item
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        window.open(
                                          IMAGES_URL + evid?.attributes?.url,
                                          "_blank"
                                        );
                                      }}
                                    >
                                     
                                      <div className="d-flex align-items-center">
                                        {[
                                          ".jpg",
                                          ".jpeg",
                                          ".png",
                                          ".svg",
                                        ].includes(evid?.attributes?.ext) ? (
                                          <PhotoSvg />
                                        ) : [".pdf"].includes(
                                            evid?.attributes?.ext
                                          ) ? (
                                          <PhotoSvg />
                                        ) : [".csv", ".xlsx", ".xls"].includes(
                                            evid?.attributes?.ext
                                          ) ? (
                                          <CSVSvg />
                                        ) : [".zip"].includes(
                                            evid?.attributes?.ext
                                          ) ? (
                                          <ZipSvg />
                                        ) : [".doc", ".docx"].includes(
                                            evid?.attributes?.ext
                                          ) ? (
                                          <DocSvg />
                                        ) : [".txt"].includes(
                                            evid?.attributes?.ext
                                          ) ? (
                                          <TextSvg />
                                        ) : (
                                         
                                          <Attachment />
                                        )}
                                      

                                        <p className="font-weight-bold ml-2 mr-2">
                                          {evid?.attributes?.name}
                                        </p>
                                      </div>
                                    </List.Item>
                                  );
                                })}
                              </List>
                            </Segment>
                          </>
                        );
                      } else if (e?.workOrder?.data) {
                        return (
                          <div>
                            <label className="procedure-item-action">
                              {t.procedures.form.required_action}
                            </label>
                            <div className="WO-link">
                              <p>
                                {t.procedures.refer}{" "}
                                <span
                                  style={{
                                    color: "#0C66E4",
                                    textDecoration: "underline",
                                  }}
                                  className="c-pointer"
                                  onClick={() =>
                                    history.push(
                                      `/work-orders?id=${e?.workOrder?.data?.attributes?.countId}`
                                    )
                                  }
                                >
                                  {t.workOrders.details_view.work_order_no} #
                                  {e?.workOrder?.data?.attributes?.countId}
                                </span>
                              </p>
                            </div>
                           
                         
                          </div>
                        );
                      }
                    })
                  : null}
              </div>

            </>
          ) : null}
        </div>
      );

  
    };

    let returnFields = null;
    switch (field.type) {
      case "text":
        returnFields = (
          <Segment className="segament" secondary>
            {field?.value
              ? field?.value
              : WOStatus
              ? t.common.na
              : t.procedures.form.text_placeholder}
          </Segment>
        );
        break;
      case "number":
        returnFields = (
          <Segment className="segament" secondary>
            {field?.value
              ? field?.value
              : WOStatus
              ? t.common.na
              : t.procedures.form.number_placeholder}
          </Segment>
        );
        break;
      case "amount":
       
        returnFields = (
          <Segment className="segament" secondary>
            {field?.value
              ? field?.value
              : WOStatus
              ? t.common.na
              : t.procedures.form.amount_placeholder}
          </Segment>
        );
        break;
      case "signature":
        returnFields = field?.file?.data?.attributes?.name ? (
          <Segment secondary>
            <List divided relaxed>
              <List.Item
                style={{ cursor: "pointer" }}
                onClick={() => {
                  window.open(
                    IMAGES_URL + field?.file?.data?.attributes?.url,
                    "_blank"
                  );
                }}
              >
              
                <div className="d-flex align-items-center">
                  {[".jpg", ".jpeg", ".png", ".svg"].includes(
                    field?.file?.data?.attributes?.ext
                  ) ? (
                    <PhotoSvg />
                  ) : [".pdf"].includes(field?.file?.data?.attributes?.ext) ? (
                    <PhotoSvg />
                  ) : [".csv", ".xlsx"].includes(
                      field?.file?.data?.attributes?.ext
                    ) ? (
                    <CSVSvg />
                  ) : [".zip"].includes(field?.file?.data?.attributes?.ext) ? (
                    <ZipSvg />
                  ) : [".doc", ".docx"].includes(
                      field?.file?.data?.attributes?.ext
                    ) ? (
                    <DocSvg />
                  ) : [".txt"].includes(field?.file?.data?.attributes?.ext) ? (
                    <TextSvg />
                  ) : (
                   
                    <FileIcon />
                  )}
                  <p className="font-weight-bold ml-2 mr-2">
                    {field?.file?.data?.attributes?.name}
                  </p>
                </div>
               
              </List.Item>
            </List>
          </Segment>
        ) : WOStatus ? (
          <Segment className="segament" secondary>
            {t.common.na}
          </Segment>
        ) : (
          <Segment className="segament" secondary>
            {t.procedures.form.signature_placeholder}
          </Segment>
        );
        break;
      case "date":
        returnFields = (
          <Segment className="segament" secondary>
            {field?.value
              ? field?.value
              : WOStatus
              ? t.common.na
              : t.procedures.form.date_placeholder}
          </Segment>
        );
        break;

      case "checkbox":
        returnFields = generatedCheckOptions(field?.options, field);

    
        break;
      case "file":


        returnFields = field?.file?.data?.attributes?.name ? (
          <Segment secondary>
            <List divided relaxed>
              <List.Item
                style={{ cursor: "pointer" }}
                onClick={() => {
                  window.open(
                    IMAGES_URL + field?.file?.data?.attributes?.url,
                    "_blank"
                  );
                }}
              >
                <div className="d-flex align-items-center">
                  {[".jpg", ".jpeg", ".png", ".svg"].includes(
                    field?.file?.data?.attributes?.ext
                  ) ? (
                    <PhotoSvg />
                  ) : [".pdf"].includes(field?.file?.data?.attributes?.ext) ? (
                    <PhotoSvg />
                  ) : [".csv", ".xlsx"].includes(
                      field?.file?.data?.attributes?.ext
                    ) ? (
                    <CSVSvg />
                  ) : [".zip"].includes(field?.file?.data?.attributes?.ext) ? (
                    <ZipSvg />
                  ) : [".doc", ".docx"].includes(
                      field?.file?.data?.attributes?.ext
                    ) ? (
                    <DocSvg />
                  ) : [".txt"].includes(field?.file?.data?.attributes?.ext) ? (
                    <TextSvg />
                  ) : (
                   
                    <FileIcon />
                  )}{" "}
                  <p className="font-weight-bold ml-2 mr-2">
                    {field?.file?.data?.attributes?.name}
                  </p>
                </div>
              </List.Item>
            </List>
          </Segment>
        ) : WOStatus ? (
          <Segment secondary>{t.common.na}</Segment>
        ) : (
          <Segment className="segament" secondary>
            {t.procedures.form.file_placeholder}
          </Segment>
        );
        break;

      case "photo":
      
        returnFields = field?.file?.data?.attributes?.name ? (
          <Segment secondary>
            <List divided relaxed>
              <List.Item
                style={{ cursor: "pointer" }}
                onClick={() => {
                  window.open(
                    IMAGES_URL + field?.file?.data?.attributes?.url,
                    "_blank"
                  );
                }}
              >
                <div className="d-flex align-items-center">
                  {[".jpg", ".jpeg", ".png", ".svg"].includes(
                    field?.file?.data?.attributes?.ext
                  ) ? (
                    <PhotoSvg />
                  ) : [".pdf"].includes(field?.file?.data?.attributes?.ext) ? (
                    <PhotoSvg />
                  ) : [".csv", ".xlsx"].includes(
                      field?.file?.data?.attributes?.ext
                    ) ? (
                    <CSVSvg />
                  ) : [".zip"].includes(field?.file?.data?.attributes?.ext) ? (
                    <ZipSvg />
                  ) : [".doc", ".docx"].includes(
                      field?.file?.data?.attributes?.ext
                    ) ? (
                    <DocSvg />
                  ) : [".txt"].includes(field?.file?.data?.attributes?.ext) ? (
                    <TextSvg />
                  ) : (
                    
                    <FileIcon />
                  )}
                  <p className="font-weight-bold ml-2 mr-2">
                    {field?.file?.data?.attributes?.name}
                  </p>
                </div>
              </List.Item>
            </List>
          </Segment>
        ) : WOStatus ? (
          <Segment secondary>{t.common.na}</Segment>
        ) : (
          <Segment className="segament" secondary>
            {t.procedures.form.photo_placeholder}
          </Segment>
        );
        break;
      case "multipleChoice":
        returnFields = generatedMultiOptions(field.options);
        break;
      case "checklist":
      

        returnFields = generatedOptions(field.options);
        break;
      case "inspectionCheck":
      
        returnFields = generatedInspectionOptions(field);

        break;
      default:
        returnFields = null;
    }

    return (
      <>
        {returnFields}
   
        <div className="d-flex align-items-center">
         
         
          {field?.file?.data?.attributes ? (
            <div
              onClick={() => {
                window.open(
                  IMAGES_URL + field?.file?.data?.attributes?.url,
                  "_blank"
                );
              }}
              className="c-pointer"
            >
            </div>
          ) : null}
        </div>
      </>
    );

  };


  const generateSubFields2 = (field) => {
    const generatedOptions = (options) => {
      return options?.map((option, index) => {
        return (
          <div className="procedure-item-option">
            <p>{option.item}</p>
            <input type="checkbox" checked={false} />
          </div>
        );
      });
    };

    const generatedCheckOptions = (options, field) => {
      return options?.map((option, index) => {
        return (
          <div className="procedure-item-option">
            <p>{option.option}</p>
            <input
              type="checkbox"
              checked={field?.value == false ? false : true}
            />
          </div>
        );
      });
    };

    const generatedMultiOptions = (options) => {
      return options?.map((option, index) => {
        return (
          <div className="procedure-item-option">
            <p>{option.item}</p>
            <input type="radio" checked={false} />
          </div>
        );
      });
    };

    const generatedInspectionOptions = (field) => {
      let filteredArray = inspectionTypes.filter(
        (insp) => insp.type == field.inspectionType
      );
      filteredArray = filteredArray[0];

      let filteredFailed = filteredArray?.options?.filter(
        (option) => option?.value == field?.failedValue
      );
      filteredFailed = filteredFailed ? filteredFailed[0] : null;

      let logicAnswer = filteredArray?.options?.filter(
        (option) => option.value == field?.logicAnswer
      );
      logicAnswer = logicAnswer ? logicAnswer[0] : null;

      return (
        <div className="wrapper-new-label-inspection-check">
          <label
            style={
              selectedLanguage == "en"
                ? { marginLeft: "-5px" }
                : { marginRight: "-5px" }
            }
            className="title-text-isc"
          >
            {t.procedures.form.type_options[6]}
          </label>
          <div className="mt-3 mb-5">
            {filteredArray?.options?.map((option) => (
              <span
                className="btn new-added-add-logic-txt option-inspection"
                style={{ fontSize: "12px" }}
              >
                {option.label
                  ? t.procedures.inspectionTypes[option.label.toLowerCase()] ||
                    option.label
                  : ""}
              </span>

             
            ))}
          </div>
        </div>
      );
    };

    let returnFields = null;
    switch (field.fieldType) {
      case "text":
        returnFields = (
          <Segment className="segament" secondary>
            {field?.value
              ? field?.value
              : WOStatus
              ? t.common.na
              : t.procedures.form.text_placeholder}
          </Segment>
        );
        break;
      case "number":
        returnFields = (
          <Segment className="segament" secondary>
            {field?.value
              ? field?.value
              : WOStatus
              ? t.common.na
              : t.procedures.form.number_placeholder}
          </Segment>
        );
        break;
      case "amount":
        
        returnFields = (
          <Segment className="segament" secondary>
            {field?.value
              ? field?.value
              : WOStatus
              ? t.common.na
              : t.procedures.form.amount_placeholder}
          </Segment>
        );
        break;
      case "signature":
        returnFields = field?.file?.data?.attributes?.name ? (
          <Segment secondary>
            <List divided relaxed>
              <List.Item
                style={{ cursor: "pointer" }}
                onClick={() => {
                  window.open(
                    IMAGES_URL + field?.file?.data?.attributes?.url,
                    "_blank"
                  );
                }}
              >
               
                <div className="d-flex align-items-center">
                  {[".jpg", ".jpeg", ".png", ".svg"].includes(
                    field?.file?.data?.attributes?.ext
                  ) ? (
                    <PhotoSvg />
                  ) : [".pdf"].includes(field?.file?.data?.attributes?.ext) ? (
                    <PhotoSvg />
                  ) : [".csv", ".xlsx"].includes(
                      field?.file?.data?.attributes?.ext
                    ) ? (
                    <CSVSvg />
                  ) : [".zip"].includes(field?.file?.data?.attributes?.ext) ? (
                    <ZipSvg />
                  ) : [".doc", ".docx"].includes(
                      field?.file?.data?.attributes?.ext
                    ) ? (
                    <DocSvg />
                  ) : [".txt"].includes(field?.file?.data?.attributes?.ext) ? (
                    <TextSvg />
                  ) : (
                  
                    <FileIcon />
                  )}
                  <p className="font-weight-bold ml-2 mr-2">
                    {field?.file?.data?.attributes?.name}
                  </p>
                </div>
             
              </List.Item>
            </List>
          </Segment>
        ) : WOStatus ? (
          <Segment className="segament" secondary>
            {t.common.na}
          </Segment>
        ) : (
          <Segment className="segament" secondary>
            {t.procedures.form.signature_placeholder}
          </Segment>
        );
        break;
      case "date":
        returnFields = (
          <Segment className="segament" secondary>
            {field?.value
              ? field?.value
              : WOStatus
              ? t.common.na
              : t.procedures.form.date_placeholder}
          </Segment>
        );
        break;

      case "checkbox":
        returnFields = generatedCheckOptions(field?.options, field);
        break;
      case "file":
     

        returnFields = field?.file?.data?.attributes?.name ? (
          <Segment secondary>
            <List divided relaxed>
              <List.Item
                style={{ cursor: "pointer" }}
                onClick={() => {
                  window.open(
                    IMAGES_URL + field?.file?.data?.attributes?.url,
                    "_blank"
                  );
                }}
              >
                <div className="d-flex align-items-center">
                  {[".jpg", ".jpeg", ".png", ".svg"].includes(
                    field?.file?.data?.attributes?.ext
                  ) ? (
                    <PhotoSvg />
                  ) : [".pdf"].includes(field?.file?.data?.attributes?.ext) ? (
                    <PhotoSvg />
                  ) : [".csv", ".xlsx"].includes(
                      field?.file?.data?.attributes?.ext
                    ) ? (
                    <CSVSvg />
                  ) : [".zip"].includes(field?.file?.data?.attributes?.ext) ? (
                    <ZipSvg />
                  ) : [".doc", ".docx"].includes(
                      field?.file?.data?.attributes?.ext
                    ) ? (
                    <DocSvg />
                  ) : [".txt"].includes(field?.file?.data?.attributes?.ext) ? (
                    <TextSvg />
                  ) : (
                    
                    <FileIcon />
                  )}{" "}
                  <p className="font-weight-bold ml-2 mr-2">
                    {field?.file?.data?.attributes?.name}
                  </p>
                </div>
              </List.Item>
            </List>
          </Segment>
        ) : WOStatus ? (
          <Segment secondary>{t.common.na}</Segment>
        ) : (
          <Segment className="segament" secondary>
            {t.procedures.form.file_placeholder}
          </Segment>
        );
        break;

      case "photo":
      
        returnFields = field?.file?.data?.attributes?.name ? (
          <Segment secondary>
            <List divided relaxed>
              <List.Item
                style={{ cursor: "pointer" }}
                onClick={() => {
                  window.open(
                    IMAGES_URL + field?.file?.data?.attributes?.url,
                    "_blank"
                  );
                }}
              >
                <div className="d-flex align-items-center">
                  {[".jpg", ".jpeg", ".png", ".svg"].includes(
                    field?.file?.data?.attributes?.ext
                  ) ? (
                    <PhotoSvg />
                  ) : [".pdf"].includes(field?.file?.data?.attributes?.ext) ? (
                    <PhotoSvg />
                  ) : [".csv", ".xlsx"].includes(
                      field?.file?.data?.attributes?.ext
                    ) ? (
                    <CSVSvg />
                  ) : [".zip"].includes(field?.file?.data?.attributes?.ext) ? (
                    <ZipSvg />
                  ) : [".doc", ".docx"].includes(
                      field?.file?.data?.attributes?.ext
                    ) ? (
                    <DocSvg />
                  ) : [".txt"].includes(field?.file?.data?.attributes?.ext) ? (
                    <TextSvg />
                  ) : (
                  
                    <FileIcon />
                  )}
                  <p className="font-weight-bold ml-2 mr-2">
                    {field?.file?.data?.attributes?.name}
                  </p>
                </div>
              </List.Item>
            </List>
          </Segment>
        ) : WOStatus ? (
          <Segment secondary>{t.common.na}</Segment>
        ) : (
          <Segment className="segament" secondary>
            {t.procedures.form.photo_placeholder}
          </Segment>
        );
        break;
      case "multipleChoice":
        returnFields = generatedMultiOptions(field.options);
        break;
      case "checklist":
     

        returnFields = generatedOptions(field.options);
        break;
      case "inspectionCheck":
     
        returnFields = generatedInspectionOptions(field);

        break;
      default:
        returnFields = null;
    }

    return (
      <>
        {returnFields}
       
        <div className="">
        
        
          <div className=" mt-2">
            {field?.attachments?.data?.length > 0 && (
              <>
                {field?.attachments?.data?.map((prev, index) => {
                  return (
                    <>
                      <div
                        onClick={() => {
                          window.open(
                            IMAGES_URL + prev.attributes.url,
                            "_blank"
                          );
                        }}
                        className="c-pointer file-name-show-procedure details"
                      >
                        <div
                          className="c-pointer"
                          style={{ position: "relative" }}
                         
                        >
                          <span className="ml-2 mr-2">
                            {[".jpg", ".jpeg", ".png", ".svg"].includes(
                              prev?.attributes?.ext
                            ) ? (
                              <PhotoSvg />
                            ) : [".pdf"].includes(prev?.attributes?.ext) ? (
                              <PdfGreyIcon />
                            ) : [".mp4", ".mov"].includes(
                                prev?.attributes?.ext
                              ) ? (
                              <VideoGreyIcon />
                            ) : [".csv", ".xlsx"].includes(
                                prev?.attributes?.ext
                              ) ? (
                              <CSVSvg />
                            ) : [".zip"].includes(prev?.attributes?.ext) ? (
                              <ZipSvg />
                            ) : [".doc", ".docx"].includes(
                                prev?.attributes?.ext
                              ) ? (
                              <DocSvg />
                            ) : [".txt"].includes(prev?.attributes?.ext) ? (
                              <TextSvg />
                            ) : (
                              
                              <Attachment />
                            )}
                         
                          </span>
                        </div>
                        <p
                          style={{ wordBreak: "break-word" }}
                          className="mt-1 c-pointer"
                        >
                          {" "}
                          {prev?.attributes?.name}
                        </p>
                      </div>
                    </>
                  );
                })}
              </>
            )}
          </div>
        </div>
      </>
    );
  };

  const generatedFieldType = (type) => {
    let filteredFields = fieldTypes.filter((field) => field.value == type);
    filteredFields = filteredFields[0];

    return (
      <div className="flexbox align-center">
        <Icon
          circular={filteredFields?.icon?.circular}
          color={filteredFields?.icon?.color}
          name={filteredFields?.icon?.name}
        />
        {filteredFields?.text}
      </div>
    );
  };

  const generateFields = (fields, isHeader = false) => {
    const toggleProcedureItems = (index) => {
      if (procedureItemsVisible[index]) {
        setProcedureItemsVisible({
          ...procedureItemsVisible,
          [index]: false,
        });
      } else {
        setProcedureItemsVisible({
          ...procedureItemsVisible,
          [index]: true,
        });
      }
      
    };
   

    if (isHeader) {
      return headerProcedureFields.map((field, index) => (
        <React.Fragment key={field.id}>
          <div
            className={
              field?.reportItems?.length == 0
                ? "hide-border mb-5"
                : !procedureItemsVisible[index]
                ? "show-border mb-5"
                : "mb-5 hide-border"
            }
            style={
              field?.reportItems?.length == 0
                ? {}
                : !procedureItemsVisible[index]
                ? { borderRadius: "8px", border: "1px solid #0C66E4" }
                : {}
            }
          >
            <ProcedureItemContainerCard
              toggleProcedureItems={toggleProcedureItems}
              procedureItemsVisible={procedureItemsVisible}
              fieldCount={field?.reportItems?.length}
              field={field}
              fieldTypes={fieldTypes}
              index={index}
            >
              {field?.label}
            </ProcedureItemContainerCard>

            {!procedureItemsVisible[index] &&
              field?.reportItems &&
              field?.reportItems.length > 0 &&
              field.reportItems.map((subField) => (
                <ProcedureItemContainerCard
                  isHeader={isHeader}
                  key={subField.id}
                  field={subField}
                  fieldTypes={fieldTypes}
                >
                  <div className="item-value">
                    {generateSubFields(subField)}
                  </div>
                </ProcedureItemContainerCard>
              ))}
          </div>
        </React.Fragment>
      ));
    } else {
      return procedureFields.map((field, index) =>
        field?.fieldType == "heading" ? (
          <ProcedureItemContainerCard field={field} fieldTypes={fieldTypes}>
            {field?.label}
          </ProcedureItemContainerCard>
        ) : (
          <ProcedureItemContainerCard field={field} fieldTypes={fieldTypes}>
            <div className="item-value">{generateSubFields(field)}</div>
          </ProcedureItemContainerCard>
        )
      );
    }
  };

  const generateFields2 = (fields, isHeader = false) => {
    const toggleProcedureItems = (index) => {
      if (procedureItemsVisible[index]) {
        setProcedureItemsVisible({
          ...procedureItemsVisible,
          [index]: false,
        });
      } else {
        setProcedureItemsVisible({
          ...procedureItemsVisible,
          [index]: true,
        });
      }
    
    };


    if (isHeader) {
      return headerProcedureFields.map((field, index) => (
        <React.Fragment key={field.id}>
          <div
            className={
              field?.fields?.length == 0
                ? "hide-border mb-5"
                : !procedureItemsVisible[index]
                ? "show-border mb-5"
                : "mb-5 hide-border"
            }
            style={
              field?.fields?.length == 0
                ? {}
                : !procedureItemsVisible[index]
                ? { borderRadius: "8px", border: "1px solid #0C66E4" }
                : {}
            }
          >
            <ProcedureItemContainerCard2
              toggleProcedureItems={toggleProcedureItems}
              procedureItemsVisible={procedureItemsVisible}
              fieldCount={field?.fields?.length}
              field={field}
              fieldTypes={fieldTypes}
              index={index}
            >
              {field?.label}
            </ProcedureItemContainerCard2>

            {!procedureItemsVisible[index] &&
              field?.fields &&
              field?.fields.length > 0 &&
              field.fields.map((subField) => (
                <ProcedureItemContainerCard2
                  isHeader={isHeader}
                  key={subField.id}
                  field={subField}
                  fieldTypes={fieldTypes}
                >
                  <div className="item-value">
                    {generateSubFields2(subField)}
                  </div>
                </ProcedureItemContainerCard2>
              ))}
          </div>
        </React.Fragment>
      ));
    } else {
      return procedureFields.map((field, index) =>
        field?.fieldType == "heading" ? (
          <ProcedureItemContainerCard2 field={field} fieldTypes={fieldTypes}>
            {field?.label}
          </ProcedureItemContainerCard2>
        ) : (
          <ProcedureItemContainerCard2 field={field} fieldTypes={fieldTypes}>
            <div className="item-value">{generateSubFields(field)}</div>
          </ProcedureItemContainerCard2>
        )
      );
    }
  };

  const calculateCurrentCount = (total, percentage = 0) => {
    let datas = typeof percentage;

    const numerator = total * (Number(percentage) / 100); // Calculate current count

    return `(${numerator}/${total}) ${percentage}%`;
  };
  return (
    <>
      <div className="pane-body p-0">
        {targetedData ? (
          <>
            <div className="procedure-progress  p-5 ">
              <p
                style={{ color: "#0c66e4" }}
                onClick={() =>
                  history.push(
                    `/procedures?id=${procedureData?.data?.attributes?.countId}`
                  )
                }
                className="detail-intro-link c-pointer mb-3"
              >
                {" "}
                {procedureData?.data?.attributes?.title}
              </p>

              <div
                className={`mb-2 progress-container ${
                  selectedLanguage == "en" ? "" : "ml-14"
                }`}
              >
                <ProgressBar
                  completed={
                    targetedData?.attributes?.procedureCompletion
                      ? targetedData?.attributes?.procedureCompletion
                      : 0
                  }
                  bgColor="#4FAB68"
                  isLabelVisible={false}
                  height="6px"
                  
                  baseBgColor="#E4E6EA"
                  className="progress-bar"
                ></ProgressBar>
                <span
                  className={
                    selectedLanguage == "en"
                      ? "font-weight-bold ml-2"
                      : "font-weight-bold mr-2"
                  }
                >
                  {calculateCurrentCount(
                    Number(procedureItemsCount || 0),
                    Number(targetedData?.attributes?.procedureCompletion || 0)
                  )}
                </span>
              
              </div>
            </div>
            

            <div className="preview-details-wrapper">
              <ul className="details-list">
                <li className="list-item" style={{ marginBottom: "10px" }}>
                  {targetedData?.attributes?.reportItems?.length > 0 ||
                  targetedData?.attributes?.headerReportItems?.length > 0 ? (
                    <>
                      <label className="item-label procedure-item-header">
                        {t.procedures.details_view.procedure_items} (
                        {procedureItemsCount ? procedureItemsCount : 0})
                      </label>
                      <div className="item-value">
                        {targetedData?.attributes?.headerReportItems?.length > 0
                          ? generateFields(
                              targetedData?.attributes?.headerReportItems,
                              true
                            )
                          : null}
                      </div>
                      <div className="item-value">
                        {targetedData?.attributes.reportItems
                          ? generateFields(targetedData?.attributes.reportItems)
                          : null}
                      </div>
                    </>
                  ) : (
                    <>
                      <label className="item-label procedure-item-header">
                        {t.procedures.details_view.procedure_items} (
                        {procedureItemsCount ? procedureItemsCount : 0})
                      </label>
                      <div className="item-value">
                        {procedureData?.data?.attributes?.headers?.length > 0
                          ? generateFields2(
                              procedureData?.data?.attributes?.headers,
                              true
                            )
                          : null}
                      </div>
                      <div className="item-value">
                        {procedureData?.data?.attributes?.fields?.length > 0
                          ? generateFields2(
                              procedureData?.data?.attributes?.fields
                            )
                          : null}
                      </div>
                    </>
                  
                  )}
                </li>
              </ul>
            </div>
          </>
        ) : (
          <Dimmer active inverted>
            <Loader inverted>{t.common.loading}</Loader>
          </Dimmer>
        )}
      </div>
    </>
  );
}

const ProcedureItemContainerCard = ({
  fieldTypes,
  field,
  children,
  isHeader,
  fieldCount = null,
  index = null,
  toggleProcedureItems = () => {},
  procedureItemsVisible,
}) => {
  let completedCount;
  let completedProceduresPercentage;
  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return "en";
    }
  });
  const countCompleted = (data) => {
    return data.reduce((completedCount, item) => {
     
      if (item.type === "multipleChoice" || item.type === "checklist") {
        if (Array.isArray(item.options) && item.options.length > 0) {
          if (
            item.options.some((option) => option.isChecked && option.option)
          ) {
            return completedCount + 1;
          }
        }
      } else if (
        item.type === "signature" ||
        item.type === "file" ||
        item.type === "photo"
      ) {
        if (item?.file?.data !== null) {
          return completedCount + 1;
        }
      }
      else if (item.value !== null && item.value !== "") {
        return completedCount + 1;
      }
      return completedCount;
    }, 0);
  };

  if (field?.reportItems?.length > 0) {
    completedCount = countCompleted(field?.reportItems);
    completedProceduresPercentage = (
      (completedCount / field?.reportItems?.length) *
      100
    ).toFixed(2);
  }

  const generatedFieldType = (type) => {
    let filteredFields = fieldTypes.filter((field) => field.value == type);
    filteredFields = filteredFields[0];
    return (
      <>
        {type !== "heading" ? (
          <>
            {filteredFields.icon}
            <div className="photo">{filteredFields.text}</div>
          </>
        ) : null}
      </>
    );
  };
  return (
    <div
      className={isHeader ? "m-3 procedure-container" : "procedure-container"}
    >
      <div
        className={`procedure-item-parent green ${
          field?.fieldType == "heading" ? "header-div" : ""
        } 
        ${
          field?.fieldType === "heading" &&
          (!field?.reportItems ||
            field?.reportItems?.length === 0 ||
            procedureItemsVisible?.[index])
            ? "show-header"
            : ""
        }
   
        `}

      
      >
      
        <div
          style={{ wordBreak: "break-word" }}
          className={field?.fieldType == "heading" ? "head-title" : "title"}
        >
          {field?.fieldType == "heading" && field?.reportItems?.length > 0 ? (
            !procedureItemsVisible[index] ? (
              <span
                className="mt-2 c-pointer"
                onClick={() => toggleProcedureItems(index)}
              >
                {<DownArrow />}
              </span>
            ) : (
              <span
                className="mt-2 c-pointer"
                onClick={() => toggleProcedureItems(index)}
              >
                {selectedLanguage == "en" ? <UpArrow /> : <UpArrowAr />}
              </span>
            )
          ) : null}
          {field.label}
          {field?.isRequired ? <Asterisk color="#D14040" /> : null}
        </div>
        <div
          className={`type-parent ${selectedLanguage == "en" ? "" : "ml-1"}`}
        >
          {generatedFieldType(
            field?.fieldType ? field?.fieldType : field?.type
          )}
          {field?.fieldType == "heading" &&
          completedCount &&
          completedProceduresPercentage ? (
            <span
              className={
                selectedLanguage == "ar" ? "mr-2 pl-1 pr-1" : "ml-3 pl-1 pr-1"
              }
            
              style={{
                background: "#0C66E4",
                color: "white",
                borderRadius: "3px",
              }}
            >
              {`${completedCount}/${field?.reportItems?.length} ${completedProceduresPercentage}%`}
            </span>
          ) : null}
        </div>
      </div>
      {field?.type && !["heading"].includes(field?.type) ? (
        <div className="procedure-sub-item-parent">{children}</div>
      ) : (
        <div className="mb-5"></div>
      )}
    </div>
  );
};

const ProcedureItemContainerCard2 = ({
  fieldTypes,
  field,
  children,
  isHeader,
  fieldCount = null,
  index = null,
  toggleProcedureItems = () => {},
  procedureItemsVisible,
}) => {
  let completedCount;
  let completedProceduresPercentage;
  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return "en";
    }
  });
  const countCompleted = (data) => {
    return data.reduce((completedCount, item) => {
      // Handle "multipleChoice" and "checklist" types
      if (item.type === "multipleChoice" || item.type === "checklist") {
        if (Array.isArray(item.options) && item.options.length > 0) {
          if (
            item.options.some((option) => option.isChecked && option.option)
          ) {
            return completedCount + 1;
          }
        }
      } else if (
        item.type === "signature" ||
        item.type === "file" ||
        item.type === "photo"
      ) {
        if (item?.file?.data !== null) {
          return completedCount + 1;
        }
      }
      // Handle other types
      else if (item.value !== null && item.value !== "") {
        return completedCount + 1;
      }
      return completedCount;
    }, 0);
  };

  if (field?.reportItems?.length > 0) {
    completedCount = countCompleted(field?.reportItems);
    completedProceduresPercentage = (
      (completedCount / field?.reportItems?.length) *
      100
    ).toFixed(2);
  }

  const generatedFieldType = (type) => {
    let filteredFields = fieldTypes.filter((field) => field.value == type);
    filteredFields = filteredFields[0];
    return (
      <>
        {type !== "heading" ? (
          <>
            {filteredFields.icon}
            <div className="photo">{filteredFields.text}</div>
          </>
        ) : null}
      </>
    );
  };
  return (
    <div
      className={isHeader ? "m-3 procedure-container" : "procedure-container"}
    >
      <div
        className={`procedure-item-parent green ${
          field?.fieldType == "heading" ? "header-div" : ""
        }${
          field?.fieldType === "heading" &&
          (!field?.fields ||
            field?.fields?.length === 0 ||
            procedureItemsVisible?.[index])
            ? "show-header"
            : ""
        }`}
      >
        
        <div
          style={{ wordBreak: "break-word" }}
          className={field?.fieldType == "heading" ? "head-title" : "title"}
        >
          {field?.fieldType == "heading" && field?.fields?.length > 0 ? (
            !procedureItemsVisible[index] ? (
              <span
                className="mt-2 c-pointer"
                onClick={() => toggleProcedureItems(index)}
              >
                {<DownArrow />}
              </span>
            ) : (
              <span
                className="mt-2 c-pointer"
                onClick={() => toggleProcedureItems(index)}
              >
                {selectedLanguage == "en" ? <UpArrow /> : <UpArrowAr />}
              </span>
            )
          ) : null}
          
          {field.label}
          {field?.isRequired ? <Asterisk color="#D14040" /> : null}
        </div>
        <div
          className={`type-parent ${selectedLanguage == "en" ? "" : "ml-1"}`}
        >
          {generatedFieldType(
            field?.fieldType ? field?.fieldType : field?.type
          )}
          {field?.fieldType == "heading" ? (
            <span
              className={
                selectedLanguage == "ar" ? "mr-2 pl-1 pr-1" : "ml-3 pl-1 pr-1"
              }
            
              style={{
                background: "#0C66E4",
                color: "white",
                borderRadius: "3px",
              }}
            >
              {`0/${field?.fields?.length} 0%`}
            </span>
          ) : null}
        </div>
      </div>
      {field?.fieldType && !["heading"].includes(field?.fieldType) ? (
        <div className="procedure-sub-item-parent">{children}</div>
      ) : (
        <div className="mb-5"></div>
      )}
    </div>
  );
};

export default ProcedureItems;
